import React, {useContext, useState} from 'react';
import './shipping.scss';
import MyContext from '@/hooks/goodsInfo'
import Input from '@/components/inputStyle/index'
import {DataContext} from "@/hooks/formMessage";

const ShippingGoods = () => {
    const [couponFlag, setCouponFlag] = useState(false)
    const {priceCorrelation, curInfo, shippingPrice,shipping_price} = useContext(DataContext)
    const goods = useContext(MyContext);
    const handleCoupon = () => {
        setCouponFlag(!couponFlag)
    }
    return (<div className="goods-box">
        <div className="goods">
            <div className="goods-list">
                <div className="goods-1">
                    <table className="goods-2">
                        <tbody className="goods-tbody">
                        {goods.items.map((item, index) => {
                            return (item.product_title !== 'Shipping Protection' &&
                                <tr key={index} className="goods-tr">
                                    <td className="goods-td" style={{paddingTop: index && '1rem'}}>
                                        <div className="goods-item">
                                            <div className="goods-img">
                                                <img src={item.image} className="img" alt=""/>
                                            </div>
                                            <span className="quantity">{item.quantity}</span>
                                        </div>
                                    </td>
                                    <td className="goods-title" style={{paddingTop: index && '1rem'}}>
                                            <span>
                                                {item.product_title}
                                            </span>
                                        {item.variant_title &&
                                            <div style={{fontSize: '12px', color: '#666'}}>
                                                {item.variant_title}
                                            </div>
                                        }
                                    </td>
                                    <td className="goods-price" style={{paddingTop: index && '1rem'}}>
                                        <span>{curInfo.currency_symbol}{item.presentment_price.toFixed(2)}</span>
                                    </td>
                                </tr>)
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="goods-total">
                <div style={{
                    marginBottom: '1.5rem'
                }}>
                    <div className="row alc" style={{cursor: 'pointer'}} onClick={handleCoupon}>
                        <svg version="1.1" viewBox="0 0 42 42"
                             style={{display: 'block', width: ' 0.765rem', height: '0.765rem', marginRight: '2px'}}>
                            {!couponFlag ? <rect y="20" width="42" height="2" fill="#a26b25"></rect> :
                                <polygon fill="#a26b25"
                                         points="42,20 22,20 22,0 20,0 20,20 0,20 0,22 20,22 20,42 22,42 22,22 42,22"></polygon>}
                        </svg>
                        <div style={{
                            display: 'table-cell',
                            verticalAlign: 'middle',
                            lineHeight: ' 0.9rem',
                            padding: ' 0px 0px 0px 0.15em',
                            margin: 0,
                            color: 'rgb(162, 107, 37)',
                            fontSize: ' 0.9rem',
                            cursor: 'inherit',
                            userSelect: 'none',
                        }}>{!couponFlag ? 'Hide' : 'Discount code'}
                        </div>
                    </div>
                    {!couponFlag && <div className="input-anm">
                        <Input inputFont="Discount code" inputFlagBtn={true} field="coupon"></Input>
                    </div>}
                </div>
                <table className="total-price">
                    <tbody>
                    <tr>
                        <th>Subtotal</th>
                        <td><span>{curInfo.currency_symbol}{(priceCorrelation.sub_total - shipping_price).toFixed(2)}</span></td>
                    </tr>
                    {shippingPrice.length !== 0 && <tr style={{height: '10px'}}></tr>}
                    {shippingPrice.length !== 0 &&
                        <tr>
                            <th>Shipping</th>
                            <td>
                                <span>{priceCorrelation.shipping ? curInfo.currency_symbol : ''}{priceCorrelation.shipping ? priceCorrelation.shipping.toFixed(2) : 'Free'}</span>
                            </td>
                        </tr>
                    }
                    </tbody>
                    <tfoot>
                    <tr>
                        <th style={{textAlign: 'left'}}><span style={{fontWeight: 500, fontSize: '1rem'}}>Total</span>
                        </th>
                        <td style={{textAlign: 'right'}}><span className="cur">{curInfo.currency}</span><span
                            className="price">{curInfo.currency_symbol}{(priceCorrelation.total - shipping_price).toFixed(2)}</span>
                        </td>
                    </tr>
                    </tfoot>
                </table>
                <div style={{marginTop:'40px'}}>
                    {/*<img alt="" src={require('@/assets/image/9463e8e6-2931-4bd1-9931-ca77db7056d5.png')} style={{maxWidth:'100%'}}/>*/}
                    {/*<img alt="" src={require('@/assets/image/0635c5253afa759b381b3d41e5d932c.png')}*/}
                    {/*     style={{maxWidth: '100%'}}/>*/}
                    {/*<img alt="" src={require('@/assets/image/a819dcd9a7f9fae113ce61a7f9dab00.png')}*/}
                    {/*     style={{maxWidth: '100%'}}/>*/}
                    <img alt="" src={require('@/assets/image/0e0f0d7f2e1c31cc47358e0ccc0a607.png')}
                         style={{maxWidth: '100%'}}/>
                </div>
            </div>
        </div>
        <div style={{padding: '1rem'}}></div>
    </div>);
};

export default ShippingGoods;

import styles from "./index.module.scss";
import React, {useEffect, useState} from 'react';
import '@/pages/checkout/components/shipping.scss';
import {getOrderDetail} from '@/api'
import GoodsInfo from './goodsInfo'
import {Collapse} from "antd";
import Cart from "@/pages/checkout/svgJsx/cart";
import {useNavigate} from "react-router-dom";

const Upsell = () => {
    const navigate = useNavigate();
    const [collList, setCollList] = useState([]);
    const [orderInfo, setOrderInfo] = useState(1);
    const {Panel} = Collapse;
    //获取订单支付成功页面数据
    const _getOrderDetail = () => {
        const searchParams = new URLSearchParams(window.location.search);
        const orderId = searchParams.get('order_id');
        if (!orderId) return navigate(-1)
        console.log(orderId)
        getOrderDetail(orderId).then(res => {
            setOrderInfo(res.data)
        })
    }
    useEffect(() => {
        _getOrderDetail()
        // eslint-disable-next-line
    }, [])
    return (<div className={styles['content']}>
        {orderInfo !== 1 && <div className={styles['content-box']}>
            <div className={styles['lf']}>
                <div className={styles['logo']}>
                    <img
                        src={require('@/assets/image/logo.png')}
                        className="title" alt=""/>
                </div>
                <div className={styles['lf-msg']}>
                    <img className={styles['lf-svg']} src={require('@/assets/image/ok.png')} style={{width: '8%'}}
                         alt=''/>
                    <div className={styles['lf-font']}>
                        <div style={{
                            marginBottom: '10px', color: 'rgba(51,51,51,.8)', fontSize: '.875rem'
                        }}>order {orderInfo.order_num}
                        </div>
                        <div style={{fontSize: '20px'}}>Thank you,{orderInfo.first_name}</div>
                    </div>
                </div>
                {<div className={styles['pc-over']}>
                    <Collapse defaultActiveKey={collList} onChange={(key) => setCollList(key)}
                              expandIcon={({isActive}) => isActive ? '' : ''}
                    >
                        <Panel header={<Cart collList={collList} total={orderInfo.total_price}/>} key="1">
                            <GoodsInfo orderInfo={orderInfo}></GoodsInfo> {/*手机端商品价格展示组件*/}
                        </Panel>
                    </Collapse>
                </div>}
                <div className={styles['middle-content']}>
                    <div style={{fontSize: '18px'}}>Your order is confirmed</div>
                    <div style={{margin: '7px 0', color: 'rgba(51,51,51,.9)', fontSize: '14px'}}>You'll receive an
                        email when your order is ready
                    </div>
                </div>
                <div className={styles['middle-content']} style={{marginTop: "10px"}}>
                    <div style={{fontSize: '18px'}}>Order details</div>
                    <div className={styles['msg']}>
                        <div>
                            <div className={styles['martop']}>
                                <div style={{margin: '10px 0', fontWeight: 500}}>Contact information</div>
                                <div style={{color: 'rgba(51,51,51,.9)'}}>{orderInfo.order_details.email}</div>
                            </div>
                            <div className={styles['martop']}>
                                <div style={{margin: '10px 0', fontWeight: 500}}>Shipping address</div>
                                <div style={{color: 'rgba(51,51,51,.9)', lineHeight: 1.5}}>
                                    <div>{orderInfo.shipping_address.username}</div>
                                    <div>{orderInfo.shipping_address.address1}{orderInfo.shipping_address.address2 ? ', ' + orderInfo.shipping_address.address2 : ''}</div>
                                    <div>{orderInfo.shipping_address.province}</div>
                                    <div>{orderInfo.shipping_address.city} {orderInfo.shipping_address.city ? orderInfo.shipping_address.city : ''}</div>
                                    <div>{orderInfo.shipping_address.country}</div>
                                    <div>{orderInfo.shipping_address.phone}</div>
                                </div>
                                <div style={{margin: '10px 0', fontWeight: 500}}>Shipping method
                                </div>
                                <div style={{color: 'rgba(51,51,51,.9)', lineHeight: 1.5}}>
                                    {orderInfo.shipping_method}
                                </div>
                            </div>
                        </div>
                        <div style={{marginLeft: '60px'}}>
                            <div className={styles['martop']}>
                                <div style={{margin: '10px 0', fontWeight: 500}}>Payment method</div>
                                <div
                                    style={{color: 'rgba(51,51,51,.9)'}}>{orderInfo.payment_method.pay_method_name} - <span
                                    style={{fontWeight: 500}}>{orderInfo.payment_method.currency === 'USD' ? '$' : '€'}{orderInfo.payment_method.total_price}</span>
                                </div>
                            </div>
                            <div className={styles['martop']}>
                                <div style={{margin: '10px 0', fontWeight: 500}}>Billing address</div>
                                <div style={{color: 'rgba(51,51,51,.9)', lineHeight: 1.5}}>
                                    <div>{orderInfo[orderInfo.payment_method.pay_method_name === 'Paypal' ? 'shipping_address' : 'billing_address'].username}</div>
                                    <div>{orderInfo[orderInfo.payment_method.pay_method_name === 'Paypal' ? 'shipping_address' : 'billing_address'].address1}{!orderInfo[orderInfo.payment_method.pay_method_name === 'Paypal' ? 'shipping_address' : 'billing_address'].address2 ? '' : ', ' + orderInfo[orderInfo.payment_method.pay_method_name === 'Paypal' ? 'shipping_address' : 'billing_address'].address2}</div>
                                    <div>{orderInfo[orderInfo.payment_method.pay_method_name === 'Paypal' ? 'shipping_address' : 'billing_address'].province}</div>
                                    <div>{orderInfo[orderInfo.payment_method.pay_method_name === 'Paypal' ? 'shipping_address' : 'billing_address'].city} {orderInfo[orderInfo.payment_method.pay_method_name === 'Paypal' ? 'shipping_address' : 'billing_address'].zip ? orderInfo[orderInfo.payment_method.pay_method_name === 'Paypal' ? 'shipping_address' : 'billing_address'].zip : ''}</div>
                                    <div>{orderInfo[orderInfo.payment_method.pay_method_name === 'Paypal' ? 'shipping_address' : 'billing_address'].country}</div>
                                    <div>{orderInfo[orderInfo.payment_method.pay_method_name === 'Paypal' ? 'shipping_address' : 'billing_address'].phone}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${styles['rg']} ${styles['over']}`}>
                {<GoodsInfo orderInfo={orderInfo}></GoodsInfo>}
            </div>
        </div>}
    </div>)
}
export default Upsell

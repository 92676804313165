import React, {createContext, useContext, useEffect, useState} from 'react';
import MyContext from "./goodsInfo";
import {createOrder, updateOrder,trackEvent,postidlogs} from "@/api";
// 创建上下文
export const DataContext = createContext({});
// 上下文提供者组件
export const DataProvider = ({children}) => {
    const goods = useContext(MyContext);
    const [requestFlag,setRequestFlag] = useState(false)
    const [layout, setLayout] = useState('layout2') //保存当前选择的国家，layout布局，（是否需要回显zip框，是否需要回显州的选择框的判断）
    const [billingLayout, setBillingLayout] = useState(undefined) //保存
    const [asiabillInfo, setAsiabillInfo] = useState({}) //获取支付信息数据
    const [paymentList, setPaymentList] = useState()
    const [oneOrThreeStep, setOneOrThreeStep] = useState(window.shopify.session.pay_step || 1) //当前默认步骤是多少步（从接口获取步骤状态）
    // const [oneOrThreeStep, setOneOrThreeStep] = useState(1)
    const [stepIndex, setStepIndex] = useState(1) //关于当前步骤
    const [shippingPrice, setShippingPrice] = useState([]) //运费
    const [shippingInfo, setShippingInfo] = useState({}) //当前选中的邮费 默认第一个
    const [errorMsg, setErrorMsg] = useState('') //下单错误信息
    let [shipping_price,set_shipping_price] = useState(0) //运费险金额
    const [shippingLoading, setShippingLoading] = useState(false)
    const [code,setCode] = useState('')
    const curList = [
        {currency: 'USD', currency_symbol: '$'},
        {currency: 'EUR', currency_symbol: '€'},
        {currency: 'GBP', currency_symbol: '£'},
        {currency: 'CAD', currency_symbol: '$'},
        {currency: 'AUD', currency_symbol: '$'},
        {currency: 'CHF', currency_symbol: 'CHF'},
        {currency: 'AED', currency_symbol: 'Dhs.'},
        {currency: 'SEK', currency_symbol: 'kr'},
        {currency: 'KRW', currency_symbol: '₩'},
        {currency: 'SGD', currency_symbol: '$'},
        {currency: 'PLN', currency_symbol: 'zł'},
        {currency: 'NZD', currency_symbol: '$'},
        {currency: 'MYR', currency_symbol: 'RM'},
        {currency: 'JPY', currency_symbol: '¥'},
        {currency: 'ILS', currency_symbol: 'NIS'},
        {currency: 'HKD', currency_symbol: '$'},
        {currency: 'DKK', currency_symbol: 'kr'},
        {currency: 'CZK', currency_symbol: 'Kč'},
    ]
    const [curInfo, setCurInfo] = useState({})
    const handleSelect = (e,type) => { //表单填写（填写省州的事件爆出）
        setCode(e)
        if(!type){
            trackEvent('province:'+e)
            postidlogs(4)
        }
    }
    //当前页面回显的货币币种
    useEffect(() => {
        let found = false;
        curList.forEach((item) => {
            if (!found && item.currency === window.shopify.cart.currency) {
                setCurInfo(item);
                found = true;
            }
        })
        // eslint-disable-next-line
    }, [window.shopify.cart.currency]);

    //当前是否存在运费险，存在不显示
    useEffect(() => {
        goods.items.forEach(item => {
            if (item.product_title === 'Shipping Protection') {
                set_shipping_price(item.presentment_price)
            }
        })
        console.log()
    }, [goods,shipping_price])
    const [checkout, setCheckout] = useState({
        email: '',//邮箱
        first_name: '', last_name: '', address_1: '',//地址1
        address_2: '',//地址2
        city: '',//城市
        country: '',//国家全称
        country_code: '',//国家简称
        province: '',//州全称
        province_code: '',//州简称
        zip: '',//邮政编码
        phone: '',//电话
    });
    const [billingAddress, setBillingAddress] = useState({
        first_name: '', last_name: '', address_1: '',//地址1
        address_2: '',//地址2
        city: '',//城市
        country: '',//国家全称
        country_code: '',//国家简称
        province: '',//州全称
        province_code: '',//州简称
        zip: '',//邮政编码
        phone: '',//电话
    }); //账单地址
    const [checkValidate, setCheckValidate] = useState({})
    const [payment, setPayment] = useState(0) //当前选中的支付方式
    const [billingAddressValidate, setBillingAddressValidate] = useState({})
    const [priceCorrelation, setPriceCorrelation] = useState({
        total: (goods.total_price / 100).toFixed(2),//总价
        tax: 0,//税费
        shipping: 0,//邮费
        sub_total: (goods.total_price / 100).toFixed(2),//小计
    })
    const [coupon, setCoupon] = useState('') //关于优惠券码
    const [billingCheck, setBillingCheck] = useState(true) //控制是否选中自定义账单地址
    //将商品金额算好
    useEffect(() => {
        if (stepIndex === 2 || oneOrThreeStep === 1) {
            setPriceCorrelation({
                ...priceCorrelation,
                total: (Number(goods.total_price / 100) + (shippingInfo.price ? Number(shippingInfo.price) : 0)).toFixed(2),
                shipping: Number(shippingInfo.price || 0)
            })
        }
        // eslint-disable-next-line
    }, [stepIndex, oneOrThreeStep, shippingInfo, shippingPrice])
    const incrementCount = (key, value) => {
        if (key === 'coupon') {
            setCoupon(value)
        } else {
            setCheckout({
                ...checkout, [key]: value
            });
        }
    };
    const billingAddressCount = (key, value) => {
        setBillingAddress({
            ...billingAddress, [key]: value
        });
    };
    const disposalData = () => {
        let shipping_address = {
            first_name: checkout.first_name,
            last_name: checkout.last_name,
            address1: checkout.address_1,
            address2: checkout.address_2,
            city: checkout.city,
            country: checkout.country,
            country_code: checkout.country_code,
            province: checkout.province,
            province_code: checkout.province_code,
            zip: checkout.zip,
            phone: checkout.phone,
        }
        return {
            email: checkout.email,
            checkout: {
                cart: goods,
            },
            checkout_id: window.shopify.session.id,
            shipping_line: shippingInfo,
            shipping_address: shipping_address,
            billing_address: payment ? shipping_address : billingCheck ? shipping_address : {
                first_name: billingAddress.first_name,
                last_name: billingAddress.last_name,
                address1: billingAddress.address_1,
                address2: billingAddress.address_2,
                city: billingAddress.city,
                country: billingAddress.country,
                country_code: billingAddress.country_code,
                province: billingAddress.province,
                province_code: billingAddress.province_code,
                zip: billingAddress.zip,
                phone: billingAddress.phone,
            }
        }
    }
    //创建订单
    const _createOrder = async () => {
        setRequestFlag(true);
        let data = disposalData();
        try {
            let res = await createOrder(data);
            //请求成功
            if (res.code === 200) {
                localStorage.setItem('order_id', res.data.order_id);
                localStorage.setItem('draft', JSON.stringify(res.data.draft));
                if (errorMsg) {
                    setErrorMsg('');
                }
            } else if (res.msg.includes("contains an invalid domain name")) {
                setErrorMsg('Email contains an invalid domain name');
            }
        } catch (error) {
            // 请求失败处理
            console.error('Error creating order:', error);
        } finally {
            // 请求成功失败都执行的代码
            setRequestFlag(false);
        }
    }

    //更新订单
    const _updateOrder = async (row) => {
        setRequestFlag(true);
        let data = disposalData();
        if (row) {
            data.shipping_line = row;
        }
        try {
            let res = await updateOrder(localStorage.getItem('order_id'), data);
            if (res.code === 200) {
                localStorage.setItem('draft', JSON.stringify(res.data.draft));
            } else if (res.msg.includes("contains an invalid domain name")) {
                setErrorMsg('Email contains an invalid domain name');
            } else if (res.code === 1130) {
                localStorage.removeItem('order_id');
                await _createOrder();
            }
        } catch (error) {
            console.error('Error updating order:', error);
        } finally {
            setRequestFlag(false);
        }
    }

    return (<DataContext.Provider value={{
        checkout,
        setCheckout,
        coupon,
        stepIndex,
        setStepIndex,
        incrementCount,
        checkValidate,
        setCheckValidate,
        layout,
        setLayout,
        billingLayout,
        setBillingLayout,
        oneOrThreeStep,
        setOneOrThreeStep,
        shippingPrice,
        setShippingPrice,
        priceCorrelation,
        setPriceCorrelation,
        billingAddressCount,
        setBillingAddress,
        billingAddress,
        billingAddressValidate,
        setBillingAddressValidate,
        billingCheck,
        setBillingCheck,
        payment,
        setPayment,
        asiabillInfo,
        setAsiabillInfo,
        shippingInfo,
        setShippingInfo,
        curInfo,
        setCurInfo,
        goods,
        paymentList,
        setPaymentList,
        errorMsg,
        setErrorMsg,
        shippingLoading,
        setShippingLoading,
        disposalData,
        _createOrder,
        _updateOrder,
        shipping_price,
        requestFlag,
        code,
        setCode,
        handleSelect
    }}>
        {children}
    </DataContext.Provider>);
};

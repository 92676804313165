import React from 'react';

const DownOutlined = () => {
    return (
        <svg version="1.1" viewBox="0 0 11 7" className="sc-1m4eypi-0 ldFBxS"
             style={{
                 transition: 'all 0.3s ease-in-out 0s',
                 width: '0.6875rem',
                 height: ' 0.375rem',
                 transform: 'scale(1)',
                 opacity: 1
             }}>
            <path fill="#a26b25"
                  d="M6.138.876L5.642.438l-.496.438L.504 4.972l.992 1.124L6.138 2l-.496.436 3.862 3.408.992-1.122L6.138.876z"></path>
        </svg>
    )
}
export default DownOutlined

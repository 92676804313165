import React, {useContext, useEffect, useState} from 'react';
import selectStyles from './index.module.scss'
// layout1 不填写邮编 不填写区
// layout2 填写邮编 填写区
// layout3 填写邮编 填写区
// layout4 不填写邮编 填写区
// 无layout 填写邮编  不填写区
import province from "./province";
import {DataContext} from '@/hooks/formMessage';
const SelectInput = (props) => {
    const {checkout, incrementCount,handleSelect,code} = useContext(DataContext)
    const [state, setState] = useState({
        selectLineBor: false,
        province: '',
        province_code: '',
    })
    const [stateList, setStateList] = useState([])
    const selected = () => {
        checkout['province'] = state.province
        checkout['province_code'] = state.province_code
        incrementCount('province', state.province)
        incrementCount('province_code', state.province_code)
    }
    const handleSelect1 = (e,type)=>{
        handleSelect(e,type)
    }
    useEffect(() => {
        if (stateList && stateList.length > 0) {
            stateList.forEach((item) => {
                if (item.code === code) {
                    // setStateInfo(item)
                    setState((prevState) => {
                        return {
                            ...prevState,
                            province: item.name,
                            province_code: item.code,
                        }
                    })
                    if (item.code) {
                        selected()
                    }
                }
            })
        }
        handleSelect(code?code:checkout['province_code'],1)
        // eslint-disable-next-line
    }, [stateList, code, state.province, state.province_code]);
    useEffect(() => {
        const codeProps = props.code?props.code:'US'
        setStateList(province[codeProps])
        if (province[codeProps] && province[codeProps].length > 0) {
            setStateList(prevStateList => {
                return [{
                    code: '',
                    value: '-- select --',
                    name: '-- select --'
                }, ...prevStateList];
            });
        }
    }, [props.code])
    const handleFocus = () => {
        setState({
            ...state,
            selectLineBor: true
        })
    }
    const handleBlur = () => {
        setState({
            ...state,
            selectLineBor: false
        })
    }
    return (
        <div className={selectStyles['content']}>
            <div className={selectStyles['checkfy']}>
                <div className={selectStyles['rela-check']}>
                    <div className={selectStyles['check-label']}>
                        <label className={selectStyles['label']} style={{opacity: state.selectLineBor ? '1' : '.75'}}>
                            {props.selectType}
                        </label>
                    </div>
                    <div className={selectStyles['select-check']}>
                        <div className={selectStyles['select-box']}>
                            <select className={selectStyles['select']}
                                    onChange={(e) => handleSelect1(e.target.value, props.selectNum)}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    value={state.province_code}
                                    autoComplete="shipping country" color="#333333"
                                    style={{
                                        border: props.validate ? '1px solid rgb(227, 44, 43)' : state.selectLineBor ? '1px solid rgb(162,107,37)' : '1px solid rgb(217, 217, 217)',
                                        boxShadow: 'none'
                                    }}>
                                {stateList && stateList.length > 0 &&
                                    stateList.map((item, index) => {
                                        return <option key={index} value={item.code} color="#333333"
                                                       className="uepder-8 dyCcSX">{item.name}</option>
                                    })
                                }
                            </select>
                            <div className={selectStyles['icon']}>
                                <svg version="1.1" viewBox="0 0 21 19">
                                    <g fill="none" fillRule="evenodd">
                                        <g fill="#333333">
                                            <g>
                                                <path d="M0 0h1v19H0V0z" fillOpacity="0.15"></path>
                                                <path d="M11 8h10l-5 5-5-5z" fillOpacity="0.5"></path>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SelectInput;

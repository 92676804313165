import axios from 'axios'
// 创建axios实例
const service = axios.create({
    baseURL:process.env.REACT_APP_ENV !== 'production'? "https://amazoeo.com/api/v1" : "https://uvinn.com/api/v1", // api 的 base_url
    timeout: 100000, // 请求超时时间
    headers: {
        "X-Opc-Shop-Id": window.shopify.session.shop,
        'X-Opc-Client-Id': window.shopify.session.client_id
    },
})
//
// request拦截器
service.interceptors.request.use(
    config => {
        // Do something before request is sent
        return config
    },
    error => {
        Promise.reject(error)
    }
)
// response 拦截器
service.interceptors.response.use(
    response => {
        const res = response.data
        // if the custom code is not 200, it is judged as an error.
        if (res.code !== 200) {
            return res
        } else {
            return res
        }
    },
    error => {
        return error
    }
)
export default function request(method, url, data) {
    method = method.toLocaleLowerCase();
    if (method === 'post') {
        return service.post(url, data)
    } else if (method === 'get') {
        return service.get(url, {params: data})
    } else if (method === 'delete') {
        return service.delete(url, {params: data})
    } else if (method === 'put') {
        return service.put(url, data)
    } else {
        console.error('未知的method' + method)
        return false
    }
}

import React, {useContext, useEffect, useState} from 'react';
import {PayPalButtons, PayPalScriptProvider} from '@paypal/react-paypal-js';
import {postCalculate,postPaypalQuick} from '@/api'
import {DataContext} from "@/hooks/formMessage";
import getShopifyPath from '@/utils/throttle';

const QuickPayButton = () => {
    const {priceCorrelation, curInfo, goods,shippingPrice} = useContext(DataContext);
    const paypal_sdk = window.shopify.session.paypal.paypal_client_id;
    const [price_total,setPriceTotal] = useState({})
    const [goodspaypal, setGoodspaypal] = useState({
        purchase_units: [
            {
                amount: {
                    value: "50.00", // 初始支付金额
                },
                items: [], // 初始商品信息
            },
        ],
        application_context: {
            user_action: 'PAY_NOW', // 用户点击按钮后立即支付
        }
    });
    const _postCalculate = () => {
        let data = {
            subtotal: priceCorrelation.sub_total,
            shipping_price: shippingPrice[0].price.toString(), // 运费
        }
        postCalculate(data).then(res => {
            setPriceTotal(res.data)
            console.log(res.data)
        })
    }
    const paypalData = () => {
        const updatedPurchaseUnits = {
            amount: {
                value:price_total.totalPrice, // 总计
                currency_code: curInfo.currency,
                breakdown: {
                    item_total: {
                        value: priceCorrelation.sub_total, // 小计
                        currency_code: curInfo.currency,
                    },
                    shipping: {
                        value: shippingPrice[0].price, // 运费
                        currency_code: curInfo.currency,
                    },
                    tax_total: {
                        value:price_total.taxPrice, // 增值税 (VAT)
                        currency_code: curInfo.currency,
                    }
                },
            },
            items: goods.items.map(item => ({
                name: item.product_title, // 商品名字
                quantity: item.quantity, // 商品数量
                unit_amount: {
                    value: item.presentment_price, // 商品价格
                    currency_code: curInfo.currency,
                },
            })),
        };

        setGoodspaypal({
            purchase_units: [updatedPurchaseUnits],
            application_context: {
                user_action: 'PAY_NOW', // 用户点击按钮后立即支付
            }
        });
    }
    useEffect(() => {
        const fetchData = async () => {
            if (shippingPrice.length>0 && !price_total.totalPrice) {
                console.log(shippingPrice,priceCorrelation.shipping)
                await _postCalculate();
            }
        };
        fetchData();
        // eslint-disable-next-line
    }, [shippingPrice]);
    useEffect(() => {
        if (price_total.totalPrice) {
            paypalData();
        }
        // eslint-disable-next-line
    }, [price_total.totalPrice]);
    return (
        <div>
            {curInfo.currency&&
                <PayPalScriptProvider options={{"client-id": paypal_sdk, commit: false, currency: curInfo.currency}}>
                    <PayPalButtons
                        key={JSON.stringify(goodspaypal)} // 添加 key 以强制重新渲染
                        style={{layout: 'horizontal', tagline: false, intent: 'capture'}} // 设置按钮的样式为横向排列
                        createOrder={(data, actions) => {
                            return actions.order.create(goodspaypal);
                        }}
                        onApprove={(data, actions) => {
                            return actions.order.capture().then((details) => {
                                console.log('Transaction completed by ' + details.payer.name.given_name);
                                console.log('Payment details:', details);

                                let info = {
                                    paypal: details,
                                    checkout: {cart: window.shopify.cart},
                                    shipping_line: shippingPrice[0],
                                };
                                return postPaypalQuick(info).then(res => {
                                    localStorage.setItem('order_id', res.data.order_id);
                                    localStorage.setItem('draft',  JSON.stringify(res.data.draft));
                                    window.location.href = getShopifyPath('credit-card-3ds-redirect-loading') + '?cid=' + window.shopify.session.client_id;
                                }).then(() => {
                                    // 在 postPaypalQuick 请求完成后，关闭 PayPal 的页面
                                    return actions.close();
                                });
                            });
                        }}

                        onError={(err) => {
                            console.error('Payment error:', err);
                            // 支付失败，跳转到失败页面
                            // window.location.replace(getShopifyPath('entry') + '?cid=' + window.shopify.session.client_id)
                        }}
                        onCancel={(data) => {
                            console.log('Payment was cancelled');
                            // 用户取消支付，跳转到取消页面
                            // window.location.replace(getShopifyPath('entry') + '?cid=' + window.shopify.session.client_id)
                        }}
                    />
                </PayPalScriptProvider>
            }
        </div>
    );
};

export default QuickPayButton;

import React, {useContext} from 'react';
import styles from './shipping.module.scss'
import paynowStyles from './paynow.module.scss'
import MyComponent from '@/pages/checkout/components/myPaySDK/myJsSDK'
import Visa from '@/pages/checkout/svgJsx/visa'
import Baishitong from '@/pages/checkout/svgJsx/baishitong'
import Amex from '@/pages/checkout/svgJsx/amex'
import Jcb from '@/pages/checkout/svgJsx/jcb'
import Paypal from '@/pages/checkout/svgJsx/paypal'
import BigPaypal from "@/pages/checkout/svgJsx/bigPaypal";
import {DataContext} from '@/hooks/formMessage';
import BigCart from "@/pages/checkout/svgJsx/Bigcart";
import ErrorMessage from '../../svgJsx/errorMsg';
import {trackEvent,postidlogs} from '@/api'

const PayNow = () => {
    const {payment, setPayment, errorMsg} = useContext(DataContext)
    //选择当前支付方式1
    const selectPay = (e, num) => {
        setPayment(num); // 切换开启和关闭状态
        trackEvent('payment:' + (num === 1 ? 'paypal' : 'card'))
        postidlogs(6)
    }
    return (<div className={`${styles['content']} content`}>
        <div className={styles['title-font']}>
            Payment method
        </div>
        <div>
            <span className={paynowStyles['gkzkol']}>
                All transactions are secure and encrypted.
            </span>
        </div>
        {errorMsg && <div className={paynowStyles['ftrdcM']}>
            <div className={paynowStyles['hotbno']}>
                <ErrorMessage></ErrorMessage>
            </div>
            <div className={paynowStyles['kzefuj']}>
                {errorMsg}
            </div>
        </div>}
        <div className={paynowStyles['cengdp']}>
            <fieldset className={paynowStyles['kgstvn']}>
                <div className={paynowStyles['knysfgss']}>
                    <div className={paynowStyles['bgkgkb']}>
                        <input type="radio" color="#a26b25" checked={!payment}
                               onChange={(...rest) => selectPay(...rest, 0)} readOnly=""
                               className={!payment ? paynowStyles['bgehbk'] : paynowStyles['hnwnsb']}/>
                    </div>
                    <div className={paynowStyles['detlws']}>
                        <div className={paynowStyles['cieqo']}>
                            <label className={paynowStyles['kivdvv']} onClick={(...rest) => selectPay(...rest, 0)}>
                                Credit Card
                            </label>
                            <div className={paynowStyles['wpclm']}>
                                <Visa></Visa>
                                <Baishitong></Baishitong>
                                <Amex></Amex>
                                <Jcb></Jcb>
                                ...
                            </div>
                        </div>
                    </div>
                </div>
                <div className={!payment ? paynowStyles['block'] : paynowStyles['none-block']}>
                    <MyComponent/>
                </div>
                <div className={paynowStyles['fmrhf0']}>
                    <div className={paynowStyles['bgkgkb']}>
                        <input type="radio" onChange={(...rest) => selectPay(...rest, 1)} color="#a26b25"
                               readOnly=""
                               className={payment ? paynowStyles['bgehbk'] : paynowStyles['hnwnsb']}
                               checked={payment}/>
                    </div>
                    <div className={paynowStyles['detlws']}>
                        <div className={paynowStyles['cieqo']}>
                            <label className={paynowStyles['kivdvv']} onClick={(...rest) => selectPay(...rest, 1)}>
                                <BigPaypal></BigPaypal>
                            </label>
                            <div className={paynowStyles['wpclm']}>
                                <Visa></Visa>
                                <Baishitong></Baishitong>
                                <Amex></Amex>
                                <Paypal></Paypal>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={paynowStyles['vxglv']}
                     style={{display: payment ? 'block' : 'none', height: payment ? 'auto' : '0'}}>
                    <div className={paynowStyles['llwojb']}>
                        <div className={paynowStyles['jpfffn']}>
                            <div className={paynowStyles['bhpujo']}>
                                <div className={paynowStyles['cfoqrk']}>
                                    <BigCart></BigCart>
                                </div>
                                <p className={paynowStyles['gadqgp']}>
                                    After clicking "Place Order", you will be redirected to PayPal to complete your
                                    purchase securely.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </div>
    </div>)
}
export default PayNow;

import React from 'react';
import './index.scss'

const Mask = ({onLanguageFlag, handleLanguageType}) => {
    const language = [
        {
            id: 1,
            name: 'English',
            l_abb: 'en'
        },
        {
            id: 2,
            name: 'Español',
            l_abb: 'es'
        },
        {
            id: 3,
            name: 'Portugués (Brasil)',
            l_abb: 'pt-BR'
        },
        {
            id: 4,
            name: 'Portugués (Portugal)',
            l_abb: 'pt-PT'
        },
        {
            id: 5,
            name: 'Français',
            l_abb: 'fr'
        },
        {
            id: 6,
            name: 'Deutsche',
            l_abb: 'de'
        },
        {
            id: 7,
            name: 'Italiano',
            l_abb: 'it'
        },
        {
            id: 8,
            name: '日本語',
            l_abb: 'ja'
        },
        {
            id: 9,
            name: '한국어',
            l_abb: 'ko'
        },
        {
            id: 10,
            name: 'Nederlands',
            l_abb: 'nl'
        },
        {
            id: 11,
            name: 'Dansk',
            l_abb: 'da'
        },
        {
            id: 12,
            name: 'Norsk',
            l_abb: 'no'
        },
        {
            id: 13,
            name: 'Svenska',
            l_abb: 'sv'
        },
        {
            id: 14,
            name: 'Polish',
            l_abb: 'pl'
        },
    ]
    //将模态框的状态传递给父组件改变状态
    const close = () => {
        onLanguageFlag(false);
    }
    //选择语言
    const handleLanguage = (row) => {
        handleLanguageType(row)
        onLanguageFlag(false)
    }
    return (
        <div className="mask">
            <div className="mask-content">
                <div className="mask-title row alc jsb">
                    <div className="title-content">Change language</div>
                    <div style={{width: '20px', cursor: 'pointer'}} onClick={close}>
                        <svg viewBox="0 0 16 16">
                            <path fill="#333333"
                                  d="M15.1 2.3L13.7.9 8 6.6 2.3.9.9 2.3 6.6 8 .9 13.7l1.4 1.4L8 9.4l5.7 5.7 1.4-1.4L9.4 8"></path>
                        </svg>
                    </div>
                </div>
                <div className="mask-language">
                    {language.map((item, index) => {
                        return (
                            <div className="mask-language-item" key={index} onClick={() => handleLanguage(item)}>
                                {item.name}
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
export default Mask

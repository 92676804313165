import React from "react";
import routes from './router'
import {useRoutes} from "react-router";
import Layout from '@/Layout/index';

export default function App() {
    //根据路由表生成对应的路由规则
    const element = useRoutes(routes)
    return (
        <Layout>
            {/* 注册路由 */}
            {element}
        </Layout>
    );
}

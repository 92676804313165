import React, {useContext, useEffect} from 'react';
import '@/pages/checkout/components/shipping.scss';
import {DataContext} from "@/hooks/formMessage";
import '@/pages/checkout/index.scss'

const GoodsInfo = (props) => {
    useEffect(() => {
        if (props.orderInfo) {
            console.log(props.orderInfo)
        }
    }, [props.orderInfo])
    const {curInfo} = useContext(DataContext)
    return (
        <div>
            <div className="goods">
                <div className="goods-list">
                    <div className="goods-1">
                        <table className="goods-2">
                            <tbody className="goods-tbody">
                            {props.orderInfo.item_lines.map((item, index) => {
                                return (<tr key={index} className="goods-tr">
                                    <td className="goods-td" style={{paddingTop: index && '1rem'}}>
                                        <div className="goods-item">
                                            <div className="goods-img">
                                                <img src={item.image} className="img" alt=""/>
                                            </div>
                                            <span className="quantity">{item.quantity}</span>
                                        </div>
                                    </td>
                                    <td className="goods-title" style={{paddingTop: index && '1rem'}}>
                                            <span>
                                                {item.name}
                                            </span>
                                    </td>
                                    <td className="goods-price" style={{paddingTop: index && '1rem'}}>
                                        <span>{curInfo.currency_symbol}{item.price}</span>
                                    </td>
                                </tr>)
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="goods-total">
                    <table className="total-price">
                        <tbody>
                        <tr>
                            <th>Subtotal</th>
                            <td><span>{curInfo.currency_symbol}{props.orderInfo.subtotal_price}</span></td>
                        </tr>
                        {
                            <tr>
                                <th>Shipping</th>
                                <td>
                                    <span>{props.orderInfo.shipping_line.price !== '0.00' ? curInfo.currency_symbol : ''}{props.orderInfo.shipping_line.price !== '0.00' ? props.orderInfo.shipping_line.price : 'Free'}</span>
                                </td>
                            </tr>
                        }
                        </tbody>
                        <tfoot>
                        <tr>
                            <th style={{textAlign: 'left'}}><span
                                style={{fontWeight: 500, fontSize: '1rem'}}>Total</span>
                            </th>
                            <td style={{textAlign: 'right'}}><span
                                className="cur">{curInfo.currency}</span><span
                                className="price">{curInfo.currency_symbol}{props.orderInfo.total_price}</span>
                            </td>
                        </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <div style={{padding: '1rem'}}></div>
        </div>
    )
}
export default GoodsInfo

import React from 'react';

const UpOutlined = () => {
    return (
        <svg version="1.1" viewBox="0 0 11 6" className="sc-1m4eypi-0 ldFBxS"
             style={{
                 transition: 'all 0.3s ease-in-out 0s',
                 width: '0.6875rem',
                 height: ' 0.375rem',
                 transform: 'scale(1)',
                 opacity: 1
             }}>
            <path fill="#a26b25"
                  d="M.504 1.813l4.358 3.845.496.438.496-.438 4.642-4.096L9.504.438 4.862 4.534h.992L1.496.69.504 1.812z"></path>
        </svg>
    )
}
export default UpOutlined

import React, {useContext, useState} from 'react'
import './index.scss'
import {DataContext} from '@/hooks/formMessage';
import createEmailValidator from '@/utils/mailcheck'
import {trackEvent,postidlogs} from '@/api'

const Input = (props) => {
    const emailValidator = createEmailValidator();
    const {
        checkout,
        checkValidate,
        setCheckValidate,
        coupon,
        _createOrder,
        _updateOrder,
        requestFlag,
        incrementCount,
    } = useContext(DataContext)
    const [state, setState] = useState({
        value: '',
        inputFont: props.inputFont, //提示字
        flag: false, //控制聚焦失焦状态
        borFlag: false,
        icon_btn_flag: false, //点击优惠券的按钮图标切换状态
        //失焦时候的样式
        blur: {
            style_wal: {
                border: '1px solid rgb(217, 217, 217)',
                boxShadow: 'none'
            },
            style_ins: {
                opacity: 0,
                transform: 'translateY(3px)'
            },
            style_input: {
                padding: '0.92857em 0.78571em'
            }
        },
        //聚焦以后的样式
        focus: {
            style_wal: {
                border: '1px solid rgb(162, 107,37)',
                boxShadow: 'rgb(162,107,37) 0 0 0 1px'
            },
            style_ins: {
                opacity: 0.75,
                transform: 'none',
            },
            style_input: {
                padding: '0 0.78571em 0.35714em',
                marginTop: '1.5em',
                outline: 'none'
            }
        },
        error: {
            border: '1px solid rgb(227, 44, 43)',
            boxShadow: 'rgb(227, 44, 43) 0px 0px 0px 1px',
            color: 'rgb(227, 44, 43)'
        },
        fontError: {
            color: 'rgb(227, 44, 43)',
            opacity: 0.75,
            transform: 'none',
        },
    })
//失焦事件
    const handleBlur = () => {
        if (props.field === 'email') {
            setCheckValidate({
                ...checkValidate,
                email: emailValidator.validate(checkout[props.field]) ? '' : 'Invalid email'
            })
            if (emailValidator.validate(checkout[props.field])) {
                if(!requestFlag){
                    if(!localStorage.getItem('order_id')) _createOrder()
                    else _updateOrder()
                }
                trackEvent('email:' + checkout[props.field])
                postidlogs(4)
            }
        }
        if (!checkout[props.field]) {
            setState({
                ...state,
                inputFont: props.inputFont,
                flag: false,
                borFlag: false,
            })
        } else {
            setCheckValidate({
                ...checkValidate,
                [props.field]: ''
            })
            if (props.field !== 'email') {
                if(emailValidator.validate(checkout['email'])&&localStorage.getItem('order_id')&&!requestFlag) _updateOrder()
                trackEvent(props.field + ':' + checkout[props.field])
                postidlogs(4)
            }
            setState({
                ...state,
                borFlag: false,
            })
        }
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };
//聚焦事件
    const handleFocus = () => {
        setState({
            ...state,
            inputFont: '',
            flag: true,
            borFlag: true,
        })
    }
    const handleChange = (e) => {
        if (props.field === 'email') {
            setCheckValidate({
                ...checkValidate,
                email: emailValidator.validate(checkout[props.field]) ? '' : 'Invalid email'
            })
        }
        incrementCount(props.field, e.target.value); // 使用incrementCount函数更新全局状态
    }
//点击获取优惠折扣
    const handleCoupon = () => {
        if (!state.icon_btn_flag) return
        setState({
            ...state,
            icon_btn_flag: !state.icon_btn_flag
        })
    }
    return (
        <div className={props.inputFlagBtn ? "coupon-input" : 'coupon-from coupon-input'}>
            <table>
                <tbody>
                <tr>
                    <td>
                        <div className="input-form">
                            <div className="input-div">
                                <label className="label1"
                                       style={checkValidate[props.field] ? state.error : !state.borFlag ? state.blur.style_wal : state.focus.style_wal}>
                                    <label className="label2"
                                           style={!checkValidate[props.field] ? (!state.flag ? state.blur.style_ins : !checkValidate[props.field] ? state.focus.style_ins : state.fontError) : state.fontError}>{!checkValidate[props.field] ? props.inputFont : checkValidate[props.field]}</label>
                                    <input
                                        onKeyDown={handleKeyDown}
                                        name={props.field !== 'coupon' ? props.field : ''}
                                        value={props.field === 'coupon' ? coupon : checkout[props.field]}
                                        onChange={handleChange} type="text"
                                        style={!state.flag ? state.blur.style_input : state.focus.style_input}
                                        onBlur={handleBlur} onFocus={handleFocus} spellCheck="false"
                                        color="#333333"
                                        placeholder={checkValidate[props.field] ? '' : state.inputFont}/>
                                </label>
                            </div>
                        </div>
                    </td>
                    {props.inputFlagBtn && <td>
                        <button className="coupon-btn" style={{opacity: coupon.length ? 1 : .5}}
                                onClick={coupon.length ? handleCoupon : () => {
                                }}>
                            <span className="btn-font" style={{opacity: state.icon_btn_flag ? 0 : 1}}>
                                Apply
                            </span>
                            <svg viewBox="0 0 50 50" className="sc-18r6j1o-0 ghBPfO"
                                 style={{
                                     zIndex: state.icon_btn_flag ? 1 : 0,
                                     position: 'absolute',
                                     top: '50%',
                                     left: '50%',
                                     padding: 0,
                                     margin: '-0.583em',
                                     width: '1.16667em',
                                     height: '1.16667em',
                                     opacity: state.icon_btn_flag ? 1 : 0,
                                     cursor: 'pointer',
                                 }}>
                                <circle cx="25" cy="25" r="20" fill="none" stroke="#ffffff" strokeWidth="4"
                                        className="sc-18r6j1o-1 ctbLsL"></circle>
                            </svg>
                        </button>
                    </td>}
                </tr>
                </tbody>
            </table>
        </div>
    )
}

export default Input;

import React, { useContext, useEffect, useState } from 'react';
import styles from './index.module.scss';
import { getGoods, postidlogs, postRepurchase,getDiscount} from '@/api';
import { Button, InputNumber, Skeleton } from 'antd';
import { DataContext } from "@/hooks/formMessage";
import LoadingSvg from "@/pages/checkout/svgJsx/loadingSvg";
import Cookies from "js-cookie";
const Upsell = () => {
    const { curInfo } = useContext(DataContext);
    const [timeLeft, setTimeLeft] = useState(300); // 5 minutes = 300 seconds
    const [goods, setGoods] = useState({});
    const [selectedOptions, setSelectedOptions] = useState({});
    const [selectedVariant, setSelectedVariant] = useState(null);
    const [rudece_price,setRudecePrice] = useState('0.50');
    const [quantity, setQuantity] = useState(1); // 添加数量状态
    const [flag, setFlag] = useState(false);    //再买一单
    const [loading, setLoading] = useState(false);
    const [error,setError] = useState('');
    useEffect(() => {
        let List = JSON.parse(localStorage.getItem('shopify')).cart.items
        const itemTitle = List[List[0].product_title === 'Shipping Protection'?1:0]?.handle;
        const itemId = List[List[0].product_title === 'Shipping Protection'?1:0]?.product_id;
        if (itemId) {
            _getDiscount()
            _getGoods(process.env.REACT_APP_ENV === 'development'?itemId:itemTitle);
        }

        const timer = setInterval(() => {
            setTimeLeft(prevTime => {
                if (prevTime <= 1) {
                    clearInterval(timer); // Clear the timer
                    return 0; // Ensure the countdown doesn't go below 0
                }
                return prevTime - 1;
            });
        }, 1000);
        return () => clearInterval(timer);
    }, []);
    const _getDiscount = ()=>{
        getDiscount().then(res=>{
            console.log(res);
            if(res.code === 200){
                setRudecePrice(res.data.discount)
            }else{
                setRudecePrice('0.5')
            }
        })
    }
    const _getGoods = (type) => {
        if(process.env.REACT_APP_ENV !== 'development'){
            fetch( `/products/${type}.json`)
                .then(response => response.json())
                .then(res => {
                    console.log(res.product)
                    const data = res.product;
                    setGoods(data);
                    setSelectedVariant(data.variants[0]);
                    const defaultOptions = data.options.reduce((acc, option) => {
                        acc[option.name] = option.values[0]; // Default to first value
                        return acc;
                    }, {});
                    setSelectedOptions(defaultOptions);
                });
        }else{
            getGoods(type).then(res => {
                console.log(res);
                const data = res.data;
                setGoods(data);
                setSelectedVariant(data.variants[0]);
                const defaultOptions = data.options.reduce((acc, option) => {
                    acc[option.name] = option.values[0]; // Default to first value
                    return acc;
                }, {});
                setSelectedOptions(defaultOptions);
            }).catch(error => {
                console.error('Error fetching goods:', error);
            });
        }

    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    };

    const handleOptionChange = (optionName, value) => {
        const updatedOptions = {
            ...selectedOptions,
            [optionName]: value
        };
        setSelectedOptions(updatedOptions);
        const matchingVariant = goods.variants.find(variant =>
            Object.keys(updatedOptions).every(key =>
                variant[`option${goods.options.findIndex(option => option.name === key) + 1}`] === updatedOptions[key]
            )
        );
        setSelectedVariant(matchingVariant);
    };

    const handlePur = (type) => () => {
        if (flag) return;  // 如果已经点击，直接返回
        setFlag(true);
        let info = { ...selectedVariant };
        info.quantity = quantity;
        let data = {
            order_id: localStorage.getItem('order_id'),
            is_continue: type,
            product_title: goods.title,
            loading_path: window.shopify.session.url_prefix.replace('entry', 'credit-card-3ds-redirect-loading') + '?cid=' + window.shopify.session.client_id,
            return_path: window.shopify.session.url_prefix.replace('entry', 'upsell') + '?cid=' + window.shopify.session.client_id
        };
        if(window.location.href.includes('error=')) data.purchaser_status  = 0
        if (type) data.line_items = [info];
        else setLoading(true)
        postRepurchase(data).then(res => {
            if (res.code === 200) {
                window.location.replace(res.data.target_url);
                if (!type || window.location.href.includes('error=')) {
                    postidlogs(7)
                    localStorage.removeItem('order_id')
                    localStorage.removeItem('draft');
                    localStorage.setItem('url', window.shopify.session.host_href)
                    Cookies.remove('cart')
                }
            }else if(res.code ==='P1100'){
                setError(res.msg)
                setTimeout(()=>{
                    localStorage.removeItem('order_id')
                    localStorage.removeItem('draft');
                    localStorage.setItem('url', window.shopify.session.host_href)
                    Cookies.remove('cart')
                    window.location.replace(res.data.target_url);
                },3000)
            }
            setFlag(false);
        }).catch(error => {
            setFlag(false);
            console.error('Error purchasing goods:', error);
        });
    };

    return (
        <div>
            {!loading ? <div className={styles['content']}>
                <div className={styles['top']}>
                    Prepare for an Outstanding Shopping Experience
                </div>
                <div style={{ padding: '15px 0' }}>Exclusive One-Time Offer Just for You</div>
                <div style={{
                    padding: '15px 0',
                    background: '#FFC000',
                    width: '100%',
                    fontSize: '18PX',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    marginBottom: '30px'
                }}>
                    Hurry! Special Offer Expires in <span
                    style={{ fontSize: '24px', color: 'red', fontWeight: 550 }}>{formatTime(timeLeft)}</span>
                </div>
                {goods && goods.image && goods.image.src ? (
                    <div className={styles['goods']}>
                        <img alt="" src={goods.image.src} className={styles['imggoods']}/>
                        <div style={{flex: 1, textAlign: 'center'}}>
                            <div style={{marginBottom: '30px', fontSize: '30px', fontWeight: 550}}>
                                {goods.title}
                            </div>
                            <div style={{display: 'flex', justifyContent: 'center', marginBottom: '30px'}}>
                                <div
                                    style={{textDecoration: 'line-through '}}>{curInfo.currency_symbol}{Number(selectedVariant?.price).toFixed(2)}</div>
                                <div style={{marginLeft: '10px', color: 'red'}}>
                                    {curInfo.currency_symbol}{(Math.floor(((Number(selectedVariant.price) - Number(selectedVariant.price)* rudece_price ))*100) / 100).toFixed(2)} ({rudece_price * 100}% extra discount)
                                </div>
                            </div>
                            <div>
                                {goods.options && goods.options.map(option => (
                                    <div key={option.id} style={{marginBottom: '20px'}}>
                                        <div style={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap'}}>
                                            {option.values.length > 1 && option.values.map(value => (
                                                <Button
                                                    key={value}
                                                    type={selectedOptions[option.name] === value ? 'primary' : 'default'}
                                                    onClick={() => handleOptionChange(option.name, value)}
                                                    style={{
                                                        border: selectedOptions[option.name] === value ? '2px solid #1890ff' : '2px solid transparent',
                                                        margin: '5px',
                                                        padding: '10px 20px', // 调整按钮大小
                                                        fontSize: '14px', // 调整字体大小
                                                        height: '50px', // 调整按钮高度
                                                        background: 'transparent', // 设置按钮为空心
                                                        color: selectedOptions[option.name] === value ? '#1890ff' : '#000', // 设置文本颜色
                                                        borderRadius: '8px' // 圆角
                                                    }}
                                                >
                                                    {value}
                                                </Button>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div>
                                Quantity: <InputNumber
                                min={1}
                                value={quantity}
                                onChange={setQuantity}
                                style={{width: '100px', height: '40px', fontSize: '16px'}} // 增大输入框
                            />
                            </div>
                            {error&&<div style={{color: 'red', fontSize: '20px',marginTop:'20px'}}>{error}</div>}
                            <div style={{
                                marginTop: '20px',
                                display: 'flex',
                                justifyContent: "center"
                            }}>
                                <div className={styles['btn-order']}
                                     onClick={handlePur(1)}>{flag ?
                                    <img style={{width: '35px'}} className={styles['btnloading']}
                                         src={require('@/assets/image/loading.png')}
                                         alt=''/>: 'COMPLETE YOUR ORDER'
                                }</div>
                            </div>
                            <span style={{color: '#cbc9c9', cursor: "pointer", fontSize: '12px'}}
                                  onClick={handlePur(0)}>Continue without this</span>
                            <div style={{height: '50px'}}></div>
                        </div>
                    </div>
                ) : <Skeleton active/>}
            </div> : <div className={styles['content1']}>
                <div className={styles['svg-box']}>
                    <LoadingSvg></LoadingSvg>
                </div>
                <div className={styles['font']}>
                    Your order's being processed
                </div>
                <div className={styles['font']}>
                    If you're not automatically redirected, refresh this
                </div>
                <div className={styles['font']}>
                    page.
                </div>
            </div>}
        </div>
    );
};

export default Upsell;

import {createStore} from "redux";
const store = createStore((state = {
    user: {}
}, action) => {
    switch (action.type) {
        case "setUser":
            return {
                ...state,
                user: action.user
            }
        default:
            return state;
    }})
export default store


import React, { useRef, useState } from 'react';
import { Autocomplete } from '@react-google-maps/api';

const PlacesAutocomplete = () => {
    const autocomplete = useRef(null);
    const [address, setAddress] = useState('');
    const [place, setPlace] = useState(null);

    const onLoad = (autocompleteRef) => {
        autocomplete.current = autocompleteRef;
    };

    const onPlaceChanged = () => {
        if (autocomplete.current !== null) {
            const place = autocomplete.current.getPlace();
            setPlace(place);
            setAddress(place.formatted_address);
            console.log('Selected Place:', place); // 打印当前选中的地方
        } else {
            console.log('Autocomplete is not loaded yet!');
        }
    };

    return (
        <div>
            <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}         options={{
                componentRestrictions: { country:'cn' },
            }}>
                <input
                    type="text"
                    placeholder="Enter a location"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    style={{ width: '400px', height: '40px', padding: '10px' }}
                />
            </Autocomplete>
            {place && (
                <div>
                    <h4>{place.name}</h4>
                    <p>{place.formatted_address}</p>
                </div>
            )}
        </div>
    );
};

export default PlacesAutocomplete;

const country = {
    AF: {
        alpha3: "AFG",
        numeric: "004",
        value: "Afghanistan",
        name: "Afghanistan"
    },
    AX: {
        alpha3: "ALA",
        numeric: "248",
        value: "Aland Islands",
        name: "\xc5land Islands"
    },
    AL: {
        alpha3: "ALB",
        numeric: "008",
        value: "Albania",
        name: "Albania"
    },
    DZ: {
        alpha3: "DZA",
        numeric: "012",
        value: "Algeria",
        name: "Algeria"
    },
    AD: {
        alpha3: "AND",
        numeric: "020",
        value: "Andorra",
        name: "Andorra"
    },
    AO: {
        alpha3: "AGO",
        numeric: "024",
        value: "Angola",
        name: "Angola",
        layout: "layout1"
    },
    AI: {
        alpha3: "AIA",
        numeric: "660",
        value: "Anguilla",
        name: "Anguilla",
        layout: "layout1"
    },
    AG: {
        alpha3: "ATG",
        numeric: "028",
        value: "Antigua And Barbuda",
        name: "Antigua & Barbuda",
        layout: "layout1"
    },
    AR: {
        alpha3: "ARG",
        numeric: "032",
        value: "Argentina",
        name: "Argentina",
        fields: {
            province: "PROVINCE",
            zip: "POSTAL_CODE"
        },
        layout: "layout2"
    },
    AM: {
        alpha3: "ARM",
        numeric: "051",
        value: "Armenia",
        name: "Armenia"
    },
    AW: {
        alpha3: "ABW",
        numeric: "533",
        value: "Aruba",
        name: "Aruba",
        layout: "layout1"
    },
    AU: {
        alpha3: "AUS",
        numeric: "036",
        value: "Australia",
        name: "Australia",
        fields: {
            province: "STATE_TERRITORY",
            zip: "POSTCODE"
        },
        layout: "layout2"
    },
    AT: {
        alpha3: "AUT",
        numeric: "040",
        value: "Austria",
        name: "Austria"
    },
    AZ: {
        alpha3: "AZE",
        numeric: "031",
        value: "Azerbaijan",
        name: "Azerbaijan"
    },
    BS: {
        alpha3: "BHS",
        numeric: "044",
        value: "Bahamas",
        name: "Bahamas",
        layout: "layout1"
    },
    BH: {
        alpha3: "BHR",
        numeric: "048",
        value: "Bahrain",
        name: "Bahrain"
    },
    BD: {
        alpha3: "BGD",
        numeric: "050",
        value: "Bangladesh",
        name: "Bangladesh"
    },
    BB: {
        alpha3: "BRB",
        numeric: "052",
        value: "Barbados",
        name: "Barbados"
    },
    BY: {
        alpha3: "BLR",
        numeric: "112",
        value: "Belarus",
        name: "Belarus"
    },
    BE: {
        alpha3: "BEL",
        numeric: "056",
        value: "Belgium",
        name: "Belgium"
    },
    BZ: {
        alpha3: "BLZ",
        numeric: "084",
        value: "Belize",
        name: "Belize",
        layout: "layout1"
    },
    BJ: {
        alpha3: "BEN",
        numeric: "204",
        value: "Benin",
        name: "Benin",
        layout: "layout1"
    },
    BM: {
        alpha3: "BMU",
        numeric: "060",
        value: "Bermuda",
        name: "Bermuda"
    },
    BT: {
        alpha3: "BTN",
        numeric: "064",
        value: "Bhutan",
        name: "Bhutan"
    },
    BO: {
        alpha3: "BOL",
        numeric: "068",
        value: "Bolivia",
        name: "Bolivia",
        layout: "layout1"
    },
    BA: {
        alpha3: "BIH",
        numeric: "070",
        value: "Bosnia And Herzegovina",
        name: "Bosnia & Herzegovina"
    },
    BW: {
        alpha3: "BWA",
        numeric: "072",
        value: "Botswana",
        name: "Botswana"
    },
    BV: {
        alpha3: "BVT",
        numeric: "074",
        value: "Bouvet Island",
        name: "Bouvet Island"
    },
    BR: {
        alpha3: "BRA",
        numeric: "076",
        value: "Brazil",
        name: "Brazil",
        fields: {
            province: "STATE",
            zip: "POSTAL_CODE"
        },
        layout: "layout2"
    },
    IO: {
        alpha3: "IOT",
        numeric: "086",
        value: "British Indian Ocean Territory",
        name: "British Indian Ocean Territory"
    },
    VG: {
        alpha3: "VGB",
        numeric: "092",
        value: "Virgin Islands, British",
        name: "British Virgin Islands"
    },
    BN: {
        alpha3: "BRN",
        numeric: "096",
        value: "Brunei",
        name: "Brunei"
    },
    BG: {
        alpha3: "BGR",
        numeric: "100",
        value: "Bulgaria",
        name: "Bulgaria"
    },
    BF: {
        alpha3: "BFA",
        numeric: "854",
        value: "Burkina Faso",
        name: "Burkina Faso",
        layout: "layout1"
    },
    BI: {
        alpha3: "BDI",
        numeric: "108",
        value: "Burundi",
        name: "Burundi"
    },
    KH: {
        alpha3: "KHM",
        numeric: "116",
        value: "Cambodia",
        name: "Cambodia"
    },
    CM: {
        alpha3: "CMR",
        numeric: "120",
        value: "Republic of Cameroon",
        name: "Cameroon"
    },
    CA: {
        alpha3: "CAN",
        numeric: "124",
        value: "Canada",
        name: "Canada",
        fields: {
            province: "PROVINCE",
            zip: "POSTAL_CODE"
        },
        layout: "layout2"
    },
    CV: {
        alpha3: "CPV",
        numeric: "132",
        value: "Cape Verde",
        name: "Cape Verde"
    },
    BQ: {
        alpha3: "BES",
        numeric: "535",
        value: "Bonaire, Sint Eustatius and Saba",
        name: "Caribbean Netherlands"
    },
    KY: {
        alpha3: "CYM",
        numeric: "136",
        value: "Cayman Islands",
        name: "Cayman Islands"
    },
    CF: {
        alpha3: "CAF",
        numeric: "140",
        value: "Central African Republic",
        name: "Central African Republic"
    },
    TD: {
        alpha3: "TCD",
        numeric: "148",
        value: "Chad",
        name: "Chad",
        layout: "layout1"
    },
    CL: {
        alpha3: "CHL",
        numeric: "152",
        value: "Chile",
        name: "Chile"
    },
    CN: {
        alpha3: "CHN",
        numeric: "156",
        value: "China",
        name: "China",
        fields: {
            province: "PROVINCE",
            zip: "POSTAL_CODE"
        },
        layout: "layout2",
        lastNameFirst: !0
    },
    CX: {
        alpha3: "CXR",
        numeric: "162",
        value: "Christmas Island",
        name: "Christmas Island"
    },
    CC: {
        alpha3: "CCK",
        numeric: "166",
        value: "Cocos (Keeling) Islands",
        name: "Cocos (Keeling) Islands"
    },
    CO: {
        alpha3: "COL",
        numeric: "170",
        value: "Colombia",
        name: "Colombia",
        fields: {
            province: "PROVINCE",
            zip: "POSTAL_CODE"
        },
        layout: "layout2"
    },
    KM: {
        alpha3: "COM",
        numeric: "174",
        value: "Comoros",
        name: "Comoros"
    },
    CG: {
        alpha3: "COG",
        numeric: "178",
        value: "Congo",
        name: "Congo - Brazzaville"
    },
    CD: {
        alpha3: "COD",
        numeric: "180",
        value: "Congo, The Democratic Republic Of The",
        name: "Congo - Kinshasa"
    },
    CK: {
        alpha3: "COK",
        numeric: "184",
        value: "Cook Islands",
        name: "Cook Islands"
    },
    CR: {
        alpha3: "CRI",
        numeric: "188",
        value: "Costa Rica",
        name: "Costa Rica"
    },
    HR: {
        alpha3: "HRV",
        numeric: "191",
        value: "Croatia",
        name: "Croatia"
    },
    CU: {
        alpha3: "CUB",
        numeric: "192",
        value: "Cuba",
        name: "Cuba"
    },
    CW: {
        alpha3: "CUW",
        numeric: "531",
        value: "Cura\xe7ao",
        name: "Cura\xe7ao",
        layout: "layout1"
    },
    CY: {
        alpha3: "CYP",
        numeric: "196",
        value: "Cyprus",
        name: "Cyprus"
    },
    CZ: {
        alpha3: "CZE",
        numeric: "203",
        value: "Czech Republic",
        name: "Czech Republic"
    },
    CI: {
        alpha3: "CIV",
        numeric: "384",
        value: "C\xf4te d'Ivoire",
        name: "C\xf4te d\u2019Ivoire"
    },
    DK: {
        alpha3: "DNK",
        numeric: "208",
        value: "Denmark",
        name: "Denmark"
    },
    DJ: {
        alpha3: "DJI",
        numeric: "262",
        value: "Djibouti",
        name: "Djibouti"
    },
    DM: {
        alpha3: "DMA",
        numeric: "212",
        value: "Dominica",
        name: "Dominica"
    },
    DO: {
        alpha3: "DOM",
        numeric: "214",
        value: "Dominican Republic",
        name: "Dominican Republic"
    },
    EC: {
        alpha3: "ECU",
        numeric: "218",
        value: "Ecuador",
        name: "Ecuador"
    },
    EG: {
        alpha3: "EGY",
        numeric: "818",
        value: "Egypt",
        name: "Egypt",
        fields: {
            province: "GOVERNORATE",
            zip: "POSTAL_CODE"
        },
        layout: "layout2"
    },
    SV: {
        alpha3: "SLV",
        numeric: "222",
        value: "El Salvador",
        name: "El Salvador"
    },
    GQ: {
        alpha3: "GNQ",
        numeric: "226",
        value: "Equatorial Guinea",
        name: "Equatorial Guinea"
    },
    ER: {
        alpha3: "ERI",
        numeric: "232",
        value: "Eritrea",
        name: "Eritrea"
    },
    EE: {
        alpha3: "EST",
        numeric: "233",
        value: "Estonia",
        name: "Estonia"
    },
    ET: {
        alpha3: "ETH",
        numeric: "231",
        value: "Ethiopia",
        name: "Ethiopia"
    },
    FK: {
        alpha3: "FLK",
        numeric: "238",
        value: "Falkland Islands (Malvinas)",
        name: "Falkland Islands"
    },
    FO: {
        alpha3: "FRO",
        numeric: "234",
        value: "Faroe Islands",
        name: "Faroe Islands"
    },
    FJ: {
        alpha3: "FJI",
        numeric: "242",
        value: "Fiji",
        name: "Fiji",
        layout: "layout1"
    },
    FI: {
        alpha3: "FIN",
        numeric: "246",
        value: "Finland",
        name: "Finland"
    },
    FR: {
        alpha3: "FRA",
        numeric: "250",
        value: "France",
        name: "France"
    },
    GF: {
        alpha3: "GUF",
        numeric: "254",
        value: "French Guiana",
        name: "French Guiana"
    },
    PF: {
        alpha3: "PYF",
        numeric: "258",
        value: "French Polynesia",
        name: "French Polynesia"
    },
    TF: {
        alpha3: "ATF",
        numeric: "260",
        value: "French Southern Territories",
        name: "French Southern Territories"
    },
    GA: {
        alpha3: "GAB",
        numeric: "266",
        value: "Gabon",
        name: "Gabon"
    },
    GM: {
        alpha3: "GMB",
        numeric: "270",
        value: "Gambia",
        name: "Gambia"
    },
    GE: {
        alpha3: "GEO",
        numeric: "268",
        value: "Georgia",
        name: "Georgia"
    },
    DE: {
        alpha3: "DEU",
        numeric: "276",
        value: "Germany",
        name: "Germany"
    },
    GH: {
        alpha3: "GHA",
        numeric: "288",
        value: "Ghana",
        name: "Ghana",
        layout: "layout1"
    },
    GI: {
        alpha3: "GIB",
        numeric: "292",
        value: "Gibraltar",
        name: "Gibraltar"
    },
    GR: {
        alpha3: "GRC",
        numeric: "300",
        value: "Greece",
        name: "Greece"
    },
    GL: {
        alpha3: "GRL",
        numeric: "304",
        value: "Greenland",
        name: "Greenland"
    },
    GD: {
        alpha3: "GRD",
        numeric: "308",
        value: "Grenada",
        name: "Grenada"
    },
    GP: {
        alpha3: "GLP",
        numeric: "312",
        value: "Guadeloupe",
        name: "Guadeloupe"
    },
    GT: {
        alpha3: "GTM",
        numeric: "320",
        value: "Guatemala",
        name: "Guatemala",
        fields: {
            province: "REGION",
            zip: "POSTAL_CODE"
        },
        layout: "layout2"
    },
    GG: {
        alpha3: "GGY",
        numeric: "831",
        value: "Guernsey",
        name: "Guernsey"
    },
    GN: {
        alpha3: "GIN",
        numeric: "324",
        value: "Guinea",
        name: "Guinea"
    },
    GW: {
        alpha3: "GNB",
        numeric: "624",
        value: "Guinea Bissau",
        name: "Guinea-Bissau"
    },
    GY: {
        alpha3: "GUY",
        numeric: "328",
        value: "Guyana",
        name: "Guyana"
    },
    HT: {
        alpha3: "HTI",
        numeric: "332",
        value: "Haiti",
        name: "Haiti"
    },
    HM: {
        alpha3: "HMD",
        numeric: "334",
        value: "Heard Island And Mcdonald Islands",
        name: "Heard & McDonald Islands"
    },
    HN: {
        alpha3: "HND",
        numeric: "340",
        value: "Honduras",
        name: "Honduras"
    },
    HK: {
        alpha3: "HKG",
        numeric: "344",
        value: "Hong Kong",
        name: "Hong Kong SAR China",
        fields: {
            province: "REGION"
        },
        layout: "layout4",
        lastNameFirst: !0
    },
    HU: {
        alpha3: "HUN",
        numeric: "348",
        value: "Hungary",
        name: "Hungary"
    },
    IS: {
        alpha3: "ISL",
        numeric: "352",
        value: "Iceland",
        name: "Iceland"
    },
    IN: {
        alpha3: "IND",
        numeric: "356",
        value: "India",
        name: "India",
        fields: {
            province: "STATE",
            zip: "PIN_CODE"
        },
        layout: "layout2"
    },
    ID: {
        alpha3: "IDN",
        numeric: "360",
        value: "Indonesia",
        name: "Indonesia",
        fields: {
            province: "PROVINCE",
            zip: "POSTAL_CODE"
        },
        layout: "layout2"
    },
    IR: {
        alpha3: "IRN",
        numeric: "364",
        value: "Iran, Islamic Republic Of",
        name: "Iran"
    },
    IQ: {
        alpha3: "IRQ",
        numeric: "368",
        value: "Iraq",
        name: "Iraq"
    },
    IE: {
        alpha3: "IRL",
        numeric: "372",
        value: "Ireland",
        name: "Ireland",
        fields: {
            province: "COUNTY",
            zip: "POSTAL_CODE"
        },
        layout: "layout2"
    },
    IM: {
        alpha3: "IMN",
        numeric: "833",
        value: "Isle Of Man",
        name: "Isle of Man"
    },
    IL: {
        alpha3: "ISR",
        numeric: "376",
        value: "Israel",
        name: "Israel"
    },
    IT: {
        alpha3: "ITA",
        numeric: "380",
        value: "Italy",
        name: "Italy",
        fields: {
            province: "PROVINCE",
            zip: "POSTAL_CODE"
        },
        layout: "layout2"
    },
    JM: {
        alpha3: "JAM",
        numeric: "388",
        value: "Jamaica",
        name: "Jamaica",
        layout: "layout1"
    },
    JP: {
        alpha3: "JPN",
        numeric: "392",
        value: "Japan",
        name: "Japan",
        fields: {
            province: "PREFECTURE",
            zip: "POSTAL_CODE"
        },
        layout: "layout3",
        lastNameFirst: !0
    },
    JE: {
        alpha3: "JEY",
        numeric: "832",
        value: "Jersey",
        name: "Jersey"
    },
    JO: {
        alpha3: "JOR",
        numeric: "400",
        value: "Jordan",
        name: "Jordan"
    },
    KZ: {
        alpha3: "KAZ",
        numeric: "398",
        value: "Kazakhstan",
        name: "Kazakhstan"
    },
    KE: {
        alpha3: "KEN",
        numeric: "404",
        value: "Kenya",
        name: "Kenya"
    },
    KI: {
        alpha3: "KIR",
        numeric: "296",
        value: "Kiribati",
        name: "Kiribati"
    },
    XK: {
        alpha3: "XKX",
        numeric: "",
        value: "Kosovo",
        name: "Kosovo"
    },
    KW: {
        alpha3: "KWT",
        numeric: "414",
        value: "Kuwait",
        name: "Kuwait"
    },
    KG: {
        alpha3: "KGZ",
        numeric: "417",
        value: "Kyrgyzstan",
        name: "Kyrgyzstan"
    },
    LA: {
        alpha3: "LAO",
        numeric: "418",
        value: "Lao People's Democratic Republic",
        name: "Laos"
    },
    LV: {
        alpha3: "LVA",
        numeric: "428",
        value: "Latvia",
        name: "Latvia"
    },
    LB: {
        alpha3: "LBN",
        numeric: "422",
        value: "Lebanon",
        name: "Lebanon"
    },
    LS: {
        alpha3: "LSO",
        numeric: "426",
        value: "Lesotho",
        name: "Lesotho"
    },
    LR: {
        alpha3: "LBR",
        numeric: "430",
        value: "Liberia",
        name: "Liberia"
    },
    LY: {
        alpha3: "LBY",
        numeric: "434",
        value: "Libyan Arab Jamahiriya",
        name: "Libya"
    },
    LI: {
        alpha3: "LIE",
        numeric: "438",
        value: "Liechtenstein",
        name: "Liechtenstein"
    },
    LT: {
        alpha3: "LTU",
        numeric: "440",
        value: "Lithuania",
        name: "Lithuania"
    },
    LU: {
        alpha3: "LUX",
        numeric: "442",
        value: "Luxembourg",
        name: "Luxembourg"
    },
    MO: {
        alpha3: "MAC",
        numeric: "446",
        value: "Macao",
        name: "Macau SAR China",
        lastNameFirst: !0
    },
    MK: {
        alpha3: "MKD",
        numeric: "807",
        value: "Macedonia, Republic Of",
        name: "Macedonia"
    },
    MG: {
        alpha3: "MDG",
        numeric: "450",
        value: "Madagascar",
        name: "Madagascar"
    },
    MW: {
        alpha3: "MWI",
        numeric: "454",
        value: "Malawi",
        name: "Malawi"
    },
    MY: {
        alpha3: "MYS",
        numeric: "458",
        value: "Malaysia",
        name: "Malaysia",
        fields: {
            province: "STATE_TERRITORY",
            zip: "POSTAL_CODE"
        },
        layout: "layout2"
    },
    MV: {
        alpha3: "MDV",
        numeric: "462",
        value: "Maldives",
        name: "Maldives"
    },
    ML: {
        alpha3: "MLI",
        numeric: "466",
        value: "Mali",
        name: "Mali",
        layout: "layout1"
    },
    MT: {
        alpha3: "MLT",
        numeric: "470",
        value: "Malta",
        name: "Malta"
    },
    MQ: {
        alpha3: "MTQ",
        numeric: "474",
        value: "Martinique",
        name: "Martinique"
    },
    MR: {
        alpha3: "MRT",
        numeric: "478",
        value: "Mauritania",
        name: "Mauritania"
    },
    MU: {
        alpha3: "MUS",
        numeric: "480",
        value: "Mauritius",
        name: "Mauritius"
    },
    YT: {
        alpha3: "MYT",
        numeric: "175",
        value: "Mayotte",
        name: "Mayotte"
    },
    MX: {
        alpha3: "MEX",
        numeric: "484",
        value: "Mexico",
        name: "Mexico",
        fields: {
            province: "STATE",
            zip: "POSTAL_CODE"
        },
        layout: "layout2"
    },
    MD: {
        alpha3: "MDA",
        numeric: "498",
        value: "Moldova, Republic of",
        name: "Moldova"
    },
    MC: {
        alpha3: "MCO",
        numeric: "492",
        value: "Monaco",
        name: "Monaco"
    },
    MN: {
        alpha3: "MNG",
        numeric: "496",
        value: "Mongolia",
        name: "Mongolia"
    },
    ME: {
        alpha3: "MNE",
        numeric: "499",
        value: "Montenegro",
        name: "Montenegro"
    },
    MS: {
        alpha3: "MSR",
        numeric: "500",
        value: "Montserrat",
        name: "Montserrat"
    },
    MA: {
        alpha3: "MAR",
        numeric: "504",
        value: "Morocco",
        name: "Morocco"
    },
    MZ: {
        alpha3: "MOZ",
        numeric: "508",
        value: "Mozambique",
        name: "Mozambique"
    },
    MM: {
        alpha3: "MMR",
        numeric: "104",
        value: "Myanmar",
        name: "Myanmar (Burma)"
    },
    NA: {
        alpha3: "NAM",
        numeric: "516",
        value: "Namibia",
        name: "Namibia"
    },
    NR: {
        alpha3: "NRU",
        numeric: "520",
        value: "Nauru",
        name: "Nauru"
    },
    NP: {
        alpha3: "NPL",
        numeric: "524",
        value: "Nepal",
        name: "Nepal"
    },
    NL: {
        alpha3: "NLD",
        numeric: "528",
        value: "Netherlands",
        name: "Netherlands"
    },
    AN: {
        alpha3: "",
        numeric: "",
        value: "Netherlands Antilles",
        name: "Netherlands Antilles"
    },
    NC: {
        alpha3: "NCL",
        numeric: "540",
        value: "New Caledonia",
        name: "New Caledonia"
    },
    NZ: {
        alpha3: "NZL",
        numeric: "554",
        value: "New Zealand",
        name: "New Zealand",
        fields: {
            province: "REGION",
            zip: "POSTAL_CODE"
        },
        layout: "layout2"
    },
    NI: {
        alpha3: "NIC",
        numeric: "558",
        value: "Nicaragua",
        name: "Nicaragua"
    },
    NE: {
        alpha3: "NER",
        numeric: "562",
        value: "Niger",
        name: "Niger"
    },
    NG: {
        alpha3: "NGA",
        numeric: "566",
        value: "Nigeria",
        name: "Nigeria",
        fields: {
            province: "STATE",
            zip: "POSTAL_CODE"
        },
        layout: "layout2"
    },
    NU: {
        alpha3: "NIU",
        numeric: "570",
        value: "Niue",
        name: "Niue"
    },
    NF: {
        alpha3: "NFK",
        numeric: "574",
        value: "Norfolk Island",
        name: "Norfolk Island"
    },
    KP: {
        alpha3: "PRK",
        numeric: "408",
        value: "Korea, Democratic People's Republic Of",
        name: "North Korea",
        lastNameFirst: !0
    },
    NO: {
        alpha3: "NOR",
        numeric: "578",
        value: "Norway",
        name: "Norway"
    },
    OM: {
        alpha3: "OMN",
        numeric: "512",
        value: "Oman",
        name: "Oman"
    },
    PK: {
        alpha3: "PAK",
        numeric: "586",
        value: "Pakistan",
        name: "Pakistan"
    },
    PS: {
        alpha3: "PSE",
        numeric: "275",
        value: "Palestinian Territory, Occupied",
        name: "Palestinian Territories"
    },
    PA: {
        alpha3: "PAN",
        numeric: "591",
        value: "Panama",
        name: "Panama",
        fields: {
            province: "REGION"
        },
        layout: "layout4"
    },
    PG: {
        alpha3: "PNG",
        numeric: "598",
        value: "Papua New Guinea",
        name: "Papua New Guinea"
    },
    PY: {
        alpha3: "PRY",
        numeric: "600",
        value: "Paraguay",
        name: "Paraguay"
    },
    PE: {
        alpha3: "PER",
        numeric: "604",
        value: "Peru",
        name: "Peru"
    },
    PH: {
        alpha3: "PHL",
        numeric: "608",
        value: "Philippines",
        name: "Philippines",
        fields: {
            province: "REGION",
            zip: "POSTAL_CODE"
        },
        layout: "layout6"
    },
    PN: {
        alpha3: "PCN",
        numeric: "612",
        value: "Pitcairn",
        name: "Pitcairn Islands"
    },
    PL: {
        alpha3: "POL",
        numeric: "616",
        value: "Poland",
        name: "Poland"
    },
    PT: {
        alpha3: "PRT",
        numeric: "620",
        value: "Portugal",
        name: "Portugal",
        fields: {
            province: "REGION",
            zip: "POSTAL_CODE"
        },
        layout: "layout2"
    },
    QA: {
        alpha3: "QAT",
        numeric: "634",
        value: "Qatar",
        name: "Qatar",
        layout: "layout1"
    },
    RE: {
        alpha3: "REU",
        numeric: "638",
        value: "Reunion",
        name: "R\xe9union"
    },
    RO: {
        alpha3: "ROU",
        numeric: "642",
        value: "Romania",
        name: "Romania",
        fields: {
            province: "COUNTY",
            zip: "POSTAL_CODE"
        },
        layout: "layout2"
    },
    RU: {
        alpha3: "RUS",
        numeric: "643",
        value: "Russia",
        name: "Russia",
        fields: {
            province: "REGION",
            zip: "POSTAL_CODE"
        },
        layout: "layout2"
    },
    RW: {
        alpha3: "RWA",
        numeric: "646",
        value: "Rwanda",
        name: "Rwanda"
    },
    WS: {
        alpha3: "WSM",
        numeric: "882",
        value: "Samoa",
        name: "Samoa"
    },
    SM: {
        alpha3: "SMR",
        numeric: "674",
        value: "San Marino",
        name: "San Marino"
    },
    ST: {
        alpha3: "STP",
        numeric: "678",
        value: "Sao Tome And Principe",
        name: "S\xe3o Tom\xe9 & Pr\xedncipe"
    },
    SA: {
        alpha3: "SAU",
        numeric: "682",
        value: "Saudi Arabia",
        name: "Saudi Arabia"
    },
    SN: {
        alpha3: "SEN",
        numeric: "686",
        value: "Senegal",
        name: "Senegal"
    },
    RS: {
        alpha3: "SRB",
        numeric: "688",
        value: "Serbia",
        name: "Serbia"
    },
    SC: {
        alpha3: "SYC",
        numeric: "690",
        value: "Seychelles",
        name: "Seychelles"
    },
    SL: {
        alpha3: "SLE",
        numeric: "694",
        value: "Sierra Leone",
        name: "Sierra Leone"
    },
    SG: {
        alpha3: "SGP",
        numeric: "702",
        value: "Singapore",
        name: "Singapore"
    },
    SX: {
        alpha3: "SXM",
        numeric: "534",
        value: "Sint Maarten",
        name: "Sint Marteen"
    },
    SK: {
        alpha3: "SVK",
        numeric: "703",
        value: "Slovakia",
        name: "Slovakia"
    },
    SI: {
        alpha3: "SVN",
        numeric: "705",
        value: "Slovenia",
        name: "Slovenia"
    },
    SB: {
        alpha3: "SLB",
        numeric: "090",
        value: "Solomon Islands",
        name: "Solomon Islands"
    },
    SO: {
        alpha3: "SOM",
        numeric: "706",
        value: "Somalia",
        name: "Somalia"
    },
    ZA: {
        alpha3: "ZAF",
        numeric: "710",
        value: "South Africa",
        name: "South Africa",
        fields: {
            province: "PROVINCE",
            zip: "POSTAL_CODE"
        },
        layout: "layout2"
    },
    GS: {
        alpha3: "SGS",
        numeric: "239",
        value: "South Georgia And The South Sandwich Islands",
        name: "South Georgia & South Sandwich Islands"
    },
    KR: {
        alpha3: "KOR",
        numeric: "410",
        value: "South Korea",
        name: "South Korea",
        fields: {
            province: "PROVINCE",
            zip: "POSTAL_CODE"
        },
        layout: "layout3",
        lastNameFirst: !0
    },
    SS: {
        alpha3: "SSD",
        numeric: "728",
        value: "South Sudan",
        name: "South Sudan",
        layout: "layout1"
    },
    ES: {
        alpha3: "ESP",
        numeric: "724",
        value: "Spain",
        name: "Spain",
        fields: {
            province: "PROVINCE",
            zip: "POSTAL_CODE"
        },
        layout: "layout2"
    },
    LK: {
        alpha3: "LKA",
        numeric: "144",
        value: "Sri Lanka",
        name: "Sri Lanka"
    },
    BL: {
        alpha3: "BLM",
        numeric: "652",
        value: "Saint Barth\xe9lemy",
        name: "St. Barth\xe9lemy"
    },
    SH: {
        alpha3: "SHN",
        numeric: "654",
        value: "Saint Helena",
        name: "St. Helena"
    },
    KN: {
        alpha3: "KNA",
        numeric: "659",
        value: "Saint Kitts And Nevis",
        name: "St. Kitts & Nevis"
    },
    LC: {
        alpha3: "LCA",
        numeric: "662",
        value: "Saint Lucia",
        name: "St. Lucia"
    },
    MF: {
        alpha3: "MAF",
        numeric: "663",
        value: "Saint Martin",
        name: "St. Martin"
    },
    PM: {
        alpha3: "SPM",
        numeric: "666",
        value: "Saint Pierre And Miquelon",
        name: "St. Pierre & Miquelon"
    },
    VC: {
        alpha3: "VCT",
        numeric: "670",
        value: "St. Vincent",
        name: "St. Vincent & Grenadines"
    },
    SD: {
        alpha3: "SDN",
        numeric: "729",
        value: "Sudan",
        name: "Sudan"
    },
    SR: {
        alpha3: "SUR",
        numeric: "740",
        value: "Suriname",
        name: "Suriname"
    },
    SJ: {
        alpha3: "SJM",
        numeric: "744",
        value: "Svalbard And Jan Mayen",
        name: "Svalbard & Jan Mayen"
    },
    SZ: {
        alpha3: "SWZ",
        numeric: "748",
        value: "Swaziland",
        name: "Swaziland"
    },
    SE: {
        alpha3: "SWE",
        numeric: "752",
        value: "Sweden",
        name: "Sweden"
    },
    CH: {
        alpha3: "CHE",
        numeric: "756",
        value: "Switzerland",
        name: "Switzerland"
    },
    SY: {
        alpha3: "SYR",
        numeric: "760",
        value: "Syria",
        name: "Syria"
    },
    TW: {
        alpha3: "TWN",
        numeric: "158",
        value: "Taiwan",
        name: "Taiwan"
    },
    TJ: {
        alpha3: "TJK",
        numeric: "762",
        value: "Tajikistan",
        name: "Tajikistan"
    },
    TZ: {
        alpha3: "TZA",
        numeric: "834",
        value: "Tanzania, United Republic Of",
        name: "Tanzania"
    },
    TH: {
        alpha3: "THA",
        numeric: "764",
        value: "Thailand",
        name: "Thailand",
        fields: {
            province: "PROVINCE",
            zip: "POSTAL_CODE"
        },
        layout: "layout2"
    },
    TL: {
        alpha3: "TLS",
        numeric: "626",
        value: "Timor Leste",
        name: "Timor-Leste"
    },
    TG: {
        alpha3: "TGO",
        numeric: "768",
        value: "Togo",
        name: "Togo",
        layout: "layout1"
    },
    TK: {
        alpha3: "TKL",
        numeric: "772",
        value: "Tokelau",
        name: "Tokelau"
    },
    TO: {
        alpha3: "TON",
        numeric: "776",
        value: "Tonga",
        name: "Tonga"
    },
    TT: {
        alpha3: "TTO",
        numeric: "780",
        value: "Trinidad and Tobago",
        name: "Trinidad & Tobago",
        layout: "layout1"
    },
    TN: {
        alpha3: "TUN",
        numeric: "788",
        value: "Tunisia",
        name: "Tunisia"
    },
    TR: {
        alpha3: "TUR",
        numeric: "792",
        value: "Turkey",
        name: "Turkey"
    },
    TM: {
        alpha3: "TKM",
        numeric: "795",
        value: "Turkmenistan",
        name: "Turkmenistan"
    },
    TC: {
        alpha3: "TCA",
        numeric: "796",
        value: "Turks and Caicos Islands",
        name: "Turks & Caicos Islands"
    },
    TV: {
        alpha3: "TUV",
        numeric: "798",
        value: "Tuvalu",
        name: "Tuvalu",
        layout: "layout1"
    },
    UM: {
        alpha3: "UMI",
        numeric: "581",
        value: "United States Minor Outlying Islands",
        name: "U.S. Outlying Islands"
    },
    UG: {
        alpha3: "UGA",
        numeric: "800",
        value: "Uganda",
        name: "Uganda",
        layout: "layout1"
    },
    UA: {
        alpha3: "UKR",
        numeric: "804",
        value: "Ukraine",
        name: "Ukraine"
    },
    AE: {
        alpha3: "ARE",
        numeric: "784",
        value: "United Arab Emirates",
        name: "United Arab Emirates",
        fields: {
            province: "EMIRATE"
        },
        layout: "layout4"
    },
    GB: {
        alpha3: "GBR",
        numeric: "826",
        value: "United Kingdom",
        name: "United Kingdom"
    },
    US: {
        alpha3: "USA",
        numeric: "840",
        value: "United States",
        name: "United States",
        fields: {
            province: "STATE",
            zip: "ZIP_CODE"
        },
        layout: "layout2"
    },
    UY: {
        alpha3: "URY",
        numeric: "858",
        value: "Uruguay",
        name: "Uruguay"
    },
    UZ: {
        alpha3: "UZB",
        numeric: "860",
        value: "Uzbekistan",
        name: "Uzbekistan"
    },
    VU: {
        alpha3: "VUT",
        numeric: "548",
        value: "Vanuatu",
        name: "Vanuatu"
    },
    VA: {
        alpha3: "VAT",
        numeric: "336",
        value: "Holy See (Vatican City State)",
        name: "Vatican City"
    },
    VE: {
        alpha3: "VEN",
        numeric: "862",
        value: "Venezuela",
        name: "Venezuela"
    },
    VN: {
        alpha3: "VNM",
        numeric: "704",
        value: "Vietnam",
        name: "Vietnam"
    },
    WF: {
        alpha3: "WLF",
        numeric: "876",
        value: "Wallis And Futuna",
        name: "Wallis & Futuna"
    },
    EH: {
        alpha3: "ESH",
        numeric: "732",
        value: "Western Sahara",
        name: "Western Sahara"
    },
    YE: {
        alpha3: "YEM",
        numeric: "887",
        value: "Yemen",
        name: "Yemen",
        layout: "layout1"
    },
    ZM: {
        alpha3: "ZMB",
        numeric: "894",
        value: "Zambia",
        name: "Zambia"
    },
    ZW: {
        alpha3: "ZWE",
        numeric: "716",
        value: "Zimbabwe",
        name: "Zimbabwe",
        layout: "layout1"
    }
}
export default country

import Checkout from '@/pages/checkout';
import Loading from '@/pages/loading/loading';
import Home from "@/pages/homeold";
import Test from "@/pages/test";
import Upsell from '@/pages/upsell';
import Succeed from '@/pages/succeed'
import {Navigate} from "react-router";
import getShopifyPath from '@/utils/throttle'
const routes = [
    {
        path: getShopifyPath('entry'),
        element: <Checkout/>,
    },
    {
        path: getShopifyPath('credit-card-3ds-redirect-loading'), // 使用 * 捕获所有参数
        element: <Loading/>,
    },
    {
        path: getShopifyPath('upsell'),
        element: <Upsell/>,
    },
    {
        path: getShopifyPath('succeed'),
        element: <Succeed/>,
    },
    {
        path: '/',
        element: <Home/>,
    },
    {
        path: '/test',
        element: <Test/>,
    },
    {
        path: '*',
        element: <Navigate to=""/>
    },
];
export default routes;

import React, {useContext, useEffect, useState} from 'react';
import selectStyles from './index.module.scss'
import country from './country'
import {DataContext} from '@/hooks/formMessage';
// layout1 不填写邮编 不填写区
// layout2 填写邮编 填写区
// layout3 填写邮编 填写区
// layout4 不填写邮编 填写区
// 无layout 填写邮编  不填写区
const country_code = ["AF", "AX", "AL", "DZ", "AD", "AO", "AI", "AG", "AR", "AM", "AW", "AU", "AT", "AZ", "BS", "BH", "BD", "BB", "BY", "BE", "BZ", "BJ", "BM", "BT", "BO", "BA", "BW", "BV", "BR", "IO", "VG", "BN", "BG", "BF", "BI", "KH", "CM", "CA", "CV", "BQ", "KY", "CF", "TD", "CL", "CN", "CX", "CC", "CO", "KM", "CG", "CD", "CK", "CR", "HR", "CU", "CW", "CY", "CZ", "CI", "DK", "DJ", "DM", "DO", "EC", "EG", "SV", "GQ", "ER", "EE", "ET", "FK", "FO", "FJ", "FI", "FR", "GF", "PF", "TF", "GA", "GM", "GE", "DE", "GH", "GI", "GR", "GL", "GD", "GP", "GT", "GG", "GN", "GW", "GY", "HT", "HM", "HN", "HK", "HU", "IS", "IN", "ID", "IR", "IQ", "IE", "IM", "IL", "IT", "JM", "JP", "JE", "JO", "KZ", "KE", "KI", "XK", "KW", "KG", "LA", "LV", "LB", "LS", "LR", "LY", "LI", "LT", "LU", "MO", "MK", "MG", "MW", "MY", "MV", "ML", "MT", "MQ", "MR", "MU", "YT", "MX", "MD", "MC", "MN", "ME", "MS", "MA", "MZ", "MM", "NA", "NR", "NP", "NL", "AN", "NC", "NZ", "NI", "NE", "NG", "NU", "NF", "KP", "NO", "OM", "PK", "PS", "PA", "PG", "PY", "PE", "PH", "PN", "PL", "PT", "QA", "RE", "RO", "RU", "RW", "WS", "SM", "ST", "SA", "SN", "RS", "SC", "SL", "SG", "SX", "SK", "SI", "SB", "SO", "ZA", "GS", "KR", "SS", "ES", "LK", "BL", "SH", "KN", "LC", "MF", "PM", "VC", "SD", "SR", "SJ", "SZ", "SE", "CH", "SY", "TW", "TJ", "TZ", "TH", "TL", "TG", "TK", "TO", "TT", "TN", "TR", "TM", "TC", "TV", "UM", "UG", "UA", "AE", "GB", "US", "UY", "UZ", "VU", "VA", "VE", "VN", "WF", "EH", "YE", "ZM", "ZW"]
const SelectInput = (props) => {
    const {billingAddressCount, billingAddress} = useContext(DataContext)
    const {onCountryChanged} = props //传给父组件的国家数据
    const [state, setState] = useState({
        countryInfo: country[country_code['AF']],
        selectLineBor: false,
        country_code: 'AF',
        country: 'Afghanistan'
    })
    const handleSelect = (e, type) => {
        console.log(e, type)
        setState({
            ...state,
            country_code: e.target.value,
            countryInfo: country[e.target.value],
            country: country[e.target.value].name
        })
        onCountryChanged(country[e.target.value], e.target.value)
    }
    useEffect(() => {
        billingAddress['country_code'] = state.country_code
        billingAddress['country'] = state.country
        billingAddressCount('country', state.country)
        billingAddressCount('country_code', state.country_code)
        // eslint-disable-next-line
    }, [state.country_code, state.country])
    const handleFocus = () => {
        setState({
            ...state,
            selectLineBor: true
        })
    }
    const handleBlur = () => {
        setState({
            ...state,
            selectLineBor: false
        })
    }
    return (
        <div className={selectStyles['content']}>
            <div className={selectStyles['checkfy']}>
                <div className={selectStyles['rela-check']}>
                    <div className={selectStyles['check-label']}>
                        <label className={selectStyles['label']} style={{opacity: state.selectLineBor ? '1' : '.75'}}>
                            {props.selectType}
                        </label>
                    </div>
                    <div className={selectStyles['select-check']}>
                        <div className={selectStyles['select-box']}>
                            <select className={selectStyles['select']}
                                    onChange={(...rest) => handleSelect(...rest, props.selectNum)}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    value={state.country_code}
                                    autoComplete="shipping country" color="#333333"
                                    style={{
                                        border: state.selectLineBor ? '1px solid rgb(162,107,37)' : '1px solid rgb(217, 217, 217)',
                                        boxShadow: 'none'
                                    }}>
                                {
                                    country_code.map((item, index) => {
                                        return (<option key={index} value={item} color="#333333"
                                                        className="uepder-8 dyCcSX">{country[item].name}</option>
                                        )
                                    })
                                }
                            </select>
                            <div className={selectStyles['icon']}>
                                <svg version="1.1" viewBox="0 0 21 19">
                                    <g fill="none" fillRule="evenodd">
                                        <g fill="#333333">
                                            <g>
                                                <path d="M0 0h1v19H0V0z" fillOpacity="0.15"></path>
                                                <path d="M11 8h10l-5 5-5-5z" fillOpacity="0.5"></path>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SelectInput;

import request from '@/utils/request'
console.log(process.env)
export const getToken = (id) => {
    return request('get', `/session-token/${id}`)
}
//创建订单
export const createOrder = (data) => {
    return request('post', '/draft-create', data)
}
//更新订单
export const updateOrder = (id, data) => {
    return request('put', `/draft-modify/${id}`, data)
}
//下单
export const placeOrder = (id, data) => {
    return request('post', `/place-order/${id}`, data)
}
//根据选择的国家获取运费
export const getFreight = (params) => {
    return request('get', '/shipping-lines', {...params,currency_rate:window.shopify.cart.currency_rate})
}
//查询支付方式
export const getPaymentEnable = () => {
    return request('get', '/payment-enable-get')
}
//获取订单状态
export const getOrderState = (id) => {
    return request('get', `/order-status/${id}`)
}
//提取订单成功页面数据

export const getOrderDetail = (id) => {
    return request('get', `/order-detail/${id}`)
}

//跳转shopify成功页面
export const getOrderStateUrl = (id) => {
    return request('get', `/order-status-url/${id}`)
}
//埋点机制
export const trackEvent = (logs) => {
    return request('post', '/order-logs', {checkout_id:window.shopify.session.id,logs,order_id:localStorage.getItem('order_id')})
}
//获取订单  //这个地方写全地址是为了兼容测试环境，前台的api+v1
export const getOrderList = (params) => {
    return request('get',process.env.REACT_APP_ENV === 'production'?"https://uvinn.com/api/dashboard":"https://amazoeo.com/api/dashboard",params)
}
//获取商品详情
export const getGoods = (id) => {
    return request('get', `/product/${id}`)
}
//重复购买商品
export const postRepurchase = (data) => {
    return request('post', `/repurchase/${data.order_id}`,data)
}
//lp埋点
export const postidlogs = (lp_type) => {
    return request('post', `/ip-logs`,{lp_type})
}

//获取加购折扣
export const getDiscount = () => {
    return request('get', '/repurchase/discount')
}
//获取ak金额
export const postCalculate = (data) => {
    return request('post', '/calculate-total-set',data)
}
//paypal快捷支付
export const postPaypalQuick = (data) => {
    return request('post', '/quick-draft-create',data)
}

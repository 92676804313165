import React from 'react';
import './svg.scss'
const BigCart = () => {
    return (
        <svg version="1.1" viewBox="-252.3 356.1 163 80.9">
            <path stroke="#545454" strokeWidth="2" strokeMiterlimit="10"
                  d="M-108.9 404.1v30c0 1.1-.9 2-2 2h-120.1c-1.1 0-2-.9-2-2v-75c0-1.1.9-2 2-2h120.1c1.1 0 2 .9 2 2v37m-124.1-29h124.1"
                  fill="none"></path>
            <circle fill="#545454" cx="-227.8" cy="361.9" r="1.8"></circle>
            <circle fill="#545454" cx="-222.2" cy="361.9" r="1.8"></circle>
            <circle fill="#545454" cx="-216.6" cy="361.9" r="1.8"></circle>
            <path stroke="#545454" strokeWidth="2" strokeMiterlimit="10" d="M-128.7 400.1h36.7m-3.6-4.1l4 4.1-4 4.1"
                  fill="none"></path>
        </svg>
    )
}
export default BigCart

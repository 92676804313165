import React, {useContext, useEffect, useState} from 'react'
import styles from './index.module.scss'
import LeftOutlined from '../../svgJsx/LeftOutlined'
import {DataContext} from '@/hooks/formMessage';
import createEmailValidator from "@/utils/mailcheck";
import {placeOrder,trackEvent,postidlogs} from '@/api'
// import {useNavigate} from 'react-router-dom';

const Button = () => {
    // const navigate = useNavigate();
    const emailValidator = createEmailValidator();
    let {
        checkout, //获取收货地址信息
        billingAddress,//账单地址
        disposalData,
        setStepIndex,//修改当前步骤条
        stepIndex, //当前步骤
        billingCheck, //控制选项，是否回显自定义填写账单地址
        layout,//当前信息填写表单的自定义布局选项（根据选择国家变化） 收货地址
        billingLayout,//当前信息填写表单的自定义布局选项（根据选择国家变化）账单地址
        setCheckValidate, //收货地址校验
        oneOrThreeStep, //当前页面的步骤是一步或者三步
        payment, //选择的支付方式
        asiabillInfo,
        paymentList,
        setErrorMsg,
        shippingPrice,
        errorMsg,
        setBillingAddressValidate, //账单地址校验
        _updateOrder,
        _createOrder
    } = useContext(DataContext)
    const [btn_font, setBtnFont] = useState('Continue to shipping')
    const [return_font, setReturnFont] = useState('cart')
    const [btn_flag, setBtnFlag] = useState(false)

    const form_error_info = {
        email: 'Invalid email',
        first_name: 'First name is required',
        last_name: 'Last name is required',
        phone: 'Invalid phone',
        address_1: 'Address required',
        city: 'City required',
        postal_code: 'Postal code is required',
        zip: 'Postal code is required',
        province: 'State required',
    }
    useEffect(() => {
        if (oneOrThreeStep === 1) {
            setBtnFont('Place Order')
            setReturnFont('cart')
        } else {
            if (stepIndex === 2) {
                setReturnFont('contact')
                setBtnFont('Continue to payment')
            } else if (stepIndex === 3) {
                setReturnFont('shipping')
                setBtnFont('Place Order')
            } else {
                setReturnFont('cart')
                setBtnFont('Continue to shipping')
            }
        }
    }, [stepIndex, oneOrThreeStep])
    //下单
    const _placeOrder = async (cusID) => {
        let info = disposalData()
        let data = {
            billingAddress: payment ? info.shipping_address : billingCheck ? info.shipping_address : info.billing_address,
            payment_id: paymentList[payment].payment_id,
            loading_path: window.shopify.session.url_prefix.replace('entry', 'credit-card-3ds-redirect-loading') + '?cid=' + window.shopify.session.client_id,
            return_path: window.shopify.session.url_prefix + '?cid=' + window.shopify.session.client_id,
        }
        if (!payment) data.customerPaymentMethodId = cusID
        let res = await placeOrder(localStorage.getItem('order_id'), data)
        if (res.code === 200) {
            if (errorMsg) {
                setErrorMsg('')
            }
            // if (!payment) navigate(window.shopify.session.url_prefix.replace('entry', 'credit-card-3ds-redirect-loading') + '?cid=' + window.shopify.session.client_id)
             window.location.href = res.data.approval_url
        } else if (res.code === 'S0001' && !errorMsg) {
            setErrorMsg(res.msg)
        }
    }
    //更新提交信用卡订单
    const _updateSubmitOrder = async () => {
        await asiabillInfo.ab.confirmPaymentMethod({
            apikey: asiabillInfo.token,
            trnxDetail: {
                "billingDetail": {
                    "address": {
                        "city": billingCheck ? checkout.city : billingAddress.city,
                        "country": billingCheck ? checkout.country_code : billingAddress.country_code,
                        "line1": billingCheck ? checkout.address_1 : billingAddress.address_1,
                        "line2": billingCheck ? checkout.address_2 : billingAddress.address_2,
                        "postalCode": billingCheck ? checkout.zip : billingAddress.zip,
                        "state": billingCheck ? checkout.province : billingAddress.province
                    },
                    "email": checkout.email,
                    "firstName": billingCheck ? checkout.first_name : billingAddress.first_name,
                    "lastName": billingCheck ? checkout.last_name : billingAddress.last_name,
                    "phone": billingCheck ? checkout.phone : billingAddress.phone
                },
            }
        }).then(async (result) => {
            // console.info("PaymentMethodObject_Result", JSON.stringify(result));
            let r = result;
            if (r.data.message === "success") {
                // your code
                await _placeOrder(r.data.data.customerPaymentMethodId)
            } else {
                console.error(r);
            }
        });
    }
    const stepIndexFun = (index)=>{
        postidlogs(index === 1?8:9)
        trackEvent(`step:${index === 1?2:3}`)
    }
    const _OK = async () => {
        console.log(checkout)
        if (btn_flag && btn_flag) return //如果按钮已经是加载状态了禁用掉按钮的点击事件
        const flag = await checkoutForeach()
        if (flag) {
            setBtnFlag(true)
            if (oneOrThreeStep === 3) {
                if (stepIndex < 3) {
                    setTimeout(() => {
                        setStepIndex(prevStepIndex => prevStepIndex + 1)
                        stepIndexFun(stepIndex)
                        setBtnFlag(false)
                    }, 1500)
                }
                if (stepIndex === 3) {
                    if (shippingPrice.length === 0) return setBtnFlag(false)  //不存在运费方案的时候返回
                    if (!localStorage.getItem('order_id')) await _createOrder()
                    else await _updateOrder() //判断是否创建过订单
                    if (!payment) await _updateSubmitOrder() //判断是否是信用卡支付
                    else {
                        if (!errorMsg) await _placeOrder(null)
                    }
                    setBtnFlag(false) //请求完成改变按钮状态
                }
            } else {
                if (shippingPrice.length === 0) return setBtnFlag(false)  //不存在运费方案的时候返回
                if (!localStorage.getItem('order_id')) await _createOrder()
                else await _updateOrder() //判断是否创建过订单
                if (!payment) await _updateSubmitOrder() //判断是否是信用卡支付
                else {
                    await _placeOrder(null)
                }
                setBtnFlag(false) //请求完成改变按钮状态
            }
        }
    }
    //遍历checkout对象，将无值的单位赋值给到setCheckValidate，用来回显错误
    const checkoutForeach = async (flag) => {
        const info = {}
        var error = ['Invalid email', 'First name is required', 'Last name is required', 'Invalid phone', 'Address required', 'City required', 'Postal code is required', 'State required']
        if ([undefined, 'layout1'].includes(layout)) {
            error = error.filter(errorMessage => errorMessage !== 'State required');
        }
        if (['layout1', 'layout4'].includes(layout)) {
            error = error.filter(errorMessage => errorMessage !== 'Postal code is required');
        }
        // console.log(error,layout)
        Object.entries(checkout).forEach(([key, value]) => {
            if (!value) {
                flag = false;
                info[key] = form_error_info[key]
            } else {
                // flag = true;
                info[key] = null
            }
        });
        setCheckValidate(info)
        for (const key in info) {
            flag = !error.includes(info[key]);
            if (!flag) {
                window.scrollTo(0, 0)
                break;
            }
        }
        if (checkout.email && !emailValidator.validate(checkout.email)) { //重新校验邮箱是否正确
            setCheckValidate({
                email: form_error_info.email
            })
            window.scrollTo(0, 0)
            flag = false
        }
        if (!billingCheck && flag && !payment) { //此处通过条件判断是否校验billing账单地址表单
            var errorBilling = ['Invalid email', 'First name is required', 'Last name is required', 'Invalid phone', 'Address required', 'City required', 'Postal code is required', 'State required']
            if ([undefined, 'layout1'].includes(billingLayout)) {
                errorBilling = errorBilling.filter(errorMessage => errorMessage !== 'State required');
            }
            if (['layout1', 'layout4'].includes(billingLayout)) {
                errorBilling = errorBilling.filter(errorMessage => errorMessage !== 'Postal code is required');
            }
            let billingInfo = {}
            Object.entries(billingAddress).forEach(([key, value]) => {
                if (!value) {
                    flag = false;
                    billingInfo[key] = form_error_info[key]
                } else {
                    // flag = true;
                    billingInfo[key] = null
                }
            });
            setBillingAddressValidate(billingInfo)
            for (const key in billingInfo) {
                flag = !errorBilling.includes(billingInfo[key]);
                if (!flag) {
                    window.scrollTo(0, 1000)
                    break;
                }
            }
        }
        return flag
    }
    //返回上一步
    const handleReturn = () => {
        if (oneOrThreeStep === 1) {
            window.location.replace('/cart')
        } else {
            if (stepIndex === 3) {
                setStepIndex(2)
            } else if (stepIndex === 2) {
                setStepIndex(1)
            } else {
                window.location.replace('/cart')
            }
        }
    }
    return (
        <div className={`${styles['step-bottom-content']} ${styles['content']} content`} style={{padding: '0 .875rem'}}>
            <div className={styles['btn-font']} onClick={handleReturn}>
                <LeftOutlined></LeftOutlined>
                <span>Return to {return_font}</span>
            </div>
            <button className={styles['btn']} onClick={_OK} id="ab_submit">
                <span style={{opacity: !btn_flag ? '1' : '0'}}>{btn_font}</span>
                <svg viewBox="0 0 50 50" className={styles['ghBPfO']}
                     style={{
                         position: 'absolute',
                         top: '50%',
                         left: '50%',
                         margin: '-0.75em',
                         width: '1.5em',
                         height: '1.5em',
                         opacity: !btn_flag ? '0' : '1',
                         cursor: 'pointer',
                     }}>
                    <circle cx="25" cy="25" r="20" fill="none" stroke="#ffffff" strokeWidth="4"
                            className={styles['ctbLsL']}></circle>
                </svg>
            </button>
        </div>)
}
export default Button

function createEmailValidator() {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

    function validateEmail(email) {
        return emailRegex.test(email);
    }

    return {
        validate: validateEmail
    };
}

export default createEmailValidator


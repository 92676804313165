import React from 'react';
import { createRoot } from 'react-dom/client';
import '@/assets/styles/common.scss';
import 'reset-css'
import App from './App';
import {BrowserRouter} from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import { DataProvider } from '@/hooks/formMessage';
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    // <React.StrictMode>
        <BrowserRouter>
            <DataProvider>
                <Provider store={store}>
                    <App />
                </Provider>
            </DataProvider>
        </BrowserRouter>
    // </React.StrictMode>,
);

import React, {useContext, useRef, useState, useEffect} from 'react';
import styles from './index.module.scss';
import Input from '@/components/inputStyle/index';
import SelectInput from '@/components/selectInput/index';
import VinceSelect from "@/components/selectInput/vinceSelect";
import {DataContext} from '@/hooks/formMessage';
import {Autocomplete} from '@react-google-maps/api';
import PaypalButtons from "@/pages/checkout/components/paypalButtons";
const ContactInformation = () => {
    const {checkValidate, setLayout, setCheckout, checkout, handleSelect} = useContext(DataContext);
    const [check, setCheck] = useState(true);
    const [countryInfo, setCountryInfo] = useState({
        alpha3: "USA",
        numeric: "840",
        value: "United States",
        name: "United States",
        fields: {
            province: "STATE",
            zip: "ZIP_CODE"
        },
        layout: "layout2"
    });
    const [state, setState] = useState({
        code: "",
    });
    const autocomplete = useRef(null);
    const [isGoogleApiLoaded, setIsGoogleApiLoaded] = useState(false); // Changed initial state to false

    const onLoad = (autocompleteRef) => {
        autocomplete.current = autocompleteRef;
    };

    const onPlaceChanged = () => {
        if (autocomplete.current !== null) {
            const place = autocomplete.current.getPlace();
            const addressComponents = place.address_components || [];
            console.log(place);
            const address1 = addressComponents.find(component => component.types.includes("street_address") || component.types.includes("route"))?.long_name || '';
            const address2 = addressComponents.find(component => component.types.includes("subpremise"))?.long_name || '';
            const city = addressComponents.find(component => component.types.includes("locality"))?.long_name || '';
            const postalCode = addressComponents.find(component => component.types.includes("postal_code"))?.long_name || '';
            const stateFullName = addressComponents.find(component => component.types.includes("administrative_area_level_1"))?.long_name || '';
            const stateAbbreviation = addressComponents.find(component => component.types.includes("administrative_area_level_1"))?.short_name || '';
            handleSelect(stateAbbreviation, 1);
            setCheckout({
                ...checkout,
                address_1: address1,
                address_2: address2,
                city: city,
                zip: postalCode,
                province: stateFullName,
                province_code: stateAbbreviation,
            });
        } else {
            console.log('Autocomplete is not loaded yet!');
        }
    };

    useEffect(() => {
        const checkGoogleApi = () => {
            if (window.google && window.google.maps) {
                setIsGoogleApiLoaded(true); // If Google Maps API is loaded
            } else {
                setIsGoogleApiLoaded(false); // If Google Maps API is not loaded
            }
        };

        checkGoogleApi();
    }, []);

    const clickCheckBox = () => {
        setCheck(!check);
    };

    const handleCountryInfo = (e, type) => {
        setState({
            code: type
        });
        setCountryInfo(e);
        setLayout(e.layout);
        setCheckout({
            ...checkout,
            province: '',
            province_code: ''
        });

        if (![undefined, 'layout2', 'layout3'].includes(countryInfo.layout)) {
            setCheckout({
                ...checkout,
                zip: '',
            });
        }
    };

    return (
        <div className={`${styles['content']} content`}>
            {
                <div style={{marginTop: '35px'}}>
                    <div className={styles['bExNsX']}>
                        <PaypalButtons></PaypalButtons>
                    </div>
                    <div color="#333333" className={styles['kUHzRC']}><span color="#333333"
                                                                            className={styles['bZgvYU']}>OR</span>
                    </div>
                </div>
            }
            <div className={styles['font-title']}>
                Contact information
            </div>
            <form>
                <Input inputFont="Email (For order confirmation)" field="email" validate={checkValidate.email}></Input>
                <div className={styles['check-box']} onClick={clickCheckBox}>
                    <div className={styles['checkBox']}>
                        <input type="checkbox" readOnly
                               className={`${styles['hpWIfr']} ${check ? styles['hpWIfr'] : styles['kllTyF']}`}/>
                    </div>
                    <label color="#333333" className={styles["gHmHgN"]}>Keep me up to date on news and exclusive
                        offers</label>
                </div>
                <div className={styles['font-title']}>
                    Shipping address
                </div>
                <div className={styles['is-arrange']}>
                    <Input inputFont="First name" field="first_name" validate={checkValidate.first_name}></Input>
                    <Input inputFont="Last name" field="last_name" validate={checkValidate.last_name}></Input>
                </div>

                {isGoogleApiLoaded ? (
                    <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged} options={{
                        componentRestrictions: {country: checkout.country_code},
                    }}>
                        <Input inputFont="Address" field="address_1" validate={checkValidate.address_1}></Input>
                    </Autocomplete>
                ) : (
                    <Input inputFont="Address" field="address_1" validate={checkValidate.address_1}></Input>
                )}

                <Input inputFont="Apartment, suite, etc. (optional)" field="address_2"></Input>
                <Input inputFont="City" field="city" validate={checkValidate.city}></Input>
                <div className={styles['is-arrange']}>
                    <SelectInput selectType={'Country/Region'} onCountryChanged={handleCountryInfo}></SelectInput>
                    {(['layout2', 'layout3', 'layout4'].includes(countryInfo.layout)) &&
                        <VinceSelect selectType={'Province'} code={state.code}
                                     validate={['layout2', 'layout3', 'layout4'].includes(countryInfo.layout) ? checkValidate.province : ''}></VinceSelect>}
                    {([undefined, 'layout2', 'layout3'].includes(countryInfo.layout)) &&
                        <Input inputFont="Postal code" field="zip"
                               validate={![undefined, 'layout2', 'layout3'].includes(countryInfo.layout) ? checkValidate.zip : ''}></Input>}
                </div>
                <Input inputFont="Phone (For shipping updates)" field="phone" validate={checkValidate.phone}></Input>
            </form>
        </div>
    )
}

export default ContactInformation;

import React from 'react'

const Loading = () => {
    return (
        <svg version="1.1" viewBox="0 0 512 512">
            <g>
                <path fill="#a26b25"
                      d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z"></path>
            </g>
        </svg>
    )
}
export default Loading

import React from 'react';

const LeftOutlined = () => {
    return (
        <svg version="1.1" viewBox="0 0 10 10" className="sc-1m4eypi-0 ldFBxS"
             style={{width: '9.8px', position: 'relative', top: '1px', marginRight: '0.25rem',}}>
            <path fill="currentColor" d="M8 1L7 0 3 4 2 5l1 1 4 4 1-1-4-4"></path>
        </svg>
    )
}
export default LeftOutlined

import React from 'react';
import './svg.scss'
const Baishitong = () => {
    return (
        <span className="sc-1oqx2z8-3 fhOnKH"><svg focusable="false" xmlns="http://www.w3.org/2000/svg"
                                                   viewBox="0 0 32 21"><g fill="none" fillRule="evenodd"><g><g
            transform="translate(0 2)"><path fill="#003663"
                                             d="M26.58 19H2.42A2.4 2.4 0 0 1 0 16.62V2.38A2.4 2.4 0 0 1 2.42 0h24.16A2.4 2.4 0 0 1 29 2.38v14.25A2.4 2.4 0 0 1 26.58 19z"></path><circle
            cx="10.5" cy="9.5" r="6.5" fill="#eb1c26"></circle><circle cx="18.5" cy="9.5" r="6.5"
                                                                       fill="#f99f1b"></circle><path fill="#ef5d20"
                                                                                                     d="M14.5 4.38a6.49 6.49 0 0 0 0 10.24 6.49 6.49 0 0 0 0-10.24z"></path></g></g></g></svg></span>
    )
}
export default Baishitong

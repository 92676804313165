const province = {
    AR: [{
        code: "B",
        value: "Buenos Aires",
        name: "Buenos Aires"
    }, {
        code: "K",
        value: "Catamarca",
        name: "Catamarca"
    }, {
        code: "H",
        value: "Chaco",
        name: "Chaco"
    }, {
        code: "U",
        value: "Chubut",
        name: "Chubut"
    }, {
        code: "C",
        value: "Ciudad Aut\xf3noma de Buenos Aires",
        name: "Ciudad Aut\xf3noma de Buenos Aires"
    }, {
        code: "X",
        value: "C\xf3rdoba",
        name: "C\xf3rdoba"
    }, {
        code: "W",
        value: "Corrientes",
        name: "Corrientes"
    }, {
        code: "E",
        value: "Entre R\xedos",
        name: "Entre R\xedos"
    }, {
        code: "P",
        value: "Formosa",
        name: "Formosa"
    }, {
        code: "Y",
        value: "Jujuy",
        name: "Jujuy"
    }, {
        code: "L",
        value: "La Pampa",
        name: "La Pampa"
    }, {
        code: "F",
        value: "La Rioja",
        name: "La Rioja"
    }, {
        code: "M",
        value: "Mendoza",
        name: "Mendoza"
    }, {
        code: "N",
        value: "Misiones",
        name: "Misiones"
    }, {
        code: "Q",
        value: "Neuqu\xe9n",
        name: "Neuqu\xe9n"
    }, {
        code: "R",
        value: "R\xedo Negro",
        name: "R\xedo Negro"
    }, {
        code: "A",
        value: "Salta",
        name: "Salta"
    }, {
        code: "J",
        value: "San Juan",
        name: "San Juan"
    }, {
        code: "D",
        value: "San Luis",
        name: "San Luis"
    }, {
        code: "Z",
        value: "Santa Cruz",
        name: "Santa Cruz"
    }, {
        code: "S",
        value: "Santa Fe",
        name: "Santa Fe"
    }, {
        code: "G",
        value: "Santiago Del Estero",
        name: "Santiago Del Estero"
    }, {
        code: "V",
        value: "Tierra Del Fuego",
        name: "Tierra Del Fuego"
    }, {
        code: "T",
        value: "Tucum\xe1n",
        name: "Tucum\xe1n"
    }],
    AU:
        [{
            code: "ACT",
            value: "Australian Capital Territory",
            name: "Australian Capital Territory"
        }, {
            code: "NSW",
            value: "New South Wales",
            name: "New South Wales"
        }, {
            code: "NT",
            value: "Northern Territory",
            name: "Northern Territory"
        }, {
            code: "QLD",
            value: "Queensland",
            name: "Queensland"
        }, {
            code: "SA",
            value: "South Australia",
            name: "South Australia"
        }, {
            code: "TAS",
            value: "Tasmania",
            name: "Tasmania"
        }, {
            code: "VIC",
            value: "Victoria",
            name: "Victoria"
        }, {
            code: "WA",
            value: "Western Australia",
            name: "Western Australia"
        }],
    BR:
        [{
            code: "AC",
            value: "Acre",
            name: "Acre"
        }, {
            code: "AL",
            value: "Alagoas",
            name: "Alagoas"
        }, {
            code: "AP",
            value: "Amap\xe1",
            name: "Amap\xe1"
        }, {
            code: "AM",
            value: "Amazonas",
            name: "Amazonas"
        }, {
            code: "BA",
            value: "Bahia",
            name: "Bahia"
        }, {
            code: "CE",
            value: "Cear\xe1",
            name: "Cear\xe1"
        }, {
            code: "DF",
            value: "Distrito Federal",
            name: "Distrito Federal"
        }, {
            code: "ES",
            value: "Esp\xedrito Santo",
            name: "Esp\xedrito Santo"
        }, {
            code: "GO",
            value: "Goi\xe1s",
            name: "Goi\xe1s"
        }, {
            code: "MA",
            value: "Maranh\xe3o",
            name: "Maranh\xe3o"
        }, {
            code: "MT",
            value: "Mato Grosso",
            name: "Mato Grosso"
        }, {
            code: "MS",
            value: "Mato Grosso do Sul",
            name: "Mato Grosso do Sul"
        }, {
            code: "MG",
            value: "Minas Gerais",
            name: "Minas Gerais"
        }, {
            code: "PA",
            value: "Par\xe1",
            name: "Par\xe1"
        }, {
            code: "PB",
            value: "Para\xedba",
            name: "Para\xedba"
        }, {
            code: "PR",
            value: "Paran\xe1",
            name: "Paran\xe1"
        }, {
            code: "PE",
            value: "Pernambuco",
            name: "Pernambuco"
        }, {
            code: "PI",
            value: "Piau\xed",
            name: "Piau\xed"
        }, {
            code: "RN",
            value: "Rio Grande do Norte",
            name: "Rio Grande do Norte"
        }, {
            code: "RS",
            value: "Rio Grande do Sul",
            name: "Rio Grande do Sul"
        }, {
            code: "RJ",
            value: "Rio de Janeiro",
            name: "Rio de Janeiro"
        }, {
            code: "RO",
            value: "Rond\xf4nia",
            name: "Rond\xf4nia"
        }, {
            code: "RR",
            value: "Roraima",
            name: "Roraima"
        }, {
            code: "SC",
            value: "Santa Catarina",
            name: "Santa Catarina"
        }, {
            code: "SP",
            value: "S\xe3o Paulo",
            name: "S\xe3o Paulo"
        }, {
            code: "SE",
            value: "Sergipe",
            name: "Sergipe"
        }, {
            code: "TO",
            value: "Tocantins",
            name: "Tocantins"
        }],
    CA:
        [{
            code: "AB",
            value: "Alberta",
            name: "Alberta"
        }, {
            code: "BC",
            value: "British Columbia",
            name: "British Columbia"
        }, {
            code: "MB",
            value: "Manitoba",
            name: "Manitoba"
        }, {
            code: "NB",
            value: "New Brunswick",
            name: "New Brunswick"
        }, {
            code: "NL",
            value: "Newfoundland",
            name: "Newfoundland"
        }, {
            code: "NT",
            value: "Northwest Territories",
            name: "Northwest Territories"
        }, {
            code: "NS",
            value: "Nova Scotia",
            name: "Nova Scotia"
        }, {
            code: "NU",
            value: "Nunavut",
            name: "Nunavut"
        }, {
            code: "ON",
            value: "Ontario",
            name: "Ontario"
        }, {
            code: "PE",
            value: "Prince Edward Island",
            name: "Prince Edward Island"
        }, {
            code: "QC",
            value: "Quebec",
            name: "Quebec"
        }, {
            code: "SK",
            value: "Saskatchewan",
            name: "Saskatchewan"
        }, {
            code: "YT",
            value: "Yukon",
            name: "Yukon"
        }],
    CN:
        [{
            code: "AH",
            value: "Anhui",
            name: "Anhui"
        }, {
            code: "BJ",
            value: "Beijing",
            name: "Beijing"
        }, {
            code: "CQ",
            value: "Chongqing",
            name: "Chongqing"
        }, {
            code: "FJ",
            value: "Fujian",
            name: "Fujian"
        }, {
            code: "GS",
            value: "Gansu",
            name: "Gansu"
        }, {
            code: "GD",
            value: "Guangdong",
            name: "Guangdong"
        }, {
            code: "GX",
            value: "Guangxi",
            name: "Guangxi"
        }, {
            code: "GZ",
            value: "Guizhou",
            name: "Guizhou"
        }, {
            code: "HI",
            value: "Hainan",
            name: "Hainan"
        }, {
            code: "HE",
            value: "Hebei",
            name: "Hebei"
        }, {
            code: "HL",
            value: "Heilongjiang",
            name: "Heilongjiang"
        }, {
            code: "HA",
            value: "Henan",
            name: "Henan"
        }, {
            code: "HB",
            value: "Hubei",
            name: "Hubei"
        }, {
            code: "HN",
            value: "Hunan",
            name: "Hunan"
        }, {
            code: "NM",
            value: "Inner Mongolia",
            name: "Inner Mongolia"
        }, {
            code: "JS",
            value: "Jiangsu",
            name: "Jiangsu"
        }, {
            code: "JX",
            value: "Jiangxi",
            name: "Jiangxi"
        }, {
            code: "JL",
            value: "Jilin",
            name: "Jilin"
        }, {
            code: "LN",
            value: "Liaoning",
            name: "Liaoning"
        }, {
            code: "NX",
            value: "Ningxia",
            name: "Ningxia"
        }, {
            code: "QH",
            value: "Qinghai",
            name: "Qinghai"
        }, {
            code: "SN",
            value: "Shaanxi",
            name: "Shaanxi"
        }, {
            code: "SD",
            value: "Shandong",
            name: "Shandong"
        }, {
            code: "SH",
            value: "Shanghai",
            name: "Shanghai"
        }, {
            code: "SX",
            value: "Shanxi",
            name: "Shanxi"
        }, {
            code: "SC",
            value: "Sichuan",
            name: "Sichuan"
        }, {
            code: "TJ",
            value: "Tianjin",
            name: "Tianjin"
        }, {
            code: "XJ",
            value: "Xinjiang",
            name: "Xinjiang"
        }, {
            code: "YZ",
            value: "Xizang",
            name: "Xizang"
        }, {
            code: "YN",
            value: "Yunnan",
            name: "Yunnan"
        }, {
            code: "ZJ",
            value: "Zhejiang",
            name: "Zhejiang"
        }],
    CO:
        [{
            code: "DC",
            value: "Bogot\xe1, D.C.",
            name: "Bogot\xe1, D.C."
        }, {
            code: "AMA",
            value: "Amazonas",
            name: "Amazonas"
        }, {
            code: "ANT",
            value: "Antioquia",
            name: "Antioquia"
        }, {
            code: "ARA",
            value: "Arauca",
            name: "Arauca"
        }, {
            code: "ATL",
            value: "Atl\xe1ntico",
            name: "Atl\xe1ntico"
        }, {
            code: "BOL",
            value: "Bol\xedvar",
            name: "Bol\xedvar"
        }, {
            code: "BOY",
            value: "Boyac\xe1",
            name: "Boyac\xe1"
        }, {
            code: "CAL",
            value: "Caldas",
            name: "Caldas"
        }, {
            code: "CAQ",
            value: "Caquet\xe1",
            name: "Caquet\xe1"
        }, {
            code: "CAS",
            value: "Casanare",
            name: "Casanare"
        }, {
            code: "CAU",
            value: "Cauca",
            name: "Cauca"
        }, {
            code: "CES",
            value: "Cesar",
            name: "Cesar"
        }, {
            code: "CHO",
            value: "Choc\xf3",
            name: "Choc\xf3"
        }, {
            code: "COR",
            value: "C\xf3rdoba",
            name: "C\xf3rdoba"
        }, {
            code: "CUN",
            value: "Cundinamarca",
            name: "Cundinamarca"
        }, {
            code: "GUA",
            value: "Guain\xeda",
            name: "Guain\xeda"
        }, {
            code: "GUV",
            value: "Guaviare",
            name: "Guaviare"
        }, {
            code: "HUI",
            value: "Huila",
            name: "Huila"
        }, {
            code: "LAG",
            value: "La Guajira",
            name: "La Guajira"
        }, {
            code: "MAG",
            value: "Magdalena",
            name: "Magdalena"
        }, {
            code: "MET",
            value: "Meta",
            name: "Meta"
        }, {
            code: "NAR",
            value: "Nari\xf1o",
            name: "Nari\xf1o"
        }, {
            code: "NSA",
            value: "Norte de Santander",
            name: "Norte de Santander"
        }, {
            code: "PUT",
            value: "Putumayo",
            name: "Putumayo"
        }, {
            code: "QUI",
            value: "Quind\xedo",
            name: "Quind\xedo"
        }, {
            code: "RIS",
            value: "Risaralda",
            name: "Risaralda"
        }, {
            code: "SAP",
            value: "San Andr\xe9s, Providencia y Santa Catalina",
            name: "San Andr\xe9s, Providencia y Santa Catalina"
        }, {
            code: "SAN",
            value: "Santander",
            name: "Santander"
        }, {
            code: "SUC",
            value: "Sucre",
            name: "Sucre"
        }, {
            code: "TOL",
            value: "Tolima",
            name: "Tolima"
        }, {
            code: "VAC",
            value: "Valle del Cauca",
            name: "Valle del Cauca"
        }, {
            code: "VAU",
            value: "Vaup\xe9s",
            name: "Vaup\xe9s"
        }, {
            code: "VID",
            value: "Vichada",
            name: "Vichada"
        }],
    EG:
        [{
            code: "SU",
            value: "6th of October",
            name: "6th of October"
        }, {
            code: "SHR",
            value: "Al Sharqia",
            name: "Al Sharqia"
        }, {
            code: "ALX",
            value: "Alexandria",
            name: "Alexandria"
        }, {
            code: "ASN",
            value: "Aswan",
            name: "Aswan"
        }, {
            code: "AST",
            value: "Asyut",
            name: "Asyut"
        }, {
            code: "BH",
            value: "Beheira",
            name: "Beheira"
        }, {
            code: "BNS",
            value: "Beni Suef",
            name: "Beni Suef"
        }, {
            code: "C",
            value: "Cairo",
            name: "Cairo"
        }, {
            code: "DK",
            value: "Dakahlia",
            name: "Dakahlia"
        }, {
            code: "DT",
            value: "Damietta",
            name: "Damietta"
        }, {
            code: "FYM",
            value: "Faiyum",
            name: "Faiyum"
        }, {
            code: "GH",
            value: "Gharbia",
            name: "Gharbia"
        }, {
            code: "GZ",
            value: "Giza",
            name: "Giza"
        }, {
            code: "HU",
            value: "Helwan",
            name: "Helwan"
        }, {
            code: "IS",
            value: "Ismailia",
            name: "Ismailia"
        }, {
            code: "KFS",
            value: "Kafr el-Sheikh",
            name: "Kafr el-Sheikh"
        }, {
            code: "LX",
            value: "Luxor",
            name: "Luxor"
        }, {
            code: "MT",
            value: "Matrouh",
            name: "Matrouh"
        }, {
            code: "MN",
            value: "Minya",
            name: "Minya"
        }, {
            code: "MNF",
            value: "Monufia",
            name: "Monufia"
        }, {
            code: "WAD",
            value: "New Valley",
            name: "New Valley"
        }, {
            code: "SIN",
            value: "North Sinai",
            name: "North Sinai"
        }, {
            code: "PTS",
            value: "Port Said",
            name: "Port Said"
        }, {
            code: "KB",
            value: "Qalyubia",
            name: "Qalyubia"
        }, {
            code: "KN",
            value: "Qena",
            name: "Qena"
        }, {
            code: "BA",
            value: "Red Sea",
            name: "Red Sea"
        }, {
            code: "SHG",
            value: "Sohag",
            name: "Sohag"
        }, {
            code: "JS",
            value: "South Sinai",
            name: "South Sinai"
        }, {
            code: "SUZ",
            value: "Suez",
            name: "Suez"
        }],
    GT:
        [{
            code: "AVE",
            value: "Alta Verapaz",
            name: "Alta Verapaz"
        }, {
            code: "BVE",
            value: "Baja Verapaz",
            name: "Baja Verapaz"
        }, {
            code: "CMT",
            value: "Chimaltenango",
            name: "Chimaltenango"
        }, {
            code: "CQM",
            value: "Chiquimula",
            name: "Chiquimula"
        }, {
            code: "EPR",
            value: "El Progreso",
            name: "El Progreso"
        }, {
            code: "ESC",
            value: "Escuintla",
            name: "Escuintla"
        }, {
            code: "GUA",
            value: "Guatemala",
            name: "Guatemala"
        }, {
            code: "HUE",
            value: "Huehuetenango",
            name: "Huehuetenango"
        }, {
            code: "IZA",
            value: "Izabal",
            name: "Izabal"
        }, {
            code: "JAL",
            value: "Jalapa",
            name: "Jalapa"
        }, {
            code: "JUT",
            value: "Jutiapa",
            name: "Jutiapa"
        }, {
            code: "PET",
            value: "Pet\xe9n",
            name: "Pet\xe9n"
        }, {
            code: "QUE",
            value: "Quetzaltenango",
            name: "Quetzaltenango"
        }, {
            code: "QUI",
            value: "Quich\xe9",
            name: "Quich\xe9"
        }, {
            code: "RET",
            value: "Retalhuleu",
            name: "Retalhuleu"
        }, {
            code: "SAC",
            value: "Sacatep\xe9quez",
            name: "Sacatep\xe9quez"
        }, {
            code: "SMA",
            value: "San Marcos",
            name: "San Marcos"
        }, {
            code: "SRO",
            value: "Santa Rosa",
            name: "Santa Rosa"
        }, {
            code: "SOL",
            value: "Solol\xe1",
            name: "Solol\xe1"
        }, {
            code: "SUC",
            value: "Suchitep\xe9quez",
            name: "Suchitep\xe9quez"
        }, {
            code: "TOT",
            value: "Totonicap\xe1n",
            name: "Totonicap\xe1n"
        }, {
            code: "ZAC",
            value: "Zacapa",
            name: "Zacapa"
        }],
    HK:
        [{
            code: "HK",
            value: "Hong Kong Island",
            name: "Hong Kong Island"
        }, {
            code: "KL",
            value: "Kowloon",
            name: "Kowloon"
        }, {
            code: "NT",
            value: "New Territories",
            name: "New Territories"
        }],
    IN:
        [{
            code: "AN",
            value: "Andaman and Nicobar",
            name: "Andaman and Nicobar"
        }, {
            code: "AP",
            value: "Andhra Pradesh",
            name: "Andhra Pradesh"
        }, {
            code: "AR",
            value: "Arunachal Pradesh",
            name: "Arunachal Pradesh"
        }, {
            code: "AS",
            value: "Assam",
            name: "Assam"
        }, {
            code: "BR",
            value: "Bihar",
            name: "Bihar"
        }, {
            code: "CH",
            value: "Chandigarh",
            name: "Chandigarh"
        }, {
            code: "CG",
            value: "Chattisgarh",
            name: "Chattisgarh"
        }, {
            code: "DN",
            value: "Dadra and Nagar Haveli",
            name: "Dadra and Nagar Haveli"
        }, {
            code: "DD",
            value: "Daman and Diu",
            name: "Daman and Diu"
        }, {
            code: "DL",
            value: "Delhi",
            name: "Delhi"
        }, {
            code: "GA",
            value: "Goa",
            name: "Goa"
        }, {
            code: "GJ",
            value: "Gujarat",
            name: "Gujarat"
        }, {
            code: "HR",
            value: "Haryana",
            name: "Haryana"
        }, {
            code: "HP",
            value: "Himachal Pradesh",
            name: "Himachal Pradesh"
        }, {
            code: "JK",
            value: "Jammu and Kashmir",
            name: "Jammu and Kashmir"
        }, {
            code: "JH",
            value: "Jharkhand",
            name: "Jharkhand"
        }, {
            code: "KA",
            value: "Karnataka",
            name: "Karnataka"
        }, {
            code: "KL",
            value: "Kerala",
            name: "Kerala"
        }, {
            code: "LD",
            value: "Lakshadweep",
            name: "Lakshadweep"
        }, {
            code: "MP",
            value: "Madhya Pradesh",
            name: "Madhya Pradesh"
        }, {
            code: "MH",
            value: "Maharashtra",
            name: "Maharashtra"
        }, {
            code: "MN",
            value: "Manipur",
            name: "Manipur"
        }, {
            code: "ML",
            value: "Meghalaya",
            name: "Meghalaya"
        }, {
            code: "MZ",
            value: "Mizoram",
            name: "Mizoram"
        }, {
            code: "NL",
            value: "Nagaland",
            name: "Nagaland"
        }, {
            code: "OR",
            value: "Orissa",
            name: "Orissa"
        }, {
            code: "PY",
            value: "Puducherry",
            name: "Puducherry"
        }, {
            code: "PB",
            value: "Punjab",
            name: "Punjab"
        }, {
            code: "RJ",
            value: "Rajasthan",
            name: "Rajasthan"
        }, {
            code: "SK",
            value: "Sikkim",
            name: "Sikkim"
        }, {
            code: "TN",
            value: "Tamil Nadu",
            name: "Tamil Nadu"
        }, {
            code: "TS",
            value: "Telangana",
            name: "Telangana"
        }, {
            code: "TR",
            value: "Tripura",
            name: "Tripura"
        }, {
            code: "UP",
            value: "Uttar Pradesh",
            name: "Uttar Pradesh"
        }, {
            code: "UK",
            value: "Uttarakhand",
            name: "Uttarakhand"
        }, {
            code: "WB",
            value: "West Bengal",
            name: "West Bengal"
        }],
    ID:
        [{
            code: "AC",
            value: "Aceh",
            name: "Aceh"
        }, {
            code: "BA",
            value: "Bali",
            name: "Bali"
        }, {
            code: "BB",
            value: "Bangka Belitung",
            name: "Bangka Belitung"
        }, {
            code: "BT",
            value: "Banten",
            name: "Banten"
        }, {
            code: "BE",
            value: "Bengkulu",
            name: "Bengkulu"
        }, {
            code: "GO",
            value: "Gorontalo",
            name: "Gorontalo"
        }, {
            code: "JK",
            value: "Jakarta",
            name: "Jakarta"
        }, {
            code: "JA",
            value: "Jambi",
            name: "Jambi"
        }, {
            code: "JB",
            value: "Jawa Barat",
            name: "Jawa Barat"
        }, {
            code: "JT",
            value: "Jawa Tengah",
            name: "Jawa Tengah"
        }, {
            code: "JI",
            value: "Jawa Timur",
            name: "Jawa Timur"
        }, {
            code: "KB",
            value: "Kalimantan Barat",
            name: "Kalimantan Barat"
        }, {
            code: "KS",
            value: "Kalimantan Selatan",
            name: "Kalimantan Selatan"
        }, {
            code: "KT",
            value: "Kalimantan Tengah",
            name: "Kalimantan Tengah"
        }, {
            code: "KI",
            value: "Kalimantan Timur",
            name: "Kalimantan Timur"
        }, {
            code: "KU",
            value: "Kalimantan Utara",
            name: "Kalimantan Utara"
        }, {
            code: "KR",
            value: "Kepulauan Riau",
            name: "Kepulauan Riau"
        }, {
            code: "LA",
            value: "Lampung",
            name: "Lampung"
        }, {
            code: "MA",
            value: "Maluku",
            name: "Maluku"
        }, {
            code: "MU",
            value: "Maluku Utara",
            name: "Maluku Utara"
        }, {
            code: "NB",
            value: "Nusa Tenggara Barat",
            name: "Nusa Tenggara Barat"
        }, {
            code: "NT",
            value: "Nusa Tenggara Timur",
            name: "Nusa Tenggara Timur"
        }, {
            code: "PA",
            value: "Papua",
            name: "Papua"
        }, {
            code: "PB",
            value: "Papua Barat",
            name: "Papua Barat"
        }, {
            code: "RI",
            value: "Riau",
            name: "Riau"
        }, {
            code: "SR",
            value: "Sulawesi Barat",
            name: "Sulawesi Barat"
        }, {
            code: "SN",
            value: "Sulawesi Selatan",
            name: "Sulawesi Selatan"
        }, {
            code: "ST",
            value: "Sulawesi Tengah",
            name: "Sulawesi Tengah"
        }, {
            code: "SG",
            value: "Sulawesi Tenggara",
            name: "Sulawesi Tenggara"
        }, {
            code: "SA",
            value: "Sulawesi Utara",
            name: "Sulawesi Utara"
        }, {
            code: "SB",
            value: "Sumatra Barat",
            name: "Sumatra Barat"
        }, {
            code: "SS",
            value: "Sumatra Selatan",
            name: "Sumatra Selatan"
        }, {
            code: "SU",
            value: "Sumatra Utara",
            name: "Sumatra Utara"
        }, {
            code: "YO",
            value: "Yogyakarta",
            name: "Yogyakarta"
        }],
    IE:
        [{
            code: "CW",
            value: "Carlow",
            name: "Carlow"
        }, {
            code: "CN",
            value: "Cavan",
            name: "Cavan"
        }, {
            code: "CE",
            value: "Clare",
            name: "Clare"
        }, {
            code: "CO",
            value: "Cork",
            name: "Cork"
        }, {
            code: "DL",
            value: "Donegal",
            name: "Donegal"
        }, {
            code: "D",
            value: "Dublin",
            name: "Dublin"
        }, {
            code: "G",
            value: "Galway",
            name: "Galway"
        }, {
            code: "KY",
            value: "Kerry",
            name: "Kerry"
        }, {
            code: "KE",
            value: "Kildare",
            name: "Kildare"
        }, {
            code: "KK",
            value: "Kilkenny",
            name: "Kilkenny"
        }, {
            code: "LS",
            value: "Laois",
            name: "Laois"
        }, {
            code: "LM",
            value: "Leitrim",
            name: "Leitrim"
        }, {
            code: "LK",
            value: "Limerick",
            name: "Limerick"
        }, {
            code: "LD",
            value: "Longford",
            name: "Longford"
        }, {
            code: "LH",
            value: "Louth",
            name: "Louth"
        }, {
            code: "MO",
            value: "Mayo",
            name: "Mayo"
        }, {
            code: "MH",
            value: "Meath",
            name: "Meath"
        }, {
            code: "MN",
            value: "Monaghan",
            name: "Monaghan"
        }, {
            code: "OY",
            value: "Offaly",
            name: "Offaly"
        }, {
            code: "RN",
            value: "Roscommon",
            name: "Roscommon"
        }, {
            code: "SO",
            value: "Sligo",
            name: "Sligo"
        }, {
            code: "TA",
            value: "Tipperary",
            name: "Tipperary"
        }, {
            code: "WD",
            value: "Waterford",
            name: "Waterford"
        }, {
            code: "WH",
            value: "Westmeath",
            name: "Westmeath"
        }, {
            code: "WX",
            value: "Wexford",
            name: "Wexford"
        }, {
            code: "WW",
            value: "Wicklow",
            name: "Wicklow"
        }],
    IT:
        [{
            code: "AG",
            value: "Agrigento",
            name: "Agrigento"
        }, {
            code: "AL",
            value: "Alessandria",
            name: "Alessandria"
        }, {
            code: "AN",
            value: "Ancona",
            name: "Ancona"
        }, {
            code: "AO",
            value: "Aosta",
            name: "Aosta"
        }, {
            code: "AR",
            value: "Arezzo",
            name: "Arezzo"
        }, {
            code: "AP",
            value: "Ascoli Piceno",
            name: "Ascoli Piceno"
        }, {
            code: "AT",
            value: "Asti",
            name: "Asti"
        }, {
            code: "AV",
            value: "Avellino",
            name: "Avellino"
        }, {
            code: "BA",
            value: "Bari",
            name: "Bari"
        }, {
            code: "BT",
            value: "Barletta-Andria-Trani",
            name: "Barletta-Andria-Trani"
        }, {
            code: "BL",
            value: "Belluno",
            name: "Belluno"
        }, {
            code: "BN",
            value: "Benevento",
            name: "Benevento"
        }, {
            code: "BG",
            value: "Bergamo",
            name: "Bergamo"
        }, {
            code: "BI",
            value: "Biella",
            name: "Biella"
        }, {
            code: "BO",
            value: "Bologna",
            name: "Bologna"
        }, {
            code: "BZ",
            value: "Bolzano",
            name: "Bolzano"
        }, {
            code: "BS",
            value: "Brescia",
            name: "Brescia"
        }, {
            code: "BR",
            value: "Brindisi",
            name: "Brindisi"
        }, {
            code: "CA",
            value: "Cagliari",
            name: "Cagliari"
        }, {
            code: "CL",
            value: "Caltanissetta",
            name: "Caltanissetta"
        }, {
            code: "CB",
            value: "Campobasso",
            name: "Campobasso"
        }, {
            code: "CI",
            value: "Carbonia-Iglesias",
            name: "Carbonia-Iglesias"
        }, {
            code: "CE",
            value: "Caserta",
            name: "Caserta"
        }, {
            code: "CT",
            value: "Catania",
            name: "Catania"
        }, {
            code: "CZ",
            value: "Catanzaro",
            name: "Catanzaro"
        }, {
            code: "CH",
            value: "Chieti",
            name: "Chieti"
        }, {
            code: "CO",
            value: "Como",
            name: "Como"
        }, {
            code: "CS",
            value: "Cosenza",
            name: "Cosenza"
        }, {
            code: "CR",
            value: "Cremona",
            name: "Cremona"
        }, {
            code: "KR",
            value: "Crotone",
            name: "Crotone"
        }, {
            code: "CN",
            value: "Cuneo",
            name: "Cuneo"
        }, {
            code: "EN",
            value: "Enna",
            name: "Enna"
        }, {
            code: "FM",
            value: "Fermo",
            name: "Fermo"
        }, {
            code: "FE",
            value: "Ferrara",
            name: "Ferrara"
        }, {
            code: "FI",
            value: "Firenze",
            name: "Firenze"
        }, {
            code: "FG",
            value: "Foggia",
            name: "Foggia"
        }, {
            code: "FC",
            value: "Forl\xec-Cesena",
            name: "Forl\xec-Cesena"
        }, {
            code: "FR",
            value: "Frosinone",
            name: "Frosinone"
        }, {
            code: "GE",
            value: "Genova",
            name: "Genova"
        }, {
            code: "GO",
            value: "Gorizia",
            name: "Gorizia"
        }, {
            code: "GR",
            value: "Grosseto",
            name: "Grosseto"
        }, {
            code: "IM",
            value: "Imperia",
            name: "Imperia"
        }, {
            code: "IS",
            value: "Isernia",
            name: "Isernia"
        }, {
            code: "AQ",
            value: "L'Aquila",
            name: "L'Aquila"
        }, {
            code: "SP",
            value: "La Spezia",
            name: "La Spezia"
        }, {
            code: "LT",
            value: "Latina",
            name: "Latina"
        }, {
            code: "LE",
            value: "Lecce",
            name: "Lecce"
        }, {
            code: "LC",
            value: "Lecco",
            name: "Lecco"
        }, {
            code: "LI",
            value: "Livorno",
            name: "Livorno"
        }, {
            code: "LO",
            value: "Lodi",
            name: "Lodi"
        }, {
            code: "LU",
            value: "Lucca",
            name: "Lucca"
        }, {
            code: "MC",
            value: "Macerata",
            name: "Macerata"
        }, {
            code: "MN",
            value: "Mantova",
            name: "Mantova"
        }, {
            code: "MS",
            value: "Massa-Carrara",
            name: "Massa-Carrara"
        }, {
            code: "MT",
            value: "Matera",
            name: "Matera"
        }, {
            code: "VS",
            value: "Medio Campidano",
            name: "Medio Campidano"
        }, {
            code: "ME",
            value: "Messina",
            name: "Messina"
        }, {
            code: "MI",
            value: "Milano",
            name: "Milano"
        }, {
            code: "MO",
            value: "Modena",
            name: "Modena"
        }, {
            code: "MB",
            value: "Monza e Brianza",
            name: "Monza e Brianza"
        }, {
            code: "NA",
            value: "Napoli",
            name: "Napoli"
        }, {
            code: "NO",
            value: "Novara",
            name: "Novara"
        }, {
            code: "NU",
            value: "Nuoro",
            name: "Nuoro"
        }, {
            code: "OG",
            value: "Ogliastra",
            name: "Ogliastra"
        }, {
            code: "OT",
            value: "Olbia-Tempio",
            name: "Olbia-Tempio"
        }, {
            code: "OR",
            value: "Oristano",
            name: "Oristano"
        }, {
            code: "PD",
            value: "Padova",
            name: "Padova"
        }, {
            code: "PA",
            value: "Palermo",
            name: "Palermo"
        }, {
            code: "PR",
            value: "Parma",
            name: "Parma"
        }, {
            code: "PV",
            value: "Pavia",
            name: "Pavia"
        }, {
            code: "PG",
            value: "Perugia",
            name: "Perugia"
        }, {
            code: "PU",
            value: "Pesaro e Urbino",
            name: "Pesaro e Urbino"
        }, {
            code: "PE",
            value: "Pescara",
            name: "Pescara"
        }, {
            code: "PC",
            value: "Piacenza",
            name: "Piacenza"
        }, {
            code: "PI",
            value: "Pisa",
            name: "Pisa"
        }, {
            code: "PT",
            value: "Pistoia",
            name: "Pistoia"
        }, {
            code: "PN",
            value: "Pordenone",
            name: "Pordenone"
        }, {
            code: "PZ",
            value: "Potenza",
            name: "Potenza"
        }, {
            code: "PO",
            value: "Prato",
            name: "Prato"
        }, {
            code: "RG",
            value: "Ragusa",
            name: "Ragusa"
        }, {
            code: "RA",
            value: "Ravenna",
            name: "Ravenna"
        }, {
            code: "RC",
            value: "Reggio Calabria",
            name: "Reggio Calabria"
        }, {
            code: "RE",
            value: "Reggio Emilia",
            name: "Reggio Emilia"
        }, {
            code: "RI",
            value: "Rieti",
            name: "Rieti"
        }, {
            code: "RN",
            value: "Rimini",
            name: "Rimini"
        }, {
            code: "RM",
            value: "Roma",
            name: "Roma"
        }, {
            code: "RO",
            value: "Rovigo",
            name: "Rovigo"
        }, {
            code: "SA",
            value: "Salerno",
            name: "Salerno"
        }, {
            code: "SS",
            value: "Sassari",
            name: "Sassari"
        }, {
            code: "SV",
            value: "Savona",
            name: "Savona"
        }, {
            code: "SI",
            value: "Siena",
            name: "Siena"
        }, {
            code: "SR",
            value: "Siracusa",
            name: "Siracusa"
        }, {
            code: "SO",
            value: "Sondrio",
            name: "Sondrio"
        }, {
            code: "TA",
            value: "Taranto",
            name: "Taranto"
        }, {
            code: "TE",
            value: "Teramo",
            name: "Teramo"
        }, {
            code: "TR",
            value: "Terni",
            name: "Terni"
        }, {
            code: "TO",
            value: "Torino",
            name: "Torino"
        }, {
            code: "TP",
            value: "Trapani",
            name: "Trapani"
        }, {
            code: "TN",
            value: "Trento",
            name: "Trento"
        }, {
            code: "TV",
            value: "Treviso",
            name: "Treviso"
        }, {
            code: "TS",
            value: "Trieste",
            name: "Trieste"
        }, {
            code: "UD",
            value: "Udine",
            name: "Udine"
        }, {
            code: "VA",
            value: "Varese",
            name: "Varese"
        }, {
            code: "VE",
            value: "Venezia",
            name: "Venezia"
        }, {
            code: "VB",
            value: "Verbano-Cusio-Ossola",
            name: "Verbano-Cusio-Ossola"
        }, {
            code: "VC",
            value: "Vercelli",
            name: "Vercelli"
        }, {
            code: "VR",
            value: "Verona",
            name: "Verona"
        }, {
            code: "VV",
            value: "Vibo Valentia",
            name: "Vibo Valentia"
        }, {
            code: "VI",
            value: "Vicenza",
            name: "Vicenza"
        }, {
            code: "VT",
            value: "Viterbo",
            name: "Viterbo"
        }],
    JP:
        [{
            code: "JP-01",
            value: "Hokkaid\u014d",
            name: "Hokkaid\u014d"
        }, {
            code: "JP-02",
            value: "Aomori",
            name: "Aomori"
        }, {
            code: "JP-03",
            value: "Iwate",
            name: "Iwate"
        }, {
            code: "JP-04",
            value: "Miyagi",
            name: "Miyagi"
        }, {
            code: "JP-05",
            value: "Akita",
            name: "Akita"
        }, {
            code: "JP-06",
            value: "Yamagata",
            name: "Yamagata"
        }, {
            code: "JP-07",
            value: "Fukushima",
            name: "Fukushima"
        }, {
            code: "JP-08",
            value: "Ibaraki",
            name: "Ibaraki"
        }, {
            code: "JP-09",
            value: "Tochigi",
            name: "Tochigi"
        }, {
            code: "JP-10",
            value: "Gunma",
            name: "Gunma"
        }, {
            code: "JP-11",
            value: "Saitama",
            name: "Saitama"
        }, {
            code: "JP-12",
            value: "Chiba",
            name: "Chiba"
        }, {
            code: "JP-13",
            value: "T\u014dky\u014d",
            name: "T\u014dky\u014d"
        }, {
            code: "JP-14",
            value: "Kanagawa",
            name: "Kanagawa"
        }, {
            code: "JP-15",
            value: "Niigata",
            name: "Niigata"
        }, {
            code: "JP-16",
            value: "Toyama",
            name: "Toyama"
        }, {
            code: "JP-17",
            value: "Ishikawa",
            name: "Ishikawa"
        }, {
            code: "JP-18",
            value: "Fukui",
            name: "Fukui"
        }, {
            code: "JP-19",
            value: "Yamanashi",
            name: "Yamanashi"
        }, {
            code: "JP-20",
            value: "Nagano",
            name: "Nagano"
        }, {
            code: "JP-21",
            value: "Gifu",
            name: "Gifu"
        }, {
            code: "JP-22",
            value: "Shizuoka",
            name: "Shizuoka"
        }, {
            code: "JP-23",
            value: "Aichi",
            name: "Aichi"
        }, {
            code: "JP-24",
            value: "Mie",
            name: "Mie"
        }, {
            code: "JP-25",
            value: "Shiga",
            name: "Shiga"
        }, {
            code: "JP-26",
            value: "Ky\u014dto",
            name: "Ky\u014dto"
        }, {
            code: "JP-27",
            value: "\u014csaka",
            name: "\u014csaka"
        }, {
            code: "JP-28",
            value: "Hy\u014dgo",
            name: "Hy\u014dgo"
        }, {
            code: "JP-29",
            value: "Nara",
            name: "Nara"
        }, {
            code: "JP-30",
            value: "Wakayama",
            name: "Wakayama"
        }, {
            code: "JP-31",
            value: "Tottori",
            name: "Tottori"
        }, {
            code: "JP-32",
            value: "Shimane",
            name: "Shimane"
        }, {
            code: "JP-33",
            value: "Okayama",
            name: "Okayama"
        }, {
            code: "JP-34",
            value: "Hiroshima",
            name: "Hiroshima"
        }, {
            code: "JP-35",
            value: "Yamaguchi",
            name: "Yamaguchi"
        }, {
            code: "JP-36",
            value: "Tokushima",
            name: "Tokushima"
        }, {
            code: "JP-37",
            value: "Kagawa",
            name: "Kagawa"
        }, {
            code: "JP-38",
            value: "Ehime",
            name: "Ehime"
        }, {
            code: "JP-39",
            value: "K\u014dchi",
            name: "K\u014dchi"
        }, {
            code: "JP-40",
            value: "Fukuoka",
            name: "Fukuoka"
        }, {
            code: "JP-41",
            value: "Saga",
            name: "Saga"
        }, {
            code: "JP-42",
            value: "Nagasaki",
            name: "Nagasaki"
        }, {
            code: "JP-43",
            value: "Kumamoto",
            name: "Kumamoto"
        }, {
            code: "JP-44",
            value: "\u014cita",
            name: "\u014cita"
        }, {
            code: "JP-45",
            value: "Miyazaki",
            name: "Miyazaki"
        }, {
            code: "JP-46",
            value: "Kagoshima",
            name: "Kagoshima"
        }, {
            code: "JP-47",
            value: "Okinawa",
            name: "Okinawa"
        }],
    MY:
        [{
            code: "JHR",
            value: "Johor",
            name: "Johor"
        }, {
            code: "KDH",
            value: "Kedah",
            name: "Kedah"
        }, {
            code: "KTN",
            value: "Kelantan",
            name: "Kelantan"
        }, {
            code: "KUL",
            value: "Kuala Lumpur",
            name: "Kuala Lumpur"
        }, {
            code: "LBN",
            value: "Labuan",
            name: "Labuan"
        }, {
            code: "MLK",
            value: "Melaka",
            name: "Melaka"
        }, {
            code: "NSN",
            value: "Negeri Sembilan",
            name: "Negeri Sembilan"
        }, {
            code: "PHG",
            value: "Pahang",
            name: "Pahang"
        }, {
            code: "PRK",
            value: "Perak",
            name: "Perak"
        }, {
            code: "PLS",
            value: "Perlis",
            name: "Perlis"
        }, {
            code: "PNG",
            value: "Pulau Pinang",
            name: "Pulau Pinang"
        }, {
            code: "PJY",
            value: "Putrajaya",
            name: "Putrajaya"
        }, {
            code: "SBH",
            value: "Sabah",
            name: "Sabah"
        }, {
            code: "SWK",
            value: "Sarawak",
            name: "Sarawak"
        }, {
            code: "SGR",
            value: "Selangor",
            name: "Selangor"
        }, {
            code: "TRG",
            value: "Terengganu",
            name: "Terengganu"
        }],
    MX:
        [{
            code: "AGS",
            value: "Aguascalientes",
            name: "Aguascalientes"
        }, {
            code: "BC",
            value: "Baja California",
            name: "Baja California"
        }, {
            code: "BCS",
            value: "Baja California Sur",
            name: "Baja California Sur"
        }, {
            code: "CAMP",
            value: "Campeche",
            name: "Campeche"
        }, {
            code: "CHIS",
            value: "Chiapas",
            name: "Chiapas"
        }, {
            code: "CHIH",
            value: "Chihuahua",
            name: "Chihuahua"
        }, {
            code: "DF",
            value: "Ciudad de M\xe9xico",
            name: "Ciudad de M\xe9xico"
        }, {
            code: "COAH",
            value: "Coahuila",
            name: "Coahuila"
        }, {
            code: "COL",
            value: "Colima",
            name: "Colima"
        }, {
            code: "DGO",
            value: "Durango",
            name: "Durango"
        }, {
            code: "GTO",
            value: "Guanajuato",
            name: "Guanajuato"
        }, {
            code: "GRO",
            value: "Guerrero",
            name: "Guerrero"
        }, {
            code: "HGO",
            value: "Hidalgo",
            name: "Hidalgo"
        }, {
            code: "JAL",
            value: "Jalisco",
            name: "Jalisco"
        }, {
            code: "MEX",
            value: "M\xe9xico",
            name: "M\xe9xico"
        }, {
            code: "MICH",
            value: "Michoac\xe1n",
            name: "Michoac\xe1n"
        }, {
            code: "MOR",
            value: "Morelos",
            name: "Morelos"
        }, {
            code: "NAY",
            value: "Nayarit",
            name: "Nayarit"
        }, {
            code: "NL",
            value: "Nuevo Le\xf3n",
            name: "Nuevo Le\xf3n"
        }, {
            code: "OAX",
            value: "Oaxaca",
            name: "Oaxaca"
        }, {
            code: "PUE",
            value: "Puebla",
            name: "Puebla"
        }, {
            code: "QRO",
            value: "Quer\xe9taro",
            name: "Quer\xe9taro"
        }, {
            code: "Q ROO",
            value: "Quintana Roo",
            name: "Quintana Roo"
        }, {
            code: "SLP",
            value: "San Luis Potos\xed",
            name: "San Luis Potos\xed"
        }, {
            code: "SIN",
            value: "Sinaloa",
            name: "Sinaloa"
        }, {
            code: "SON",
            value: "Sonora",
            name: "Sonora"
        }, {
            code: "TAB",
            value: "Tabasco",
            name: "Tabasco"
        }, {
            code: "TAMPS",
            value: "Tamaulipas",
            name: "Tamaulipas"
        }, {
            code: "TLAX",
            value: "Tlaxcala",
            name: "Tlaxcala"
        }, {
            code: "VER",
            value: "Veracruz",
            name: "Veracruz"
        }, {
            code: "YUC",
            value: "Yucat\xe1n",
            name: "Yucat\xe1n"
        }, {
            code: "ZAC",
            value: "Zacatecas",
            name: "Zacatecas"
        }],
    NZ:
        [{
            code: "AUK",
            value: "Auckland",
            name: "Auckland"
        }, {
            code: "BOP",
            value: "Bay of Plenty",
            name: "Bay of Plenty"
        }, {
            code: "CAN",
            value: "Canterbury",
            name: "Canterbury"
        }, {
            code: "GIS",
            value: "Gisborne",
            name: "Gisborne"
        }, {
            code: "HKB",
            value: "Hawke's Bay",
            name: "Hawke's Bay"
        }, {
            code: "MWT",
            value: "Manawatu-Wanganui",
            name: "Manawatu-Wanganui"
        }, {
            code: "MBH",
            value: "Marlborough",
            name: "Marlborough"
        }, {
            code: "NSN",
            value: "Nelson",
            name: "Nelson"
        }, {
            code: "NTL",
            value: "Northland",
            name: "Northland"
        }, {
            code: "OTA",
            value: "Otago",
            name: "Otago"
        }, {
            code: "STL",
            value: "Southland",
            name: "Southland"
        }, {
            code: "TKI",
            value: "Taranaki",
            name: "Taranaki"
        }, {
            code: "TAS",
            value: "Tasman",
            name: "Tasman"
        }, {
            code: "WKO",
            value: "Waikato",
            name: "Waikato"
        }, {
            code: "WGN",
            value: "Wellington",
            name: "Wellington"
        }, {
            code: "WTC",
            value: "West Coast",
            name: "West Coast"
        }],
    NG:
        [{
            code: "AB",
            value: "Abia",
            name: "Abia"
        }, {
            code: "FC",
            value: "Abuja Federal Capital Territory",
            name: "Abuja Federal Capital Territory"
        }, {
            code: "AD",
            value: "Adamawa",
            name: "Adamawa"
        }, {
            code: "AK",
            value: "Akwa Ibom",
            name: "Akwa Ibom"
        }, {
            code: "AN",
            value: "Anambra",
            name: "Anambra"
        }, {
            code: "BA",
            value: "Bauchi",
            name: "Bauchi"
        }, {
            code: "BY",
            value: "Bayelsa",
            name: "Bayelsa"
        }, {
            code: "BE",
            value: "Benue",
            name: "Benue"
        }, {
            code: "BO",
            value: "Borno",
            name: "Borno"
        }, {
            code: "CR",
            value: "Cross River",
            name: "Cross River"
        }, {
            code: "DE",
            value: "Delta",
            name: "Delta"
        }, {
            code: "EB",
            value: "Ebonyi",
            name: "Ebonyi"
        }, {
            code: "ED",
            value: "Edo",
            name: "Edo"
        }, {
            code: "EK",
            value: "Ekiti",
            name: "Ekiti"
        }, {
            code: "EN",
            value: "Enugu",
            name: "Enugu"
        }, {
            code: "GO",
            value: "Gombe",
            name: "Gombe"
        }, {
            code: "IM",
            value: "Imo",
            name: "Imo"
        }, {
            code: "JI",
            value: "Jigawa",
            name: "Jigawa"
        }, {
            code: "KD",
            value: "Kaduna",
            name: "Kaduna"
        }, {
            code: "KN",
            value: "Kano",
            name: "Kano"
        }, {
            code: "KT",
            value: "Katsina",
            name: "Katsina"
        }, {
            code: "KE",
            value: "Kebbi",
            name: "Kebbi"
        }, {
            code: "KO",
            value: "Kogi",
            name: "Kogi"
        }, {
            code: "KW",
            value: "Kwara",
            name: "Kwara"
        }, {
            code: "LA",
            value: "Lagos",
            name: "Lagos"
        }, {
            code: "NA",
            value: "Nasarawa",
            name: "Nasarawa"
        }, {
            code: "NI",
            value: "Niger",
            name: "Niger"
        }, {
            code: "OG",
            value: "Ogun",
            name: "Ogun"
        }, {
            code: "ON",
            value: "Ondo",
            name: "Ondo"
        }, {
            code: "OS",
            value: "Osun",
            name: "Osun"
        }, {
            code: "OY",
            value: "Oyo",
            name: "Oyo"
        }, {
            code: "PL",
            value: "Plateau",
            name: "Plateau"
        }, {
            code: "RI",
            value: "Rivers",
            name: "Rivers"
        }, {
            code: "SO",
            value: "Sokoto",
            name: "Sokoto"
        }, {
            code: "TA",
            value: "Taraba",
            name: "Taraba"
        }, {
            code: "YO",
            value: "Yobe",
            name: "Yobe"
        }, {
            code: "ZA",
            value: "Zamfara",
            name: "Zamfara"
        }],
    PA:
        [{
            code: "PA-1",
            value: "Bocas del Toro",
            name: "Bocas del Toro"
        }, {
            code: "PA-4",
            value: "Chiriqu\xed",
            name: "Chiriqu\xed"
        }, {
            code: "PA-2",
            value: "Cocl\xe9",
            name: "Cocl\xe9"
        }, {
            code: "PA-3",
            value: "Col\xf3n",
            name: "Col\xf3n"
        }, {
            code: "PA-5",
            value: "Dari\xe9n",
            name: "Dari\xe9n"
        }, {
            code: "PA-EM",
            value: "Ember\xe1",
            name: "Ember\xe1"
        }, {
            code: "PA-6",
            value: "Herrera",
            name: "Herrera"
        }, {
            code: "PA-KY",
            value: "Kuna Yala",
            name: "Kuna Yala"
        }, {
            code: "PA-7",
            value: "Los Santos",
            name: "Los Santos"
        }, {
            code: "PA-NB",
            value: "Ng\xf6be-Bugl\xe9",
            name: "Ng\xf6be-Bugl\xe9"
        }, {
            code: "PA-8",
            value: "Panam\xe1",
            name: "Panam\xe1"
        }, {
            code: "PA-10",
            value: "Panam\xe1 Oeste",
            name: "Panam\xe1 Oeste"
        }, {
            code: "PA-9",
            value: "Veraguas",
            name: "Veraguas"
        }],
    PH:
        [{
            code: "PH-ABR",
            value: "Abra",
            name: "Abra"
        }, {
            code: "PH-AGN",
            value: "Agusan del Norte",
            name: "Agusan del Norte"
        }, {
            code: "PH-AGS",
            value: "Agusan del Sur",
            name: "Agusan del Sur"
        }, {
            code: "PH-AKL",
            value: "Aklan",
            name: "Aklan"
        }, {
            code: "PH-ALB",
            value: "Albay",
            name: "Albay"
        }, {
            code: "PH-ANT",
            value: "Antique",
            name: "Antique"
        }, {
            code: "PH-APA",
            value: "Apayao",
            name: "Apayao"
        }, {
            code: "PH-AUR",
            value: "Aurora",
            name: "Aurora"
        }, {
            code: "PH-BAS",
            value: "Basilan",
            name: "Basilan"
        }, {
            code: "PH-BAN",
            value: "Bataan",
            name: "Bataan"
        }, {
            code: "PH-BTN",
            value: "Batanes",
            name: "Batanes"
        }, {
            code: "PH-BTG",
            value: "Batangas",
            name: "Batangas"
        }, {
            code: "PH-BEN",
            value: "Benguet",
            name: "Benguet"
        }, {
            code: "PH-BIL",
            value: "Biliran",
            name: "Biliran"
        }, {
            code: "PH-BOH",
            value: "Bohol",
            name: "Bohol"
        }, {
            code: "PH-BUK",
            value: "Bukidnon",
            name: "Bukidnon"
        }, {
            code: "PH-BUL",
            value: "Bulacan",
            name: "Bulacan"
        }, {
            code: "PH-CAG",
            value: "Cagayan",
            name: "Cagayan"
        }, {
            code: "PH-CAN",
            value: "Camarines Norte",
            name: "Camarines Norte"
        }, {
            code: "PH-CAS",
            value: "Camarines Sur",
            name: "Camarines Sur"
        }, {
            code: "PH-CAM",
            value: "Camiguin",
            name: "Camiguin"
        }, {
            code: "PH-CAP",
            value: "Capiz",
            name: "Capiz"
        }, {
            code: "PH-CAT",
            value: "Catanduanes",
            name: "Catanduanes"
        }, {
            code: "PH-CAV",
            value: "Cavite",
            name: "Cavite"
        }, {
            code: "PH-CEB",
            value: "Cebu",
            name: "Cebu"
        }, {
            code: "PH-NCO",
            value: "Cotabato",
            name: "Cotabato"
        }, {
            code: "PH-DVO",
            value: "Davao Occidental",
            name: "Davao Occidental"
        }, {
            code: "PH-DAO",
            value: "Davao Oriental",
            name: "Davao Oriental"
        }, {
            code: "PH-COM",
            value: "Davao de Oro",
            name: "Davao de Oro"
        }, {
            code: "PH-DAV",
            value: "Davao del Norte",
            name: "Davao del Norte"
        }, {
            code: "PH-DAS",
            value: "Davao del Sur",
            name: "Davao del Sur"
        }, {
            code: "PH-DIN",
            value: "Dinagat Islands",
            name: "Dinagat Islands"
        }, {
            code: "PH-EAS",
            value: "Eastern Samar",
            name: "Eastern Samar"
        }, {
            code: "PH-GUI",
            value: "Guimaras",
            name: "Guimaras"
        }, {
            code: "PH-IFU",
            value: "Ifugao",
            name: "Ifugao"
        }, {
            code: "PH-ILN",
            value: "Ilocos Norte",
            name: "Ilocos Norte"
        }, {
            code: "PH-ILS",
            value: "Ilocos Sur",
            name: "Ilocos Sur"
        }, {
            code: "PH-ILI",
            value: "Iloilo",
            name: "Iloilo"
        }, {
            code: "PH-ISA",
            value: "Isabela",
            name: "Isabela"
        }, {
            code: "PH-KAL",
            value: "Kalinga",
            name: "Kalinga"
        }, {
            code: "PH-LUN",
            value: "La Union",
            name: "La Union"
        }, {
            code: "PH-LAG",
            value: "Laguna",
            name: "Laguna"
        }, {
            code: "PH-LAN",
            value: "Lanao del Norte",
            name: "Lanao del Norte"
        }, {
            code: "PH-LAS",
            value: "Lanao del Sur",
            name: "Lanao del Sur"
        }, {
            code: "PH-LEY",
            value: "Leyte",
            name: "Leyte"
        }, {
            code: "PH-MAG",
            value: "Maguindanao",
            name: "Maguindanao"
        }, {
            code: "PH-MAD",
            value: "Marinduque",
            name: "Marinduque"
        }, {
            code: "PH-MAS",
            value: "Masbate",
            name: "Masbate"
        }, {
            code: "PH-00",
            value: "Metro Manila",
            name: "Metro Manila"
        }, {
            code: "PH-MSC",
            value: "Misamis Occidental",
            name: "Misamis Occidental"
        }, {
            code: "PH-MSR",
            value: "Misamis Oriental",
            name: "Misamis Oriental"
        }, {
            code: "PH-MOU",
            value: "Mountain Province",
            name: "Mountain Province"
        }, {
            code: "PH-NEC",
            value: "Negros Occidental",
            name: "Negros Occidental"
        }, {
            code: "PH-NER",
            value: "Negros Oriental",
            name: "Negros Oriental"
        }, {
            code: "PH-NSA",
            value: "Northern Samar",
            name: "Northern Samar"
        }, {
            code: "PH-NUE",
            value: "Nueva Ecija",
            name: "Nueva Ecija"
        }, {
            code: "PH-NUV",
            value: "Nueva Vizcaya",
            name: "Nueva Vizcaya"
        }, {
            code: "PH-MDC",
            value: "Occidental Mindoro",
            name: "Occidental Mindoro"
        }, {
            code: "PH-MDR",
            value: "Oriental Mindoro",
            name: "Oriental Mindoro"
        }, {
            code: "PH-PLW",
            value: "Palawan",
            name: "Palawan"
        }, {
            code: "PH-PAM",
            value: "Pampanga",
            name: "Pampanga"
        }, {
            code: "PH-PAN",
            value: "Pangasinan",
            name: "Pangasinan"
        }, {
            code: "PH-QUE",
            value: "Quezon",
            name: "Quezon"
        }, {
            code: "PH-QUI",
            value: "Quirino",
            name: "Quirino"
        }, {
            code: "PH-RIZ",
            value: "Rizal",
            name: "Rizal"
        }, {
            code: "PH-ROM",
            value: "Romblon",
            name: "Romblon"
        }, {
            code: "PH-WSA",
            value: "Samar",
            name: "Samar"
        }, {
            code: "PH-SAR",
            value: "Sarangani",
            name: "Sarangani"
        }, {
            code: "PH-SIG",
            value: "Siquijor",
            name: "Siquijor"
        }, {
            code: "PH-SOR",
            value: "Sorsogon",
            name: "Sorsogon"
        }, {
            code: "PH-SCO",
            value: "South Cotabato",
            name: "South Cotabato"
        }, {
            code: "PH-SLE",
            value: "Southern Leyte",
            name: "Southern Leyte"
        }, {
            code: "PH-SUK",
            value: "Sultan Kudarat",
            name: "Sultan Kudarat"
        }, {
            code: "PH-SLU",
            value: "Sulu",
            name: "Sulu"
        }, {
            code: "PH-SUN",
            value: "Surigao del Norte",
            name: "Surigao del Norte"
        }, {
            code: "PH-SUR",
            value: "Surigao del Sur",
            name: "Surigao del Sur"
        }, {
            code: "PH-TAR",
            value: "Tarlac",
            name: "Tarlac"
        }, {
            code: "PH-TAW",
            value: "Tawi-Tawi",
            name: "Tawi-Tawi"
        }, {
            code: "PH-ZMB",
            value: "Zambales",
            name: "Zambales"
        }, {
            code: "PH-ZSI",
            value: "Zamboanga Sibugay",
            name: "Zamboanga Sibugay"
        }, {
            code: "PH-ZAN",
            value: "Zamboanga del Norte",
            name: "Zamboanga del Norte"
        }, {
            code: "PH-ZAS",
            value: "Zamboanga del Sur",
            name: "Zamboanga del Sur"
        }],
    PT:
        [{
            code: "PT-20",
            value: "A\xe7ores",
            name: "A\xe7ores"
        }, {
            code: "PT-01",
            value: "Aveiro",
            name: "Aveiro"
        }, {
            code: "PT-02",
            value: "Beja",
            name: "Beja"
        }, {
            code: "PT-03",
            value: "Braga",
            name: "Braga"
        }, {
            code: "PT-04",
            value: "Bragan\xe7a",
            name: "Bragan\xe7a"
        }, {
            code: "PT-05",
            value: "Castelo Branco",
            name: "Castelo Branco"
        }, {
            code: "PT-06",
            value: "Coimbra",
            name: "Coimbra"
        }, {
            code: "PT-07",
            value: "\xc9vora",
            name: "\xc9vora"
        }, {
            code: "PT-08",
            value: "Faro",
            name: "Faro"
        }, {
            code: "PT-09",
            value: "Guarda",
            name: "Guarda"
        }, {
            code: "PT-10",
            value: "Leiria",
            name: "Leiria"
        }, {
            code: "PT-11",
            value: "Lisboa",
            name: "Lisboa"
        }, {
            code: "PT-30",
            value: "Madeira",
            name: "Madeira"
        }, {
            code: "PT-12",
            value: "Portalegre",
            name: "Portalegre"
        }, {
            code: "PT-13",
            value: "Porto",
            name: "Porto"
        }, {
            code: "PT-14",
            value: "Santar\xe9m",
            name: "Santar\xe9m"
        }, {
            code: "PT-15",
            value: "Set\xfabal",
            name: "Set\xfabal"
        }, {
            code: "PT-16",
            value: "Viana do Castelo",
            name: "Viana do Castelo"
        }, {
            code: "PT-17",
            value: "Vila Real",
            name: "Vila Real"
        }, {
            code: "PT-18",
            value: "Viseu",
            name: "Viseu"
        }],
    RO:
        [{
            code: "AB",
            value: "Alba",
            name: "Alba"
        }, {
            code: "AR",
            value: "Arad",
            name: "Arad"
        }, {
            code: "AG",
            value: "Arge\u0219",
            name: "Arge\u0219"
        }, {
            code: "BC",
            value: "Bac\u0103u",
            name: "Bac\u0103u"
        }, {
            code: "BH",
            value: "Bihor",
            name: "Bihor"
        }, {
            code: "BN",
            value: "Bistri\u021ba-N\u0103s\u0103ud",
            name: "Bistri\u021ba-N\u0103s\u0103ud"
        }, {
            code: "BT",
            value: "Boto\u0219ani",
            name: "Boto\u0219ani"
        }, {
            code: "BR",
            value: "Br\u0103ila",
            name: "Br\u0103ila"
        }, {
            code: "BV",
            value: "Bra\u0219ov",
            name: "Bra\u0219ov"
        }, {
            code: "B",
            value: "Bucure\u0219ti",
            name: "Bucure\u0219ti"
        }, {
            code: "BZ",
            value: "Buz\u0103u",
            name: "Buz\u0103u"
        }, {
            code: "CS",
            value: "Cara\u0219-Severin",
            name: "Cara\u0219-Severin"
        }, {
            code: "CJ",
            value: "Cluj",
            name: "Cluj"
        }, {
            code: "CT",
            value: "Constan\u021ba",
            name: "Constan\u021ba"
        }, {
            code: "CV",
            value: "Covasna",
            name: "Covasna"
        }, {
            code: "CL",
            value: "C\u0103l\u0103ra\u0219i",
            name: "C\u0103l\u0103ra\u0219i"
        }, {
            code: "DJ",
            value: "Dolj",
            name: "Dolj"
        }, {
            code: "DB",
            value: "D\xe2mbovi\u021ba",
            name: "D\xe2mbovi\u021ba"
        }, {
            code: "GL",
            value: "Gala\u021bi",
            name: "Gala\u021bi"
        }, {
            code: "GR",
            value: "Giurgiu",
            name: "Giurgiu"
        }, {
            code: "GJ",
            value: "Gorj",
            name: "Gorj"
        }, {
            code: "HR",
            value: "Harghita",
            name: "Harghita"
        }, {
            code: "HD",
            value: "Hunedoara",
            name: "Hunedoara"
        }, {
            code: "IL",
            value: "Ialomi\u021ba",
            name: "Ialomi\u021ba"
        }, {
            code: "IS",
            value: "Ia\u0219i",
            name: "Ia\u0219i"
        }, {
            code: "IF",
            value: "Ilfov",
            name: "Ilfov"
        }, {
            code: "MM",
            value: "Maramure\u0219",
            name: "Maramure\u0219"
        }, {
            code: "MH",
            value: "Mehedin\u021bi",
            name: "Mehedin\u021bi"
        }, {
            code: "MS",
            value: "Mure\u0219",
            name: "Mure\u0219"
        }, {
            code: "NT",
            value: "Neam\u021b",
            name: "Neam\u021b"
        }, {
            code: "OT",
            value: "Olt",
            name: "Olt"
        }, {
            code: "PH",
            value: "Prahova",
            name: "Prahova"
        }, {
            code: "SJ",
            value: "S\u0103laj",
            name: "S\u0103laj"
        }, {
            code: "SM",
            value: "Satu Mare",
            name: "Satu Mare"
        }, {
            code: "SB",
            value: "Sibiu",
            name: "Sibiu"
        }, {
            code: "SV",
            value: "Suceava",
            name: "Suceava"
        }, {
            code: "TR",
            value: "Teleorman",
            name: "Teleorman"
        }, {
            code: "TM",
            value: "Timi\u0219",
            name: "Timi\u0219"
        }, {
            code: "TL",
            value: "Tulcea",
            name: "Tulcea"
        }, {
            code: "VL",
            value: "V\xe2lcea",
            name: "V\xe2lcea"
        }, {
            code: "VS",
            value: "Vaslui",
            name: "Vaslui"
        }, {
            code: "VN",
            value: "Vrancea",
            name: "Vrancea"
        }],
    RU:
        [{
            code: "ALT",
            value: "Altai Krai",
            name: "Altai Krai"
        }, {
            code: "AL",
            value: "Altai Republic",
            name: "Altai Republic"
        }, {
            code: "AMU",
            value: "Amur Oblast",
            name: "Amur Oblast"
        }, {
            code: "ARK",
            value: "Arkhangelsk Oblast",
            name: "Arkhangelsk Oblast"
        }, {
            code: "AST",
            value: "Astrakhan Oblast",
            name: "Astrakhan Oblast"
        }, {
            code: "BEL",
            value: "Belgorod Oblast",
            name: "Belgorod Oblast"
        }, {
            code: "BRY",
            value: "Bryansk Oblast",
            name: "Bryansk Oblast"
        }, {
            code: "CE",
            value: "Chechen Republic",
            name: "Chechen Republic"
        }, {
            code: "CHE",
            value: "Chelyabinsk Oblast",
            name: "Chelyabinsk Oblast"
        }, {
            code: "CHU",
            value: "Chukotka Autonomous Okrug",
            name: "Chukotka Autonomous Okrug"
        }, {
            code: "CU",
            value: "Chuvash Republic",
            name: "Chuvash Republic"
        }, {
            code: "IRK",
            value: "Irkutsk Oblast",
            name: "Irkutsk Oblast"
        }, {
            code: "IVA",
            value: "Ivanovo Oblast",
            name: "Ivanovo Oblast"
        }, {
            code: "YEV",
            value: "Jewish Autonomous Oblast",
            name: "Jewish Autonomous Oblast"
        }, {
            code: "KB",
            value: "Kabardino-Balkarian Republic",
            name: "Kabardino-Balkarian Republic"
        }, {
            code: "KGD",
            value: "Kaliningrad Oblast",
            name: "Kaliningrad Oblast"
        }, {
            code: "KLU",
            value: "Kaluga Oblast",
            name: "Kaluga Oblast"
        }, {
            code: "KAM",
            value: "Kamchatka Krai",
            name: "Kamchatka Krai"
        }, {
            code: "KC",
            value: "Karachay\u2013Cherkess Republic",
            name: "Karachay\u2013Cherkess Republic"
        }, {
            code: "KEM",
            value: "Kemerovo Oblast",
            name: "Kemerovo Oblast"
        }, {
            code: "KHA",
            value: "Khabarovsk Krai",
            name: "Khabarovsk Krai"
        }, {
            code: "KHM",
            value: "Khanty-Mansi Autonomous Okrug",
            name: "Khanty-Mansi Autonomous Okrug"
        }, {
            code: "KIR",
            value: "Kirov Oblast",
            name: "Kirov Oblast"
        }, {
            code: "KO",
            value: "Komi Republic",
            name: "Komi Republic"
        }, {
            code: "KOS",
            value: "Kostroma Oblast",
            name: "Kostroma Oblast"
        }, {
            code: "KDA",
            value: "Krasnodar Krai",
            name: "Krasnodar Krai"
        }, {
            code: "KYA",
            value: "Krasnoyarsk Krai",
            name: "Krasnoyarsk Krai"
        }, {
            code: "KGN",
            value: "Kurgan Oblast",
            name: "Kurgan Oblast"
        }, {
            code: "KRS",
            value: "Kursk Oblast",
            name: "Kursk Oblast"
        }, {
            code: "LEN",
            value: "Leningrad Oblast",
            name: "Leningrad Oblast"
        }, {
            code: "LIP",
            value: "Lipetsk Oblast",
            name: "Lipetsk Oblast"
        }, {
            code: "MAG",
            value: "Magadan Oblast",
            name: "Magadan Oblast"
        }, {
            code: "ME",
            value: "Mari El Republic",
            name: "Mari El Republic"
        }, {
            code: "MOW",
            value: "Moscow",
            name: "Moscow"
        }, {
            code: "MOS",
            value: "Moscow Oblast",
            name: "Moscow Oblast"
        }, {
            code: "MUR",
            value: "Murmansk Oblast",
            name: "Murmansk Oblast"
        }, {
            code: "NIZ",
            value: "Nizhny Novgorod Oblast",
            name: "Nizhny Novgorod Oblast"
        }, {
            code: "NGR",
            value: "Novgorod Oblast",
            name: "Novgorod Oblast"
        }, {
            code: "NVS",
            value: "Novosibirsk Oblast",
            name: "Novosibirsk Oblast"
        }, {
            code: "OMS",
            value: "Omsk Oblast",
            name: "Omsk Oblast"
        }, {
            code: "ORE",
            value: "Orenburg Oblast",
            name: "Orenburg Oblast"
        }, {
            code: "ORL",
            value: "Oryol Oblast",
            name: "Oryol Oblast"
        }, {
            code: "PNZ",
            value: "Penza Oblast",
            name: "Penza Oblast"
        }, {
            code: "PER",
            value: "Perm Krai",
            name: "Perm Krai"
        }, {
            code: "PRI",
            value: "Primorsky Krai",
            name: "Primorsky Krai"
        }, {
            code: "PSK",
            value: "Pskov Oblast",
            name: "Pskov Oblast"
        }, {
            code: "AD",
            value: "Republic of Adygeya",
            name: "Republic of Adygeya"
        }, {
            code: "BA",
            value: "Republic of Bashkortostan",
            name: "Republic of Bashkortostan"
        }, {
            code: "BU",
            value: "Republic of Buryatia",
            name: "Republic of Buryatia"
        }, {
            code: "DA",
            value: "Republic of Dagestan",
            name: "Republic of Dagestan"
        }, {
            code: "IN",
            value: "Republic of Ingushetia",
            name: "Republic of Ingushetia"
        }, {
            code: "KL",
            value: "Republic of Kalmykia",
            name: "Republic of Kalmykia"
        }, {
            code: "KR",
            value: "Republic of Karelia",
            name: "Republic of Karelia"
        }, {
            code: "KK",
            value: "Republic of Khakassia",
            name: "Republic of Khakassia"
        }, {
            code: "MO",
            value: "Republic of Mordovia",
            name: "Republic of Mordovia"
        }, {
            code: "SE",
            value: "Republic of North Ossetia\u2013Alania",
            name: "Republic of North Ossetia\u2013Alania"
        }, {
            code: "TA",
            value: "Republic of Tatarstan",
            name: "Republic of Tatarstan"
        }, {
            code: "ROS",
            value: "Rostov Oblast",
            name: "Rostov Oblast"
        }, {
            code: "RYA",
            value: "Ryazan Oblast",
            name: "Ryazan Oblast"
        }, {
            code: "SPE",
            value: "Saint Petersburg",
            name: "Saint Petersburg"
        }, {
            code: "SA",
            value: "Sakha Republic (Yakutia)",
            name: "Sakha Republic (Yakutia)"
        }, {
            code: "SAK",
            value: "Sakhalin Oblast",
            name: "Sakhalin Oblast"
        }, {
            code: "SAM",
            value: "Samara Oblast",
            name: "Samara Oblast"
        }, {
            code: "SAR",
            value: "Saratov Oblast",
            name: "Saratov Oblast"
        }, {
            code: "SMO",
            value: "Smolensk Oblast",
            name: "Smolensk Oblast"
        }, {
            code: "STA",
            value: "Stavropol Krai",
            name: "Stavropol Krai"
        }, {
            code: "SVE",
            value: "Sverdlovsk Oblast",
            name: "Sverdlovsk Oblast"
        }, {
            code: "TAM",
            value: "Tambov Oblast",
            name: "Tambov Oblast"
        }, {
            code: "TOM",
            value: "Tomsk Oblast",
            name: "Tomsk Oblast"
        }, {
            code: "TUL",
            value: "Tula Oblast",
            name: "Tula Oblast"
        }, {
            code: "TVE",
            value: "Tver Oblast",
            name: "Tver Oblast"
        }, {
            code: "TYU",
            value: "Tyumen Oblast",
            name: "Tyumen Oblast"
        }, {
            code: "TY",
            value: "Tyva Republic",
            name: "Tyva Republic"
        }, {
            code: "UD",
            value: "Udmurtia",
            name: "Udmurtia"
        }, {
            code: "ULY",
            value: "Ulyanovsk Oblast",
            name: "Ulyanovsk Oblast"
        }, {
            code: "VLA",
            value: "Vladimir Oblast",
            name: "Vladimir Oblast"
        }, {
            code: "VGG",
            value: "Volgograd Oblast",
            name: "Volgograd Oblast"
        }, {
            code: "VLG",
            value: "Vologda Oblast",
            name: "Vologda Oblast"
        }, {
            code: "VOR",
            value: "Voronezh Oblast",
            name: "Voronezh Oblast"
        }, {
            code: "YAN",
            value: "Yamalo-Nenets Autonomous Okrug",
            name: "Yamalo-Nenets Autonomous Okrug"
        }, {
            code: "YAR",
            value: "Yaroslavl Oblast",
            name: "Yaroslavl Oblast"
        }, {
            code: "ZAB",
            value: "Zabaykalsky Krai",
            name: "Zabaykalsky Krai"
        }],
    ZA:
        [{
            code: "EC",
            value: "Eastern Cape",
            name: "Eastern Cape"
        }, {
            code: "FS",
            value: "Free State",
            name: "Free State"
        }, {
            code: "GT",
            value: "Gauteng",
            name: "Gauteng"
        }, {
            code: "NL",
            value: "KwaZulu-Natal",
            name: "KwaZulu-Natal"
        }, {
            code: "LP",
            value: "Limpopo",
            name: "Limpopo"
        }, {
            code: "MP",
            value: "Mpumalanga",
            name: "Mpumalanga"
        }, {
            code: "NW",
            value: "North West",
            name: "North West"
        }, {
            code: "NC",
            value: "Northern Cape",
            name: "Northern Cape"
        }, {
            code: "WC",
            value: "Western Cape",
            name: "Western Cape"
        }],
    KR:
        [{
            code: "KR-26",
            value: "Busan",
            name: "Busan"
        }, {
            code: "KR-43",
            value: "Chungbuk",
            name: "Chungbuk"
        }, {
            code: "KR-44",
            value: "Chungnam",
            name: "Chungnam"
        }, {
            code: "KR-27",
            value: "Daegu",
            name: "Daegu"
        }, {
            code: "KR-30",
            value: "Daejeon",
            name: "Daejeon"
        }, {
            code: "KR-42",
            value: "Gangwon",
            name: "Gangwon"
        }, {
            code: "KR-29",
            value: "Gwangju",
            name: "Gwangju"
        }, {
            code: "KR-47",
            value: "Gyeongbuk",
            name: "Gyeongbuk"
        }, {
            code: "KR-41",
            value: "Gyeonggi",
            name: "Gyeonggi"
        }, {
            code: "KR-48",
            value: "Gyeongnam",
            name: "Gyeongnam"
        }, {
            code: "KR-28",
            value: "Incheon",
            name: "Incheon"
        }, {
            code: "KR-49",
            value: "Jeju",
            name: "Jeju"
        }, {
            code: "KR-45",
            value: "Jeonbuk",
            name: "Jeonbuk"
        }, {
            code: "KR-46",
            value: "Jeonnam",
            name: "Jeonnam"
        }, {
            code: "KR-50",
            value: "Sejong",
            name: "Sejong"
        }, {
            code: "KR-11",
            value: "Seoul",
            name: "Seoul"
        }, {
            code: "KR-31",
            value: "Ulsan",
            name: "Ulsan"
        }],
    ES:
        [{
            code: "C",
            value: "A Coru\xf1a",
            name: "A Coru\xf1a"
        }, {
            code: "VI",
            value: "\xc1lava",
            name: "\xc1lava"
        }, {
            code: "AB",
            value: "Albacete",
            name: "Albacete"
        }, {
            code: "A",
            value: "Alicante",
            name: "Alicante"
        }, {
            code: "AL",
            value: "Almer\xeda",
            name: "Almer\xeda"
        }, {
            code: "O",
            value: "Asturias",
            name: "Asturias"
        }, {
            code: "AV",
            value: "\xc1vila",
            name: "\xc1vila"
        }, {
            code: "BA",
            value: "Badajoz",
            name: "Badajoz"
        }, {
            code: "PM",
            value: "Balears",
            name: "Balears"
        }, {
            code: "B",
            value: "Barcelona",
            name: "Barcelona"
        }, {
            code: "BU",
            value: "Burgos",
            name: "Burgos"
        }, {
            code: "CC",
            value: "C\xe1ceres",
            name: "C\xe1ceres"
        }, {
            code: "CA",
            value: "C\xe1diz",
            name: "C\xe1diz"
        }, {
            code: "S",
            value: "Cantabria",
            name: "Cantabria"
        }, {
            code: "CS",
            value: "Castell\xf3n",
            name: "Castell\xf3n"
        }, {
            code: "CE",
            value: "Ceuta",
            name: "Ceuta"
        }, {
            code: "CR",
            value: "Ciudad Real",
            name: "Ciudad Real"
        }, {
            code: "CO",
            value: "C\xf3rdoba",
            name: "C\xf3rdoba"
        }, {
            code: "CU",
            value: "Cuenca",
            name: "Cuenca"
        }, {
            code: "GI",
            value: "Girona",
            name: "Girona"
        }, {
            code: "GR",
            value: "Granada",
            name: "Granada"
        }, {
            code: "GU",
            value: "Guadalajara",
            name: "Guadalajara"
        }, {
            code: "SS",
            value: "Guip\xfazcoa",
            name: "Guip\xfazcoa"
        }, {
            code: "H",
            value: "Huelva",
            name: "Huelva"
        }, {
            code: "HU",
            value: "Huesca",
            name: "Huesca"
        }, {
            code: "J",
            value: "Ja\xe9n",
            name: "Ja\xe9n"
        }, {
            code: "LO",
            value: "La Rioja",
            name: "La Rioja"
        }, {
            code: "GC",
            value: "Las Palmas",
            name: "Las Palmas"
        }, {
            code: "LE",
            value: "Le\xf3n",
            name: "Le\xf3n"
        }, {
            code: "L",
            value: "Lleida",
            name: "Lleida"
        }, {
            code: "LU",
            value: "Lugo",
            name: "Lugo"
        }, {
            code: "M",
            value: "Madrid",
            name: "Madrid"
        }, {
            code: "MA",
            value: "M\xe1laga",
            name: "M\xe1laga"
        }, {
            code: "ML",
            value: "Melilla",
            name: "Melilla"
        }, {
            code: "MU",
            value: "Murcia",
            name: "Murcia"
        }, {
            code: "NA",
            value: "Navarra",
            name: "Navarra"
        }, {
            code: "OR",
            value: "Ourense",
            name: "Ourense"
        }, {
            code: "P",
            value: "Palencia",
            name: "Palencia"
        }, {
            code: "PO",
            value: "Pontevedra",
            name: "Pontevedra"
        }, {
            code: "SA",
            value: "Salamanca",
            name: "Salamanca"
        }, {
            code: "TF",
            value: "Santa Cruz de Tenerife",
            name: "Santa Cruz de Tenerife"
        }, {
            code: "SG",
            value: "Segovia",
            name: "Segovia"
        }, {
            code: "SE",
            value: "Sevilla",
            name: "Sevilla"
        }, {
            code: "SO",
            value: "Soria",
            name: "Soria"
        }, {
            code: "T",
            value: "Tarragona",
            name: "Tarragona"
        }, {
            code: "TE",
            value: "Teruel",
            name: "Teruel"
        }, {
            code: "TO",
            value: "Toledo",
            name: "Toledo"
        }, {
            code: "V",
            value: "Valencia",
            name: "Valencia"
        }, {
            code: "VA",
            value: "Valladolid",
            name: "Valladolid"
        }, {
            code: "BI",
            value: "Vizcaya",
            name: "Vizcaya"
        }, {
            code: "ZA",
            value: "Zamora",
            name: "Zamora"
        }, {
            code: "Z",
            value: "Zaragoza",
            name: "Zaragoza"
        }],
    TH:
        [{
            code: "TH-37",
            value: "Amnat Charoen",
            name: "Amnat Charoen"
        }, {
            code: "TH-15",
            value: "Ang Thong",
            name: "Ang Thong"
        }, {
            code: "TH-10",
            value: "Bangkok",
            name: "Bangkok"
        }, {
            code: "TH-38",
            value: "Bueng Kan",
            name: "Bueng Kan"
        }, {
            code: "TH-31",
            value: "Buriram",
            name: "Buriram"
        }, {
            code: "TH-24",
            value: "Chachoengsao",
            name: "Chachoengsao"
        }, {
            code: "TH-18",
            value: "Chai Nat",
            name: "Chai Nat"
        }, {
            code: "TH-36",
            value: "Chaiyaphum",
            name: "Chaiyaphum"
        }, {
            code: "TH-22",
            value: "Chanthaburi",
            name: "Chanthaburi"
        }, {
            code: "TH-50",
            value: "Chiang Mai",
            name: "Chiang Mai"
        }, {
            code: "TH-57",
            value: "Chiang Rai",
            name: "Chiang Rai"
        }, {
            code: "TH-20",
            value: "Chon Buri",
            name: "Chon Buri"
        }, {
            code: "TH-86",
            value: "Chumphon",
            name: "Chumphon"
        }, {
            code: "TH-46",
            value: "Kalasin",
            name: "Kalasin"
        }, {
            code: "TH-62",
            value: "Kamphaeng Phet",
            name: "Kamphaeng Phet"
        }, {
            code: "TH-71",
            value: "Kanchanaburi",
            name: "Kanchanaburi"
        }, {
            code: "TH-40",
            value: "Khon Kaen",
            name: "Khon Kaen"
        }, {
            code: "TH-81",
            value: "Krabi",
            name: "Krabi"
        }, {
            code: "TH-52",
            value: "Lampang",
            name: "Lampang"
        }, {
            code: "TH-51",
            value: "Lamphun",
            name: "Lamphun"
        }, {
            code: "TH-42",
            value: "Loei",
            name: "Loei"
        }, {
            code: "TH-16",
            value: "Lopburi",
            name: "Lopburi"
        }, {
            code: "TH-58",
            value: "Mae Hong Son",
            name: "Mae Hong Son"
        }, {
            code: "TH-44",
            value: "Maha Sarakham",
            name: "Maha Sarakham"
        }, {
            code: "TH-49",
            value: "Mukdahan",
            name: "Mukdahan"
        }, {
            code: "TH-26",
            value: "Nakhon Nayok",
            name: "Nakhon Nayok"
        }, {
            code: "TH-73",
            value: "Nakhon Pathom",
            name: "Nakhon Pathom"
        }, {
            code: "TH-48",
            value: "Nakhon Phanom",
            name: "Nakhon Phanom"
        }, {
            code: "TH-30",
            value: "Nakhon Ratchasima",
            name: "Nakhon Ratchasima"
        }, {
            code: "TH-60",
            value: "Nakhon Sawan",
            name: "Nakhon Sawan"
        }, {
            code: "TH-80",
            value: "Nakhon Si Thammarat",
            name: "Nakhon Si Thammarat"
        }, {
            code: "TH-55",
            value: "Nan",
            name: "Nan"
        }, {
            code: "TH-96",
            value: "Narathiwat",
            name: "Narathiwat"
        }, {
            code: "TH-39",
            value: "Nong Bua Lam Phu",
            name: "Nong Bua Lam Phu"
        }, {
            code: "TH-43",
            value: "Nong Khai",
            name: "Nong Khai"
        }, {
            code: "TH-12",
            value: "Nonthaburi",
            name: "Nonthaburi"
        }, {
            code: "TH-13",
            value: "Pathum Thani",
            name: "Pathum Thani"
        }, {
            code: "TH-94",
            value: "Pattani",
            name: "Pattani"
        }, {
            code: "TH-S",
            value: "Pattaya",
            name: "Pattaya"
        }, {
            code: "TH-82",
            value: "Phangnga",
            name: "Phangnga"
        }, {
            code: "TH-93",
            value: "Phatthalung",
            name: "Phatthalung"
        }, {
            code: "TH-56",
            value: "Phayao",
            name: "Phayao"
        }, {
            code: "TH-67",
            value: "Phetchabun",
            name: "Phetchabun"
        }, {
            code: "TH-76",
            value: "Phetchaburi",
            name: "Phetchaburi"
        }, {
            code: "TH-66",
            value: "Phichit",
            name: "Phichit"
        }, {
            code: "TH-65",
            value: "Phitsanulok",
            name: "Phitsanulok"
        }, {
            code: "TH-14",
            value: "Phra Nakhon Si Ayutthaya",
            name: "Phra Nakhon Si Ayutthaya"
        }, {
            code: "TH-54",
            value: "Phrae",
            name: "Phrae"
        }, {
            code: "TH-83",
            value: "Phuket",
            name: "Phuket"
        }, {
            code: "TH-25",
            value: "Prachin Buri",
            name: "Prachin Buri"
        }, {
            code: "TH-77",
            value: "Prachuap Khiri Khan",
            name: "Prachuap Khiri Khan"
        }, {
            code: "TH-85",
            value: "Ranong",
            name: "Ranong"
        }, {
            code: "TH-70",
            value: "Ratchaburi",
            name: "Ratchaburi"
        }, {
            code: "TH-21",
            value: "Rayong",
            name: "Rayong"
        }, {
            code: "TH-45",
            value: "Roi Et",
            name: "Roi Et"
        }, {
            code: "TH-27",
            value: "Sa Kaeo",
            name: "Sa Kaeo"
        }, {
            code: "TH-47",
            value: "Sakon Nakhon",
            name: "Sakon Nakhon"
        }, {
            code: "TH-11",
            value: "Samut Prakan",
            name: "Samut Prakan"
        }, {
            code: "TH-74",
            value: "Samut Sakhon",
            name: "Samut Sakhon"
        }, {
            code: "TH-75",
            value: "Samut Songkhram",
            name: "Samut Songkhram"
        }, {
            code: "TH-19",
            value: "Saraburi",
            name: "Saraburi"
        }, {
            code: "TH-91",
            value: "Satun",
            name: "Satun"
        }, {
            code: "TH-17",
            value: "Sing Buri",
            name: "Sing Buri"
        }, {
            code: "TH-33",
            value: "Sisaket",
            name: "Sisaket"
        }, {
            code: "TH-90",
            value: "Songkhla",
            name: "Songkhla"
        }, {
            code: "TH-64",
            value: "Sukhothai",
            name: "Sukhothai"
        }, {
            code: "TH-72",
            value: "Suphan Buri",
            name: "Suphan Buri"
        }, {
            code: "TH-84",
            value: "Surat Thani",
            name: "Surat Thani"
        }, {
            code: "TH-32",
            value: "Surin",
            name: "Surin"
        }, {
            code: "TH-63",
            value: "Tak",
            name: "Tak"
        }, {
            code: "TH-92",
            value: "Trang",
            name: "Trang"
        }, {
            code: "TH-23",
            value: "Trat",
            name: "Trat"
        }, {
            code: "TH-34",
            value: "Ubon Ratchathani",
            name: "Ubon Ratchathani"
        }, {
            code: "TH-41",
            value: "Udon Thani",
            name: "Udon Thani"
        }, {
            code: "TH-61",
            value: "Uthai Thani",
            name: "Uthai Thani"
        }, {
            code: "TH-53",
            value: "Uttaradit",
            name: "Uttaradit"
        }, {
            code: "TH-95",
            value: "Yala",
            name: "Yala"
        }, {
            code: "TH-35",
            value: "Yasothon",
            name: "Yasothon"
        }],
    AE:
        [{
            code: "AZ",
            value: "Abu Dhabi",
            name: "Abu Dhabi"
        }, {
            code: "AJ",
            value: "Ajman",
            name: "Ajman"
        }, {
            code: "DU",
            value: "Dubai",
            name: "Dubai"
        }, {
            code: "FU",
            value: "Fujairah",
            name: "Fujairah"
        }, {
            code: "RK",
            value: "Ras al-Khaimah",
            name: "Ras al-Khaimah"
        }, {
            code: "SH",
            value: "Sharjah",
            name: "Sharjah"
        }, {
            code: "UQ",
            value: "Umm al-Quwain",
            name: "Umm al-Quwain"
        }],
    US:
        [{
            code: "AL",
            value: "Alabama",
            name: "Alabama"
        }, {
            code: "AK",
            value: "Alaska",
            name: "Alaska"
        }, {
            code: "AS",
            value: "American Samoa",
            name: "American Samoa"
        }, {
            code: "AZ",
            value: "Arizona",
            name: "Arizona"
        }, {
            code: "AR",
            value: "Arkansas",
            name: "Arkansas"
        }, {
            code: "CA",
            value: "California",
            name: "California"
        }, {
            code: "CO",
            value: "Colorado",
            name: "Colorado"
        }, {
            code: "CT",
            value: "Connecticut",
            name: "Connecticut"
        }, {
            code: "DE",
            value: "Delaware",
            name: "Delaware"
        }, {
            code: "DC",
            value: "District of Columbia",
            name: "District of Columbia"
        }, {
            code: "FM",
            value: "Federated States of Micronesia",
            name: "Federated States of Micronesia"
        }, {
            code: "FL",
            value: "Florida",
            name: "Florida"
        }, {
            code: "GA",
            value: "Georgia",
            name: "Georgia"
        }, {
            code: "GU",
            value: "Guam",
            name: "Guam"
        }, {
            code: "HI",
            value: "Hawaii",
            name: "Hawaii"
        }, {
            code: "ID",
            value: "Idaho",
            name: "Idaho"
        }, {
            code: "IL",
            value: "Illinois",
            name: "Illinois"
        }, {
            code: "IN",
            value: "Indiana",
            name: "Indiana"
        }, {
            code: "IA",
            value: "Iowa",
            name: "Iowa"
        }, {
            code: "KS",
            value: "Kansas",
            name: "Kansas"
        }, {
            code: "KY",
            value: "Kentucky",
            name: "Kentucky"
        }, {
            code: "LA",
            value: "Louisiana",
            name: "Louisiana"
        }, {
            code: "ME",
            value: "Maine",
            name: "Maine"
        }, {
            code: "MH",
            value: "Marshall Islands",
            name: "Marshall Islands"
        }, {
            code: "MD",
            value: "Maryland",
            name: "Maryland"
        }, {
            code: "MA",
            value: "Massachusetts",
            name: "Massachusetts"
        }, {
            code: "MI",
            value: "Michigan",
            name: "Michigan"
        }, {
            code: "MN",
            value: "Minnesota",
            name: "Minnesota"
        }, {
            code: "MS",
            value: "Mississippi",
            name: "Mississippi"
        }, {
            code: "MO",
            value: "Missouri",
            name: "Missouri"
        }, {
            code: "MT",
            value: "Montana",
            name: "Montana"
        }, {
            code: "NE",
            value: "Nebraska",
            name: "Nebraska"
        }, {
            code: "NV",
            value: "Nevada",
            name: "Nevada"
        }, {
            code: "NH",
            value: "New Hampshire",
            name: "New Hampshire"
        }, {
            code: "NJ",
            value: "New Jersey",
            name: "New Jersey"
        }, {
            code: "NM",
            value: "New Mexico",
            name: "New Mexico"
        }, {
            code: "NY",
            value: "New York",
            name: "New York"
        }, {
            code: "NC",
            value: "North Carolina",
            name: "North Carolina"
        }, {
            code: "ND",
            value: "North Dakota",
            name: "North Dakota"
        }, {
            code: "MP",
            value: "Northern Mariana Islands",
            name: "Northern Mariana Islands"
        }, {
            code: "OH",
            value: "Ohio",
            name: "Ohio"
        }, {
            code: "OK",
            value: "Oklahoma",
            name: "Oklahoma"
        }, {
            code: "OR",
            value: "Oregon",
            name: "Oregon"
        }, {
            code: "PW",
            value: "Palau",
            name: "Palau"
        }, {
            code: "PA",
            value: "Pennsylvania",
            name: "Pennsylvania"
        }, {
            code: "PR",
            value: "Puerto Rico",
            name: "Puerto Rico"
        }, {
            code: "RI",
            value: "Rhode Island",
            name: "Rhode Island"
        }, {
            code: "SC",
            value: "South Carolina",
            name: "South Carolina"
        }, {
            code: "SD",
            value: "South Dakota",
            name: "South Dakota"
        }, {
            code: "TN",
            value: "Tennessee",
            name: "Tennessee"
        }, {
            code: "TX",
            value: "Texas",
            name: "Texas"
        }, {
            code: "UT",
            value: "Utah",
            name: "Utah"
        }, {
            code: "VT",
            value: "Vermont",
            name: "Vermont"
        }, {
            code: "VI",
            value: "Virgin Islands",
            name: "Virgin Islands"
        }, {
            code: "VA",
            value: "Virginia",
            name: "Virginia"
        }, {
            code: "WA",
            value: "Washington",
            name: "Washington"
        }, {
            code: "WV",
            value: "West Virginia",
            name: "West Virginia"
        }, {
            code: "WI",
            value: "Wisconsin",
            name: "Wisconsin"
        }, {
            code: "WY",
            value: "Wyoming",
            name: "Wyoming"
        }, {
            code: "AA",
            value: "Armed Forces Americas",
            name: "Armed Forces Americas"
        }, {
            code: "AE",
            value: "Armed Forces Europe",
            name: "Armed Forces Europe"
        }, {
            code: "AP",
            value: "Armed Forces Pacific",
            name: "Armed Forces Pacific"
        }]
}
export default province

import React from 'react';
import './svg.scss'
const Jcb = () => {
    return (
        <span className="sc-1oqx2z8-3 fhOnKH"><svg focusable="false" xmlns="http://www.w3.org/2000/svg"
                                                   viewBox="0 0 32 21"><g fill="none" fillRule="evenodd"><g><g
            transform="translate(0 2)"><path fill="#ebf1f8"
                                             d="M26.58 19H2.42A2.4 2.4 0 0 1 0 16.62V2.38A2.4 2.4 0 0 1 2.42 0h24.16A2.4 2.4 0 0 1 29 2.38v14.25A2.4 2.4 0 0 1 26.58 19z"></path><path
            fill="#047ab1" d="M5 17h4c.91 0 2-1.12 2-2V2H7c-.91 0-2 1.12-2 3v12z"></path><path fill="#ffffff"
                                                                                               d="M7.27 12.14A8.2 8.2 0 0 1 5 11.82v-1.1a3.9 3.9 0 0 0 1.9.58c.77 0 1.2-.54 1.2-1.28V7H10v3.02c0 1.18-.62 2.12-2.73 2.12z"></path><path
            fill="#d42d06" d="M12 17h4c.91 0 2-1.12 2-2V2h-4c-.91 0-2 1.12-2 3v12z"></path><path fill="#ffffff"
                                                                                                 d="M12 7.7c.58-.56 1.58-.91 3.2-.83.88.04 1.8.3 1.8.3v1.11a4.07 4.07 0 0 0-1.73-.57c-1.23-.11-1.98.58-1.98 1.79s.75 1.9 1.98 1.8a4.13 4.13 0 0 0 1.73-.58v1.1s-.92.27-1.8.31c-1.62.08-2.62-.27-3.2-.83V7.7z"></path><path
            fill="#67b637" d="M19 17h4c.91 0 2-1.12 2-2V2h-4c-.91 0-2 1.12-2 3v12z"></path><path fill="#ffffff"
                                                                                                 d="M24 10.7c0 .8-.62 1.3-1.45 1.3H19V7h3.23l.23.01c.73.04 1.27.47 1.27 1.21 0 .58-.37 1.08-1.04 1.2v.03c.74.05 1.31.52 1.31 1.25zm-2.55-2.91a.91.91 0 0 0-.13-.01h-1.2v1.26h1.33c.25-.06.46-.29.46-.63s-.21-.57-.46-.62zm.15 2.04a.86.86 0 0 0-.16-.01h-1.32v1.37h1.32l.16-.02c.25-.06.46-.32.46-.67 0-.35-.2-.6-.46-.67z"></path></g></g></g></svg></span>
    )
}
export default Jcb

import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { getOrderList } from '@/api';
import styles from './index.module.scss';
import { DatePicker, Button } from 'antd';
import dayjs from 'dayjs';
const { RangePicker } = DatePicker;

const EChartsComponent = () => {
    const today = dayjs().subtract(16, 'hour').format('YYYY-MM-DD');
    const [date, setDate] = useState([today, today]);
    const [info, setInfo] = useState({});
    const urlParams = new URLSearchParams(window.location.search);
    const shop_url = urlParams.get('shop').replace(/\.myshopify\.com$|\.myshopify\.shop$/, "");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const info = {
            order_date: date,
            shop_name: shop_url
        };
        fetchData(info);
        // eslint-disable-next-line
    }, [date]);

    const disabledDate = (current) => current && current > dayjs().endOf('day');

    const fetchData = async (selectedDate) => {
        try {
            setLoading(true);
            const response = await getOrderList(selectedDate);
            if (response.code === 200) {
                setInfo(response.data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleDateChange = (dates) => {
        if (dates && dates.length === 2) {
            const formattedDates = dates.map(date => dayjs(date).subtract(0, 'hour').format('YYYY-MM-DD'));
            setDate(formattedDates);
        }
    };

    const handleQuickSelect = (range) => {
        let newDates;
        const today = dayjs().subtract(16, 'hour');
        switch (range) {
            case '7days':
                newDates = [today.subtract(7, 'days').format('YYYY-MM-DD'), today.format('YYYY-MM-DD')];
                break;
            case 'yesterday':
                newDates = [today.subtract(1, 'day').format('YYYY-MM-DD'), today.subtract(1, 'day').format('YYYY-MM-DD')];
                break;
            case 'today':
                newDates = [today.format('YYYY-MM-DD'), today.format('YYYY-MM-DD')];
                break;
            case '1month':
                newDates = [today.subtract(1, 'month').format('YYYY-MM-DD'), today.format('YYYY-MM-DD')];
                break;
            default:
                newDates = [today.format('YYYY-MM-DD'), today.format('YYYY-MM-DD')];
        }
        setDate(newDates);
    };

    const getOption = () => {
        return {
            title: {
                text: 'Total Revenue',
            },
            tooltip: {},
            xAxis: {
                type: 'category',
                data: info.normal?.chartData?.map(item => dayjs(item.date).subtract(0, 'hour').format('YYYY-MM-DD')) || [], // Ensure dateList is an array
            },
            yAxis: {
                type: 'value',
            },
            series: [
                {
                    name: 'total',
                    type: 'line',
                    data: info.normal?.chartData?.map(item => item.value) || [], // Ensure dateList is an array
                },
            ],
        };
    };

    const getOption1 = () => {
        return {
            title: {
                text: 'Post-upsell Revenue',
            },
            tooltip: {},
            xAxis: {
                type: 'category',
                data: info.jg?.chartData?.map(item => dayjs(item.date).subtract(0, 'hour').format('YYYY-MM-DD')) || [], // Ensure dateList is an array
            },
            yAxis: {
                type: 'value',
            },
            series: [
                {
                    name: 'total',
                    type: 'line',
                    data: info.jg?.chartData?.map(item => item.value) || [], // Ensure dateList is an array
                },
            ],
        };
    };

    return (
        <div className={styles['content']}>
            <div
                className={styles['date']}
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '10px', // 控制子元素间的间距
                }}
            >
                <Button onClick={() => handleQuickSelect('1month')}>Last 1 Month</Button>
                <Button onClick={() => handleQuickSelect('7days')}>Last 7 Days</Button>
                <Button onClick={() => handleQuickSelect('yesterday')}>Yesterday</Button>
                <Button onClick={() => handleQuickSelect('today')}>Today</Button>
                <RangePicker
                    value={[dayjs(date[0]), dayjs(date[1])]}
                    disabledDate={disabledDate}
                    onChange={handleDateChange}
                    style={{marginLeft: '5px'}}
                />
            </div>

            {!loading ? (
                <>
                    <div className={styles['centerbox']}>
                        <div className={styles['gmv']}>
                            <div className={styles['dateBox']}>
                                <div className={styles['box']} style={{fontSize:'25px',color:'#fff26d'}}>
                                    <div className={styles['title1']}>${(info.normal?.total_revenue + info.jg?.post_upsell_revenue).toFixed(2) }</div>
                                    <div className={styles['title']}>GMV</div>
                                </div>
                            </div>
                        </div>
                        <div className={styles['center']}>
                            <div className={styles['dateBox']}>
                                <div className={styles['box']}>
                                    <div className={styles['title1']}>${info.normal?.total_revenue}</div>
                                    <div className={styles['title']}>Total Revenue</div>
                                </div>
                                <div className={styles['box']}>
                                    <div className={styles['title1']}>{info.normal?.orders}</div>
                                    <div className={styles['title']}>Orders</div>
                                </div>
                                <div className={styles['box']}>
                                    <div className={styles['title1']}>{info.normal?.order_cr}%</div>
                                    <div className={styles['title']}>Order CR</div>
                                </div>
                            </div>
                        </div>
                        <div className={styles['center']}>
                            <div className={styles['dateBox']}>
                                <div className={styles['box']}>
                                    <div className={styles['title1']}>${info.jg?.post_upsell_revenue}</div>
                                    <div className={styles['title']}>Post-upsell Revenue</div>
                                </div>
                                <div className={styles['box']}>
                                    <div className={styles['title1']}>{info.jg?.post_upsell_orders}</div>
                                    <div className={styles['title']}>Post-upsell Orders</div>
                                </div>
                                <div className={styles['box']}>
                                    <div className={styles['title1']}>{info.jg?.post_upsell_orders_cr}%</div>
                                    <div className={styles['title']}>Post-upsell Order CR</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ReactECharts option={getOption()}/>
                    <ReactECharts option={getOption1()}/>
                </>
            ) : (
                <div>Loading...</div>
            )}
        </div>
    );
};

export default EChartsComponent;

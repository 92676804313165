import React, {useContext, useState} from 'react';
import styles from "@/pages/checkout/components/stepContent/index.module.scss";
import Input from "@/components/inputStyle/billdingIndex";
import SelectInput from "@/components/selectInput/billingIndex";
import VinceSelect from "@/components/selectInput/billingVinceSelect";
import {DataContext} from '@/hooks/formMessage';

const BillingAddress = () => {
    const {billingAddressValidate, setBillingLayout, setBillingAddress, billingAddress} = useContext(DataContext)
    const [countryInfo, setCountryInfo] = useState({
        alpha3: "AFG", name: "Afghanistan", numeric: "004", value: "Afghanistan"
    })
    const [state, setState] = useState({
        code: "",
    })
    const handleCountryInfo = (e, type) => {
        console.log(e,type)
        setState({
            code: type
        })
        setCountryInfo(e)
        setBillingLayout(e.layout)
        setBillingAddress({
            ...billingAddress,
            province: '',
            province_code: ''
        })
        console.log(['layout2', 'layout3', 'layout4'].includes(countryInfo.layout))
        if (![undefined, 'layout2', 'layout3'].includes(countryInfo.layout)) {
            setBillingAddress({
                ...billingAddress,
                zip: '',
            })
        }
    }
    return (
        <div>
            <div className={styles['is-arrange']}>
                <Input inputFont="First name" field="first_name" type={'billing'} validate={billingAddressValidate.first_name}></Input>
                <Input inputFont="Last name" field="last_name" type={'billing'} validate={billingAddressValidate.last_name}></Input>
            </div>
            <Input inputFont="Address" field="address_1" type={'billing'} validate={billingAddressValidate.address_1}></Input>
            <Input inputFont="Apartment, suite, etc. (optional)" type={'billing'} field="address_2"></Input>
            <Input inputFont="City" field="city" type={'billing'} validate={billingAddressValidate.city}></Input>
            <div className={styles['is-arrange']}>
                <SelectInput selectType={'Country/Region'} type={'billing'} onCountryChanged={handleCountryInfo}></SelectInput>
                {(['layout2', 'layout3', 'layout4'].includes(countryInfo.layout)) &&
                    <VinceSelect selectType={'Province'} code={state.code}
                                 type={'billing'}
                                 validate={['layout2', 'layout3', 'layout4'].includes(countryInfo.layout) ? billingAddressValidate.province : ''}></VinceSelect>}
                {([undefined, 'layout2', 'layout3'].includes(countryInfo.layout)) &&
                    <Input inputFont="Postal code" field="zip"
                           type={'billing'}
                           validate={![undefined, 'layout2', 'layout3'].includes(countryInfo.layout) ? billingAddressValidate.zip : ''}></Input>}
            </div>
            <Input inputFont="Phone (For shipping updates)" type={'billing'} field="phone"
                   validate={billingAddressValidate.phone}></Input>
        </div>
    )
}
export default BillingAddress

import React, {useContext} from 'react';
import {DataContext} from "@/hooks/formMessage";
import DownOutlined from './DownOutlined';
import UpOutlined from "./UpOutlined";

const Cart = (props) => {
    const {priceCorrelation, curInfo,shipping_price} = useContext(DataContext)
    return (
        <div className="row alc jsb">
            <div className="row alc">
                <svg version="1.1" viewBox="0 0 20 19" style={{width: '1.25rem', height: ' 1.1875rem'}}>
                    <path fill="#a26b25"
                          d="M17.178 13.088H5.453c-.454 0-.91-.364-.91-.818L3.727 1.818H0V0h4.544c.455 0 .91.364.91.818l.09 1.272h13.45c.274 0 .547.09.73.364.18.182.27.454.18.727l-1.817 9.18c-.09.455-.455.728-.91.728zM6.27 11.27h10.09l1.454-7.362H5.634l.637 7.362zm.092 7.715c1.004 0 1.818-.813 1.818-1.817s-.814-1.818-1.818-1.818-1.818.814-1.818 1.818.814 1.817 1.818 1.817zm9.18 0c1.004 0 1.817-.813 1.817-1.817s-.814-1.818-1.818-1.818-1.818.814-1.818 1.818.814 1.817 1.818 1.817z"></path>
                </svg>
                <span style={{
                    color: 'rgb(162, 107, 37)',
                    paddingLeft: '.75rem',
                    paddingRight: '.4rem',
                    lineHeight: 1,
                    fontSize: '.875rem'
                }}>
                    {props.collList.length === 1 ? 'Hide order summary' : 'Show order summary'}
                </span>
                {props.collList.length === 1 ? <DownOutlined/> : <UpOutlined/>}
            </div>
            <div style={{
                fontSize: ' 1.28571em',
                lineHeight: '1em',
                fontWeight:500,
                color: 'rgb(51, 51, 51)'
            }}>
                {curInfo.currency_symbol}{props.total ? (props.total - shipping_price).toFixed(2) : (priceCorrelation.total - shipping_price).toFixed(2)}
            </div>
        </div>
    )
}
export default Cart

import React, {useEffect,useState} from 'react';
import LoadingSvg from "@/pages/checkout/svgJsx/loadingSvg";
import styles from './index.module.scss';
import {getOrderState, getOrderStateUrl,postidlogs} from "@/api";
import {useNavigate} from "react-router-dom";
import Cookies from 'js-cookie';
import getShopifyPath from '@/utils/throttle'
const Loading = () => {
    const navigate = useNavigate();
    let intervalId;
    const [errorcode,setErrorcode] = useState(null)
    let pollCount = 0;
    const maxPollCount = 10000;

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        intervalId = setInterval(() => {
            _getOrderState();
        }, 1000); // 每隔3秒轮询一次

        return () => {
            clearInterval(intervalId); // 清除定时器
        };
    }, []);
    //获取跳转链接
    const _getOrderStateUrl = () => {
        getOrderStateUrl(localStorage.getItem('order_id')).then(res => {
            if (res.code === 200) {
                if (res.data) {
                    clearInterval(intervalId);
                    window.location.replace(res.data)
                    postidlogs(7)
                    localStorage.removeItem('order_id');
                    localStorage.removeItem('draft');
                    localStorage.setItem('url', window.shopify.session.host_href)
                    Cookies.remove('cart')
                } else {
                    clearInterval(intervalId);
                    navigate(-1); // 跳转到其他页面
                }
            }else if(res.code === 'P0088'){
                clearInterval(intervalId);
                setErrorcode(res.msg)
                setTimeout(()=>{
                    window.location.replace(getShopifyPath('entry')+'?cid='+window.shopify.session.client_id);
                },2000)
            }
        })
    }
    const checkOrderStatus = (response) => {
        const isRepurchaseSupported = response.data.is_support_repurchase;
        const isOrderComplete = response.data.order_status;

        const handleMaxPollCountReached = () => {
            clearInterval(intervalId);
            navigate(-1); // 跳转到其他页面
        };

        if (isRepurchaseSupported) {
            navigate(`${getShopifyPath('upsell')}?cid=${window.shopify.session.client_id}`);
            return;
        }

        if (isOrderComplete) {
            _getOrderStateUrl();
        } else {
            pollCount++;
            console.log(pollCount, maxPollCount);
            if (pollCount >= maxPollCount) {
                handleMaxPollCountReached();
            }
        }
    };
    const _getOrderState = () => {
        if (localStorage.getItem('order_id')) {
            getOrderState(localStorage.getItem('order_id'))
                .then(response => {
                    checkOrderStatus(response);
                })
                .catch(error => {
                    clearInterval(intervalId);
                    navigate(-1); // 跳转到其他页面
                    console.error('Error fetching order state:', error);
                });
        } else {
            clearInterval(intervalId);
            navigate(-1); // 跳转到其他页面
        }
    };

    return (<div className={styles['content']}>
        <div className={styles['svg-box']}>
            <LoadingSvg></LoadingSvg>
        </div>
        <div className={styles['font']} style={{color:errorcode?'#FF0000':''}}>
            {!errorcode?"Your order's being processed":errorcode}
        </div>
        {!errorcode&&<div>
            <div className={styles['font']}>
                If you're not automatically redirected, refresh this
            </div>
            <div className={styles['font']}>
                page.
            </div>
        </div>}
    </div>);
};

export default Loading;

import React, {useContext, useEffect, useState} from "react";
import './index.scss'
import Step from './components/stepStyle' //顶部步骤条
import LanguageMask from "./components/languageMask"; //语言国家选择器
import ShippingGoods from './components/shippingGoods'
import ContactInformation1 from "./components/stepContent/contactInformation";
import ShippingMethod from './components/stepContent/shippingMethod'
import {Collapse} from 'antd';
import Cart from './svgJsx/cart'
import {DataContext} from '@/hooks/formMessage';
import Button from "./components/stepContent/buttonToPayment";
import PayNow from './components/stepContent/payNow'
import Freight from './components/stepContent/freight'
import Cookies from 'js-cookie';
import {postidlogs} from '@/api'
const {Panel} = Collapse;
const Checkout = () => {
    const {stepIndex, oneOrThreeStep} = useContext(DataContext)
    const [languageFlag, setLanguageFlag] = useState(false);
    const [collList, setCollList] = useState([]);
    const selectLanguage = () => {
        setLanguageFlag(!languageFlag);
    }
    const languageType = (row) => {
        console.log(row)
    }
    useEffect(() => {
        if(window.location.href.includes('error=')){
            localStorage.removeItem('order_id')
        }
        postidlogs(3)
        if (process.env.NODE_ENV === 'production') {
            if (!Cookies.get('cart')) {
                window.location.replace(localStorage.getItem('url'))
            }
        }

    }, [])
    return (<div className="container row">
        {languageFlag &&
            <LanguageMask onLanguageFlag={selectLanguage} handleLanguageType={languageType}/>} {/*语言选择组件*/}
        <div className="left-content">
            <div style={{width: '100%', position: 'relative'}}>
                <Step stepIndex={stepIndex}></Step> {/*顶部步骤条*/}
            </div>
            <div style={{clear: "both"}}></div>
            <div className="pc-over">
                <Collapse defaultActiveKey={collList} onChange={(key) => setCollList(key)}
                          expandIcon={({isActive}) => isActive ? '' : ''}
                >
                    <Panel header={<Cart collList={collList}/>} key="1">
                        <ShippingGoods></ShippingGoods> {/*手机端商品价格展示组件*/}
                    </Panel>
                </Collapse>
            </div>
            <div style={{clear: "both"}}></div>
            {oneOrThreeStep === 3 && <div>
                {stepIndex === 1 && <ContactInformation1></ContactInformation1>} {/* 步骤条组件*/}
                <div style={{clear: "both"}}></div>
                {stepIndex !== 1 && <ShippingMethod></ShippingMethod>}
                <div style={{clear: "both"}}></div>
                {stepIndex === 2 && <Freight></Freight>}
                {stepIndex === 3 && <PayNow></PayNow>}
            </div>}
            {oneOrThreeStep === 1 && <div>
                <div style={{clear: "both"}}></div>
                <ContactInformation1></ContactInformation1> {/* 步骤条组件*/}
                <div style={{clear: "both"}}></div>
                <Freight></Freight>
                <div style={{clear: "both"}}></div>
                <PayNow></PayNow>
            </div>}
            <div style={{clear: "both"}}></div>
            <Button></Button>
            <div style={{clear: "both"}}></div>
            <div className="top-border-solid content" style={{padding: '1rem', boxSizing: 'border-box'}}>
                © 2024, {window.location.host === 'renpbf.shop' ? 'seagril peptides' : window.location.host} Powered by
                Shopify
                {/*<div className="lange">*/}
                {/*    <button className="btn-lange" onClick={selectLanguage}>Language: English</button>*/}
                {/*</div>*/}
            </div>
        </div>
        <div className="right-content">
            <ShippingGoods></ShippingGoods> {/*pc端商品价格展示组件*/}
        </div>
    </div>)
}

export default Checkout;

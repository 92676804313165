import React, {useContext, useState} from 'react'
import './index.scss'
import {DataContext} from '@/hooks/formMessage';

const Input = (props) => {
    const {
        billingAddress,
        billingAddressValidate,
        coupon,
        billingAddressCount,
        setBillingAddressValidate
    } = useContext(DataContext)
    const [state, setState] = useState({
        value: '',
        inputFont: props.inputFont, //提示字
        flag: false, //控制聚焦失焦状态
        borFlag: false,
        icon_btn_flag: false, //点击优惠券的按钮图标切换状态
        //失焦时候的样式
        blur: {
            style_wal: {
                border: '1px solid rgb(217, 217, 217)',
                boxShadow: 'none'
            },
            style_ins: {
                opacity: 0,
                transform: 'translateY(3px)'
            },
            style_input: {
                padding: '0.92857em 0.78571em'
            }
        },
        //聚焦以后的样式
        focus: {
            style_wal: {
                border: '1px solid rgb(162, 107,37)',
                boxShadow: 'rgb(162,107,37) 0 0 0 1px'
            },
            style_ins: {
                opacity: 0.75,
                transform: 'none'
            },
            style_input: {
                padding: '0 0.78571em 0.35714em',
                marginTop: '1.5em',
                outline: 'none'
            }
        },
        error: {
            border: '1px solid rgb(227, 44, 43)',
            boxShadow: 'rgb(227, 44, 43) 0px 0px 0px 1px',
            color: 'rgb(227, 44, 43)'
        },
        fontError: {
            color: 'rgb(227, 44, 43)',
            opacity: 0.75,
            transform: 'none',
        }
    })
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };
//失焦事件
    const handleBlur = () => {
        if (!billingAddress[props.field]) {
            setState({
                ...state,
                inputFont: props.inputFont,
                flag: false,
                borFlag: false,
            })
        } else {
            setBillingAddressValidate({
                ...billingAddressValidate,
                [props.field]:''
            })
            setState({
                ...state,
                borFlag: false,
            })
        }
    }
//聚焦事件
    const handleFocus = () => {
        setState({
            ...state,
            inputFont: '',
            flag: true,
            borFlag: true,
        })
    }
    const handleChange = (e) => {
        billingAddressCount(props.field, e.target.value);
    }
    return (
        <div className={props.inputFlagBtn ? "coupon-input" : 'coupon-from coupon-input'}>
            <table>
                <tbody>
                <tr>
                    <td>
                        <form className="input-form">
                            <div className="input-div">
                                <label className="label1"
                                       style={billingAddressValidate[props.field] ? state.error : !state.borFlag ? state.blur.style_wal : state.focus.style_wal}>
                                    <label className="label2"
                                           style={!billingAddressValidate[props.field] ? (!state.flag ? state.blur.style_ins : billingAddressValidate[props.field] ? state.fontError : state.focus.style_ins) : state.fontError}>{!billingAddressValidate[props.field] ? props.inputFont : billingAddressValidate[props.field]}</label>
                                    <input
                                        onKeyDown={handleKeyDown}
                                        name={props.field !== 'coupon' ? props.field : ''}
                                        value={props.field === 'coupon' ? coupon : billingAddress[props.field]}
                                        onChange={handleChange} type="text"
                                        style={!state.flag ? state.blur.style_input : state.focus.style_input}
                                        onBlur={handleBlur} onFocus={handleFocus} spellCheck="false"
                                        color="#333333"
                                        placeholder={billingAddressValidate[props.field] ? '' : state.inputFont}/>
                                </label>
                            </div>
                        </form>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}

export default Input;

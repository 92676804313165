import React from 'react';
import './svg.scss'

const Loading = () => {
    return (
        <svg version="1.1" viewBox="-270 364 66 66">
            <path fill="#a26b25"
                  d="M-237 428c-17.1 0-31-13.9-31-31s13.9-31 31-31v-2c-18.2 0-33 14.8-33 33s14.8 33 33 33 33-14.8 33-33h-2c0 17.1-13.9 31-31 31z"></path>
        </svg>
    )
}
export default Loading

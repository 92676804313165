import React, {useContext} from 'react';
import shippingStyle from "./shipping.module.scss";
import {DataContext} from '@/hooks/formMessage'
import Car from '../../svgJsx/car'
import Loading from '../../svgJsx/loading'
import {trackEvent,postidlogs} from '@/api'
import createEmailValidator from '@/utils/mailcheck'

const Freight = () => {
    const emailValidator = createEmailValidator();
    const {
        shippingPrice,
        shippingInfo,
        setShippingInfo,
        curInfo,
        shippingLoading,
        _updateOrder,
        checkout
    } = useContext(DataContext)
    const handleOptionChange = (row) => {
        trackEvent(JSON.stringify(row))
        postidlogs(5)
        setShippingInfo(row);
            if (emailValidator.validate(checkout['email']) && localStorage.getItem('order_id')){
                _updateOrder(row)
            }
    };
    return (<div className={`${shippingStyle['content']} content`}>
            <div className={shippingStyle['title-font']}>
                Shipping method
            </div>
            {shippingLoading && <div className={shippingStyle['freight-box']} style={{marginTop: '15px'}}>
                <div className={shippingStyle['freight-box1']}>
                    <div className={shippingStyle['freight-box2']}>
                        {shippingPrice.length > 0 && shippingPrice.map((item, index) => {
                            return (<div
                                className={shippingPrice.length === 2 ? shippingStyle['freight-box3'] : shippingStyle['freight-box-length3']}
                                key={index}>
                                <div className={shippingStyle['freight-content']}>
                                    <div className={shippingStyle['csfvkb']}>
                                        <div className={shippingStyle['izubsl']}>
                                            <div className={shippingStyle['freight-input']}>
                                                <input type='radio' color='#a26b25'
                                                       onChange={() => handleOptionChange(item)}
                                                       className={shippingInfo.title === item.title ? shippingStyle['sc-1o8kbto-1'] : shippingStyle['hnwnsb']}
                                                       checked={shippingInfo.title === item.title}
                                                       name="shippingOption"/>
                                            </div>
                                            <div className={shippingStyle['grcoqy']}
                                                 onClick={() => handleOptionChange(item)}>
                                                {item.title}
                                            </div>
                                            <div className={shippingStyle['gstbgj']}>
                                                {item.price !== '0.00' ? curInfo.currency_symbol : ''}{item.price !== '0.00' ? item.price : 'Free'}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                        })}
                        {shippingPrice.length === 0 && <div className={shippingStyle['n43mxo1']}>
                            <div className={shippingStyle['csfvkb']}>
                                <div className={shippingStyle['']}>
                                   <span className={shippingStyle['cfzydi']}>
                                        <Car></Car>
                                   </span><span className={shippingStyle['gyljok']}>
                                    There are no shipping methods available for your region
                                </span>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>}
            {!shippingLoading && <div style={{display: 'flex', justifyContent: 'center'}}>
                {[...Array(3)].map((_, index) => (
                    <div key={index} className={shippingStyle['spinner']}>
                        <Loading/>
                    </div>
                ))}
            </div>
            }
        </div>

    )
}
export default Freight

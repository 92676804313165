import React, {useContext, useEffect, useState} from 'react';
import {getPaymentEnable, getToken} from '@/api';
import styles from './index.module.scss'
import BillingAddress from "./billingAddress";
import {DataContext} from '@/hooks/formMessage';

const MyComponent = () => {
    const {
        billingCheck, setBillingCheck, setAsiabillInfo, setPaymentList,
    } = useContext(DataContext)
    const [load, setLoad] = useState(false)
    //是否启用账单地址
    const handleAddress = (e) => {
        setBillingCheck(e.target.checked)
    }
    const _getPaymentEnable = () => {
        return getPaymentEnable()
    }
    const loadAsiabillPay = (token) => {
        // 初始化sdk样式
        let st = {
            frameMaxHeight: 100, //  iframe高度
            input: {
                FontSize: '16', // 输入框字体大小
                FontFamily: 'Arial', // 输入框字体类型
                FontWeight: '400', // 输入框字体粗细
                BorderRadius: '5px', // 输入框圆角
                Color: '#333', // 输入框字体颜色
                ContainerBorder: '1px solid #d9d9d9', // 输入框边框
                ContainerPadding: '10px 7px', // 输入框内边距
                ContainerBg: '#fff', // 输入框背景色
                ContainerSh: 'none' // 输入框阴影
            }, // 需要展示背景区域时可，自定义的样式
        }
        let p = {
            formId: 'payment-form', // 页面表单id
            formWrapperId: 'ab-card-element', // 表单内层id
            frameId: 'PrivateFrame', // 生成的IframeId
            lang: 'en', // 表单校验信息国际化参数，目前支持ar(阿拉伯语),ja(日语),ko(韩语),en(英语),zh-CN(简体中文)；不传时如果当前浏器语言为日语、韩语、英语和简体中文中的一种，则会显示该种语言；否则默认展示英语
            needCardList: true, autoValidate: true, // 是否自动展示校验错误信息， 目前支持在表单提交事件或提交按钮点击事件中触发信息校验，false时监听`getErrorMessage`事件获取错误信息
            supportedCards: ['visa', 'jcb', 'unionPay', 'ae', 'master', 'discover'], // 传入时，显示商户支持的卡种类型logo
            layout: {
                pageMode: 'block', // 页面风格模式  inner | block
                style: st
            }
        }
        // eslint-disable-next-line
        let ab = AsiabillPay(token)
        ab.elementInit("payment_steps", p).then((res) => {
            let paymentMethodList = res.data.paymentMethodList;
            if (paymentMethodList.length > 0) {
                console.log(123123424234)
            }
            console.log("initRES", res)
            setAsiabillInfo({
                ab: ab, token: token,
            })
        }).catch(function (err) {
            console.log("initERR", err)
        });
    }
    useEffect(() => {
        _getPaymentEnable().then(res => {
            setPaymentList(res.data)
            const fetchData = async () => {
                setLoad(true)
                return getToken(res.data[0].pay_method === 2 ? res.data[0].payment_id : res.data[1].payment_id);
            };
            fetchData().then(res => {
                let user = res.data
                if (user.js_sdk) {
                    const script = document.createElement('script');
                    script.src = user.js_sdk;
                    script.async = true;
                    document.body.appendChild(script);
                    script.onload = () => {
                        setLoad(false)
                        loadAsiabillPay(user.session_token);
                    };
                    return () => {
                        document.body.removeChild(script);
                    };
                }
            })
        })
        // eslint-disable-next-line
    }, []);

    return (<div className={styles['dundyh']} style={{display: load ? 'none' : 'block'}}>
        <div className={styles['llwojb']}>
            <div className={styles['durpkg']}>
                <div className={styles['form-wrap']}>
                    <form id="payment-form">
                        <div id="ab-card-element" className="ab-element">
                        </div>
                        <div id="card-errors" role="alert"></div>
                        <button id="ab_submit" type="submit" style={{display: 'none'}}>Pay Now</button>
                    </form>
                </div>
                <div className={styles['idzrqg']}>
                    <div className={styles['fvlbyh']}>
                        <input type="checkbox" readOnly=""
                               className={billingCheck ? styles['hpWIfr'] : styles['klltyf']}
                               onChange={handleAddress}
                               checked={billingCheck}/>
                    </div>
                    <label className={styles['ghmhhn']}>Billing address same as shipping</label>
                </div>
                <div className={styles['jpaowf']}
                     style={{opacity: !billingCheck ? 1 : 0, height: !billingCheck ? 'auto' : '0'}}>
                    <div className={styles['fmmzxd']}>
                        <div className={styles['jyonva']}>
                            <div className={styles['hcpgfz']}>Billing address</div>
                        </div>
                        <div className={styles['eyfrlf']}>
                            <BillingAddress></BillingAddress>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>);
}

export default MyComponent;

import React, {useContext} from 'react';
import shippingStyle from './shipping.module.scss'
import {DataContext} from '@/hooks/formMessage';

const ShippingMethod = () => {
    const {checkout, setStepIndex, stepIndex, shippingInfo} = useContext(DataContext)
    const clickReturnStep = (index) => {
        setStepIndex(index)
    }
    return (<div className={`${shippingStyle['content']} content`}>
        <div className={shippingStyle['edit-box']}>
            <div style={{marginTop: '2rem'}} className={shippingStyle['editor-msg']}>
                <div className={shippingStyle['edit-padding']}>
                    <div className={`${shippingStyle['msg-mail']}`}>
                        <div color="#333333" className={shippingStyle['jsdetm']}>
                            <div className={shippingStyle['dkrxf']}>
                                <div className={shippingStyle['eqyy']}>Contact</div>
                                <div className={shippingStyle['flgwdj']}>{checkout['email']}</div>
                            </div>
                            <div className={shippingStyle['bqmjau']}>
                                    <span className={shippingStyle['kidfyn']} onClick={() => clickReturnStep(1)}>
                                        Edit
                                    </span>
                            </div>
                        </div>
                    </div>
                    <div className={`${shippingStyle['msg-per']}`}>
                        <div color="#333333" className={shippingStyle['jsdetm']}>
                            <div className={shippingStyle['dkrxf']}>
                                <div className={shippingStyle['eqyy']}>Ship to</div>
                                <div
                                    className={shippingStyle['flgwdj']}>{checkout['address_1']}{checkout['address_2'] ? ', ' + checkout['address_2'] : ''}, {checkout['city']},{checkout['country']}{checkout['province'] ? ', ' + checkout['province'] : ''}{checkout['zip'] ? ', ' + checkout['zip'] : ''}</div>
                            </div>
                            <div className={shippingStyle['bqmjau']}>
                                    <span className={shippingStyle['kidfyn']} onClick={() => clickReturnStep(1)}>
                                        Edit
                                    </span>
                            </div>
                        </div>
                    </div>
                    {stepIndex === 3 &&
                        <div className={`${shippingStyle['msg-per']}`}>
                            <div color="#333333" className={shippingStyle['jsdetm']}>
                                <div className={shippingStyle['dkrxf']}>
                                    <div className={shippingStyle['eqyy']}>Shipping</div>
                                    <div
                                        className={shippingStyle['flgwdj']}>{shippingInfo.title}</div>
                                </div>
                                <div className={shippingStyle['bqmjau']}>
                                    <span className={shippingStyle['kidfyn']} onClick={() => clickReturnStep(2)}>
                                        Edit
                                    </span>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    </div>)
}

export default ShippingMethod
